import { getField, updateField } from 'vuex-map-fields';

export const navigation = {
    namespaced: true,
    state: {
        showNavigation: false,
    },
    getters:{
        getField,
    },
    mutations: {
        updateField,
    },
    actions : {

    }
}