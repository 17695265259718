<template>
  <v-bottom-navigation fixed class="pg-color-white" v-model="value" height="65px">
    <v-btn value="tchat" :to="{name:'Home'}" disabled icon hidden>
      <span>Tchat</span>
      <pg-icon XL>i-chat</pg-icon>
    </v-btn>
    <v-btn value="play" :to="{name:'Home'}" icon>
      <span>Jouer</span>
      <pg-icon XL>i-play</pg-icon>
    </v-btn>
    <v-btn value="wrote" @click="showGameToWrite=true" icon id="gamewrite">
      <span>Écrire</span>
      <pg-icon XL>i-wrote</pg-icon>
    </v-btn>
    <v-btn value="community" :to="{name:'Community'}" icon>
      <span>Communauté</span>
      <pg-icon XL>i-community-gold</pg-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import PgIcon from "./PGIcon";
import {createHelpers} from "vuex-map-fields";

const {mapFields} = createHelpers({
  getterType: 'game_write/getField',
  mutationType: 'game_write/updateField'
});
export default {
  name: "FooterMenu",
  components: {PgIcon},
  data: () => ({value: 'play'}),
  computed: {
    ...mapFields(['showGameToWrite']),
  }
}
</script>

<style scoped>

</style>