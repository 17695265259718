import APIService from "./api.service";

const publicVapidKey = process.env.VUE_APP_VAPID_PUBLIC_KEY;

class NotificationService {
    async getUserNotifications(playerId) {
        return APIService.get('/player_notifications?page=1&itemsPerPage=20&player.id=' + playerId + '&order%5Bid%5D=desc');
    }
    async deleteAllUserNotifications() {
        return APIService.delete('/player_notifications/delete_all');
    }

    async getNotificationActionTypes() {
        return APIService.get('/player_notification_action_types');
    }

    async markNotificationRead(notificationId) {
        return APIService.delete(notificationId);
    }

    async subscribe(registration, isDiscreet = false) {
        return new Promise((resolve, reject) => {
            registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: this.urlBase64ToUint8Array(publicVapidKey)
            })
                .then(pushSubscription => {
                    console.log("subscription OK");
                    APIService.post('/push_notifications/subscribe', {
                        subscription: pushSubscription,
                        isDiscreet: isDiscreet
                    })
                    return resolve(pushSubscription);
                }).catch(e => {
                console.error(e);
                reject(false);
            });
        })
    }

    urlBase64ToUint8Array(base64String) {
        var padding = '='.repeat((4 - base64String.length % 4) % 4);
        var base64 = (base64String + padding)
            .replace(/\-/g, '+') /* eslint-disable-line */
            .replace(/_/g, '/'); /* eslint-disable-line */

        var rawData = window.atob(base64);
        var outputArray = new Uint8Array(rawData.length);

        for (var i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }
}

export default new NotificationService();