<template>
  <v-navigation-drawer
      fixed
      temporary
      right
      v-model="showNotifications"
      app
      touchless
  >
    <template v-slot:prepend>
      <v-list-item two-line class="pg-hot-gradient" dark>
        <v-list-item-avatar>
          <pg-icon>i-notif</pg-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Notications</v-list-item-title>
          <v-list-item-subtitle>{{ nbNotificationToRead }} en attente.</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-slot:append>
      <NotificationPushSwitch class="mb-8"/>
      <v-btn width="100%" class="pg-color-very-dark-pink" dark @click="showNotifications=!showNotifications">
        <pg-icon s-m>i-close</pg-icon>
        Fermer
      </v-btn>
    </template>
    <v-divider></v-divider>
    <v-row no-gutters>
      <v-list dense nav>
        <v-list-item
            v-for="(item) in limitedNotifications"
            :key="item['@id']"
            link
            @click="showInvitationNotification(item)"
        >
          <v-list-item-icon>
            <pg-icon>{{ item.icon }}</pg-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title><span :class="!item.isRead?'font-weight-bold':''" v-html="item.text"/>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-btn width="100%" class="mt-3" text @click="deleteAllConfirm" v-show="totalNotifications">
        <pg-icon m-d>i-close-gold</pg-icon>
        Tout effacer
      </v-btn>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import PgIcon from "./PGIcon";
import NotificationMixin from "../mixins/NotificationMixin";
import GameMixin from "../mixins/GameMixin";
import AuthPlayerNeeded from "../mixins/AuthPlayerNeeded";
import MessageMixin from "../mixins/MessageMixin";
import MercureMixin from "../mixins/MercureMixin";
import PopUpMixin from "../mixins/PopUpMixin";
import GameInPlayMixin from "../mixins/GameInPlayMixin";
import PlayerMixin from "../mixins/PlayerMixin";
import NotificationPushSwitch from "./NotificationPushSwitch";

export default {
  name: "Notification",
  components: {NotificationPushSwitch, PgIcon},
  mixins: [NotificationMixin, GameMixin, AuthPlayerNeeded, MessageMixin, MessageMixin, MercureMixin, PopUpMixin, GameInPlayMixin, PlayerMixin],
  methods: {
    deleteAllConfirm() {
      this.showNotifications = false;
      this.showPopUp({
        title: "Supprimer toutes les notifications ?",
        message: "Voulez-vous supprimer toutes les notifications ?",
        callback: () => {
          this.deleteAllUserNotifications();
        },
        showCancel: true,
      })
    },
    showInvitationNotification(notification) {
      this.showNotifications = false;
      this.markNotificationRead(notification['@id']).then(() => {
        switch (this.getActionTypeCode(notification)) {
          case 'GAME_FINISHED': {
            this.getGamesInplay();
            this.showPopUp({
              title: "Partie terminée",
              message: notification.text,
              callback: () => {
              },
              showCancel: false,
            })
          }
            break;
          case 'GAME_INVITE': {
            let idGameInPlay = notification.actionParameters;
            this.getGamesInplay();
            if (!idGameInPlay || idGameInPlay === 'refusedInvitation') {
              this.showPopUp({
                title: "Notification de jeu",
                message: notification.text,
                callback: () => {
                }
              })
            } else {
              this.showInvitationGame(idGameInPlay, notification);
            }
          }
            break;
          case 'GAME_ABORT': {
            this.getGamesInplay();
            this.showPopUp({
              title: "Partie annulée",
              message: notification.text,
              callback: () => {
              },
              showCancel: false,
            })
          }
            break;
          case "ORDER_FINISHED": {
            this.showPopUp({
              title: "Commande terminée !",
              message: notification.text,
              callback: () => {
                console.log("Commande terminée id : " + notification.actionParameters);
              },
              showCancel: false,
            })
          }
            break;
          case 'GAME_PUBLISHED': {
            this.showPopUp({
              title: "Information sur la publication de votre jeu",
              message: notification.text,
              callback: () => {
                this.getGamesInplay();
              },
              showCancel: false,
            })
          }
            break;
          case 'TEAM_INVITE':
          case 'TEAM_DELETE':
          case 'TEAM_ACCEPTED':
          case 'TEAM_REFUSED': {
            this.getMe();
            this.$router.push({name: 'Profile', hash: "#couple"})
          }
            break;
          default:
            this.showErrorMessage("Notification non prise en charge");
        }
      });
      this.$forceUpdate();
    },
    getActionTypeCode(notification) {
      let actionTypeForNotif = this.notificationsActionTypes.find(actionType => actionType['@id'] === notification.actionType);
      return actionTypeForNotif ? actionTypeForNotif.code : '';
    }
  },
  created() {
    if (this.playerId) {
      this.getNotifications();
    }
    if (this.notificationsActionTypes.length <= 0) {
      this.getNotificationsActionTypes();
    }
  },
  watch: {
    playerId: function () {
      if (this.playerId) {
        this.getNotifications();
      }
    },
    showNotifications: function () {
      if (!this.notifications) {
        this.getNotifications();
      }
    },
  }
}
</script>

<style scoped>

</style>