import Launcher from "../components/play/Launcher";

export default {
    name: 'PlayGame',
    path: '/play',
    component: () => import('../layouts/Play'),
    redirect: {name: 'GameLauncher'},
    children: [
        {
            name: 'GameLauncher',
            path: 'launch',
            component: Launcher
        }
    ]
}