<template>
  <v-data-table
      :headers="headers"
      :items="games"
      :items-per-page="itemsPerPage"
      :page="page"
      :loading="loading"
      :server-items-length="totalGames"
      loading-text="En cours de chargement"
      class="elevation-1"
      @update:items-per-page="updateItemsPerPage"
      @update:page="updatePage"
  >
    <template v-slot:item.validationStatus="{ item }">
      <v-avatar :color="getColor(item.validationStatus)" size="20"/>
    </template>
    <template v-slot:item.validationStatus="{ item }">
      <v-avatar :color="getColor(item.validationStatus)" size="20"/>
    </template>
    <template v-slot:item.publicationStart="{ item }">
      {{ getDate(item.publicationStart) }}
    </template>
    <template v-slot:item.pointsReward="{ item }">
      <span>{{ item.pointsReward }}<jumping-pimentos inline s-m/></span>
    </template>
    <template v-slot:item.playedCounter="{ item }">
      <span>{{ item.pointsReward }} <span v-if="!publicPlayer">/ {{ maxGameInPlayToReward }}</span></span>
    </template>
    <template v-slot:item.isPrivate="{ item }">
      <v-btn class="pg-color-deep-red" dark :large="!$vuetify.breakpoint.mobile"
             @click="reStartGame(item)" v-if="!isSolo && item.isPrivate">
        <span v-if="gameIsInPlay(item['@id'])<0">Rejouer</span>
        <span v-else>Continuer </span>
        <pg-icon XL>i-play</pg-icon>
        <span v-if="hasToPay(item)"><b>{{ item.pointsCost }}</b></span>
      </v-btn>
    </template>
    <template v-slot:no-data>
      Aucun jeu publié pour le moment.
    </template>
  </v-data-table>
</template>

<script>
import {mapActions} from "vuex";
import moment from "moment";

import JumpingPimentos from "../JumpingPimentos";
import GameService from "../../services/game.service";
import PgIcon from "../PGIcon";
import PlayerMixin from "../../mixins/PlayerMixin";
import GameInPlayMixin from "../../mixins/GameInPlayMixin";

const MAX_GAME_IN_PLAY_TO_REWARD = process.env.VUE_APP_MAX_GAME_IN_PLAY_TO_REWARD;
export default {
  name: "ProfilePublication",
  components: {PgIcon, JumpingPimentos},
  mixins: [PlayerMixin, GameInPlayMixin],
  props: ['publicPlayer'],
  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'Titre jeu',
          align: 'start',
          sortable: false,
          value: 'title',
        },
        !this.publicPlayer ? {text: 'Rejouer privé', value: 'isPrivate',sortable: false} : {},
        !this.publicPlayer ? {text: 'État', value: 'validationStatus',sortable: false} : {},
        {text: 'Début de publication', value: 'publicationStart',sortable: false},
        {text: 'Nombre de pimentos', value: 'pointsReward',sortable: false},
        {text: 'Parties jouées', value: 'playedCounter',sortable: false},
      ],
      games: [],
      totalGames: 0,
      page: 1,
      itemsPerPage: 10,
    }
  },
  computed: {
    maxGameInPlayToReward() {
      return MAX_GAME_IN_PLAY_TO_REWARD; //process.env.VUE_MAX_GAME_IN_PLAY_TO_REWARD;
    }
  },
  methods: {
    ...mapActions({
          getMyGames: "game_write/getMyGame",
        },
    ),
    hasToPay(gameToStart) {
      if (gameToStart.author != null && gameToStart.author.id === this.playerId) return  false;
      return  gameToStart.pointsCost > 0;
    },
    reStartGame(game) {
      let gameInPlayIndex = this.gameIsInPlay(game['@id']);
      let gameInPlay = gameInPlayIndex >= 0 ? this.gamesInPlay[gameInPlayIndex] : false;
      this.startGameAndGoToPlay(game, gameInPlay);
    },
    gameIsInPlay(gameHydraId) {
      return this.gamesInPlay.findIndex(gameInPlay => gameInPlay.game['@id'] == gameHydraId);
    },
    async getPlayerGames(payload) {
      let playerId = payload.playerId;
      return new Promise((resolve, reject) => {
        GameService.getGames(null, [], playerId, payload, true).then(response => {
          resolve({games: response.data['hydra:member'], totalGames: response.data['hydra:totalItems']});
        }).catch(error => {
          reject(error);
        })
      })
    },
    updatePage(page) {
      this.page = page;
      this.initialize();
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.initialize();
    },
    initialize() {
      this.loading = true;
      if (!this.publicPlayer) {
        this.getMyGames({page: this.page, itemsPerPage: this.itemsPerPage}).then(data => {
          this.games = data.games;
          this.totalGames = data.totalGames;
        }).finally(() => {
          this.loading = false;
        });
      } else {
        this.getPlayerGames({
          page: this.page,
          itemsPerPage: this.itemsPerPage,
          playerId: this.publicPlayer.id
        }).then(data => {
          this.games = data.games;
          this.totalGames = data.totalGames;
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    getDate(dateFromDb) {
      return dateFromDb ? moment(dateFromDb).format("D-M-Y") : "NC";
    },
    getColor(status) {
      let color = 'grey';
      switch (status) {
        case 2 :
          color = 'green';
          break;
        case 1 :
          color = 'red';
          break;

      }
      return color;
    }
  }, created() {
    this.initialize();
  }
}
</script>

<style scoped>

</style>