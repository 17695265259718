<template>
  <div v-if="!sendedInvitations.length">
    Vous n'avez pas de partenaire pour le moment. Si vous n'avez pas encore envoyé une demande, vous pouvez le faire
    avec le formulaire ci-dessous :
    <v-form ref="coupleform" v-model="validForm" lazy-validation>
      <v-text-field
          v-model="email"
          :rules="[rules.required, rules.email]"
          label="E-mail"
          class="pg-text-field"
          outlined
          required
      />
      <v-btn class="pg-color-petite-orchid white--text" block :disabled="loading || !validForm"
             @click="handleCoupleRequest">Inviter
        votre partenaire
        <v-progress-circular
            indeterminate
            color="purple"
            class="ml-1"
            v-if="loading"
        ></v-progress-circular>
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import PlayerMixin from "../../../mixins/PlayerMixin";
import {mapActions} from "vuex";
import PopUpMixin from "../../../mixins/PopUpMixin";

export default {
  name: "CoupleInvitation",
  mixins: [PlayerMixin, PopUpMixin],
  data() {
    return {
      email: '',
      loading: false,
      validForm: true,
      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'e-mail non valide.'
        },
      }
    }
  },
  methods: {
    ...mapActions({
      sendInvitation: 'player/sendInvitation',
    }),
    handleCoupleRequest() {
      if (this.$refs.coupleform.validate()) {

        this.showPopUp({
          title: "Envoyer votre invitation ?",
          message: 'Voulez-vous vraiment envoyer votre invitation  ?',
          buttonTitle: 'Confirmer',
          callback: function () {
            this.loading = true;
            this.sendInvitation(this.email.trim()).finally(() => {
                  this.loading = false;
                }
            );
          }.bind(this)
        })
      }
    }
  }, watch: {
    'email': function () {
      this.email = this.email.toLowerCase();
    }
  }
}
</script>

<style scoped>

</style>