import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            dark: {
                primary: '#cc9999',
            },
            light: {
                primary: '#cc9999',
            }
        }
    }
});
