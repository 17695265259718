<template>
  <component :is="currentGameTypeComponent" v-show="playerId"/>
</template>

<script>

import ErrorGame from "./type/ErrorGame";
import GameLoading from "./type/GameLoading";
import CurrentGameInPlayMixin from "../../mixins/CurrentGameInPlayMixin";
import MercureMixin from "../../mixins/MercureMixin";

const StoryLineGame = () => ({
  component: import('./type/StorylineGame'),
  loading: GameLoading,
  error: ErrorGame,
  timeout: 3000,
});

export default {
  name: "Launcher",
  data: () => {
    return {}
  },
  mixins: [CurrentGameInPlayMixin, MercureMixin],
  components: {
    StoryLineGame
  },
  computed: {
    currentGameTypeComponent() {
      return StoryLineGame;
    }
  },
  methods: {
    launch() {
      let params = {
        gameInPlayId: this.$router.currentRoute.params.gameInPlayId,
      }
      this.startGame(params)
          .then(() => {
            this.setupMercureGameInPlay(this.currentGame['@id']);
          })
          .catch(e => {
            console.error(e);
            this.$router.push({name: 'Home'});
          });
    }
  },
  created() {
    if (!this.playerId) {
      this.getMe().then( ()=> {
        this.launch();
      });
    } else {
      this.launch();
    }
  },
  watch: {
    'currentGame': function () {
      if (this.currentGame === undefined || Object.keys(this.currentGame).length === 0) {
        this.$router.push({name: 'Home'});
      }
    },
  }
}
</script>

<style scoped>

</style>