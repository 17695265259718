import { render, staticRenderFns } from "./AvatarGenderTypeSelect.vue?vue&type=template&id=7758053e&scoped=true&"
import script from "./AvatarGenderTypeSelect.vue?vue&type=script&lang=js&"
export * from "./AvatarGenderTypeSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7758053e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VChip,VSelect})
