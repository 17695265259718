<template>
  <v-btn :icon="icon" :class="icon?'transparent':'pg-color-deep-red'" @click="handleChange" dark
         :large="!$vuetify.breakpoint.mobile">
    <pg-icon m-d v-show="isFavorite">i-favori-full</pg-icon>
    <pg-icon m-d v-show="!isFavorite">i-favori-empty</pg-icon>
    <span v-if="!icon" v-html="textButton"/>
  </v-btn>
</template>

<script>
import PgIcon from "../PGIcon";
import GameService from "../../services/game.service"

export default {
  name: "GameFavoriteButton",
  components: {PgIcon},
  props: {
    icon: {type: Boolean, default: false},
    value: {type: Object, default: null}
  },
  data: () => ({
    isFavorite: false,
  }),
  computed:{
    textButton(){
    return this.isFavorite?'Supprimer des favoris':'Ajouter aux favoris';
    }
  },
  methods: {
    handleChange() {
      if (this.isFavorite) {
        this.removeGameFromFavorite();
      } else {
        this.addGameToFavorite();
      }
    },
    addGameToFavorite() {
      GameService.addGameFavorite(this.value).then(() => {
        this.isFavorite = true;
      }).catch((e) => {
        console.error(e);
      })
    },
    removeGameFromFavorite() {
      GameService.removeGameFavorite(this.value).then(() => {
        this.isFavorite = false;
      }).catch((e) => {
        console.error(e);
      })
    }
  },
  watch: {
    isFavorite: function () {
      this.value.isFavorite = this.isFavorite;
      this.$emit('input', this.value);
    },
    value: function () {
      this.isFavorite = this.value && this.value.isFavorite ? this.value.isFavorite : false;
    }
  },
  created() {
    this.isFavorite = this.value && this.value.isFavorite ? this.value.isFavorite : false;
  }
}
</script>

<style scoped>

</style>