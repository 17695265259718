<template>
  <v-expansion-panels focusable>
    <v-expansion-panel class="pg-hot-gradient">
      <v-expansion-panel-header class="text-uppercase white--text" ripple>
        <pg-icon>i-filter</pg-icon>
        {{ title }}
        <div ref="title" style="display: none">
          <slot></slot>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pa-0 ma-0">
        <v-row align="start" justify="start" :no-gutters="!$vuetify.breakpoint.mobile">
          <v-col cols="6" sm="5" v-for="(gameCategory,n) in filteredCategories" v-bind:key="gameCategory.id"
                 :class="!$vuetify.breakpoint.mobile?'mr-5':''">
            <v-select dark
                      v-model="selectedFilter[n]"
                      :items="gameCategory.gameCategoryElements"
                      item-value="id"
                      deletable-chips
                      clearable
                      chips
                      :attach="!$vuetify.breakpoint.mobile"
                      :full-width="$vuetify.breakpoint.mobile"
                      :dense="$vuetify.breakpoint.mobile"
                      :label="gameCategory.name"
                      item-text="name"
                      validate-on-blur
                      v-on:blur="updateValue"
                      multiple>
              <template v-slot:selection="{ item,disabled }">
                <v-chip class="pg-color-dark-moderate-red" dark :disabled="disabled" close
                        @click:close="removeFilter(n,item.id)">
                  <pg-icon class="mr-5">{{ item.icon }}</pg-icon>
                  <span v-if="!$vuetify.breakpoint.mobile">{{ item.name }}</span>
                </v-chip>
              </template>
              <template v-slot:item="{item,on, attrs}">
                <v-checkbox v-model="attrs.inputValue"/>
                <pg-icon class="mr-5" :key="item.icon">{{ item.icon }}</pg-icon>
                {{ item.name }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="6" class="text-center" v-if="selectedFilter.length">
            <v-btn :class="$vuetify.breakpoint.smAndDown?'pg-color-maroon mb-2':'pg-color-maroon'" dark @click="reset">
              <pg-icon>i-clear</pg-icon>
              Effacer
            </v-btn>
            <v-btn class="ml-2 pg-color-maroon" dark @click="refresh">
              <pg-icon>i-search</pg-icon>
              Chercher
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import PgIcon from "../PGIcon";
import GameMixin from "../../mixins/GameMixin";

export default {
  name: "GameFilter",
  components: {PgIcon},
  mixins: [GameMixin],
  data: () => ({
    selectedFilter: [],
    title: "Afficher les filtres",
  }),
  props: {
    value: {
      type: Array
    },
  },
  computed: {
    filteredCategories() {
      return this.gameFilters.filter(filter => filter.gameCategories.length === 0);
    },
  },
  methods: {
    removeFilter(index, id) {
      this.selectedFilter[index].splice(this.selectedFilter[index].indexOf(id), 1);
    },
    updateValue: function () {
      this.$emit('input', this.selectedFilter);
    },
    refresh() {
      this.$emit('refresh', this.selectedFilter);
    },
    reset() {
      this.$emit('input', []);
    }
  },
  created() {
    if (!this.gameFilters.length) {
      this.getGameFilters();
    }
  },
  watch: {
    value: function () {
      this.selectedFilter = this.value;
    }
  },
  mounted() {
    if (this.$refs.title && this.$refs.title.textContent) {
      this.title = this.$refs.title.textContent;
    }
  }
}
</script>

<style scoped>
.v-menu__content {
  min-width: 80vw !important;
}


</style>