import {createHelpers} from 'vuex-map-fields';
import {mapActions} from "vuex";

const {mapFields} = createHelpers({
    getterType: 'notification/getField',
    mutationType: 'notification/updateField'
});

export default {
    computed: {
        ...mapFields(['notifications', 'showNotifications', 'notificationsActionTypes', 'notificationsJWT', 'totalNotifications', 'hasAcceptedPushNotificationOnDevice']),
        nbNotificationToRead() {
            return this.totalNotifications;
        },
        limitedNotifications() {
            let limit = this.notifications.length >= 10 ? 10 : this.notifications.length;
            return this.notifications.slice(0, limit);
        }
    },
    methods: {
        ...mapActions({
            getNotifications: "notification/getNotifications",
            markNotificationRead: "notification/markNotificationRead",
            getNotificationsActionTypes: "notification/getNotificationActionTypes",
            subscribePushNotification: "notification/subscribePushNotification",
            unsubscribePushNofication:"notification/unsubscribePushNofication",
            deleteAllUserNotifications:"notification/deleteAllUserNotifications"
        })
    },
    created() {

    },
}