import APIService from "./api.service";

class AvatarService {
    async getAvatarElementCategories() {
        return APIService.get('/avatar_element_categories');
    }

    async getAvatarGenderTypes() {
        return APIService.get('/avatar_gender_types');
    }

    async getAvatarShapeTypes() {
        return APIService.get('/avatar_shape_types');
    }

    async getAvatarSkinColors() {
        return APIService.get('/avatar_skin_colors');
    }

    async getAvatarHairColors() {
        return APIService.get('/avatar_hair_colors');
    }

    async getAvatarPicture (avatarImageId){
        return APIService.get(avatarImageId);
    }

    async createAvatar(payload){
        return APIService.post('/avatars',payload);
    }

    async updateAvatar(payload){
        return APIService.put(payload['@id'],payload);
    }

    async loadAvatar(payload){
        return APIService.get(payload);
    }
}

export default new AvatarService();