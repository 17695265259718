import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import {Plugins} from "@capacitor/core";
import './registerServiceWorker'
import { sync } from "vuex-router-sync";
sync(store, router,{moduleName:'RouterModule'});
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'
import VueAwesomeSwiper from 'vue-awesome-swiper';
import Base from "./layouts/Base";
import Auth from "./layouts/Auth";
import Play from "./layouts/Play";

import VueQuillEditor from "vue-quill-editor";
import Quill from 'quill'
import QuillEmoji from 'quill-emoji';
import "quill-emoji/dist/quill-emoji.css";
Quill.register("modules/emoji", QuillEmoji);
// import vue-quill-editor styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor);

const {SplashScreen} = Plugins;

Vue.config.productionTip = false;
Vue.use(require('vue-moment'));
Vue.use(VueAwesomeSwiper);

Vue.component('base-layout', Base);
Vue.component('auth-layout', Auth);
Vue.component('play_layout', Play);

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
    mounted() {
        SplashScreen.hide();
    }
}).$mount("#app");
