<template>
  <v-row no-gutters v-if="show">
    <v-alert type="success" width="100%" dismissible v-model="show" transition="scale-transition">
      Paiement réalisé avec succès, merci beaucoup !
    </v-alert>
  </v-row>
</template>

<script>
import MessageMixin from "../../mixins/MessageMixin";
import AuthPlayerNeeded from "../../mixins/AuthPlayerNeeded";

export default {
  name: "Checkout",
  mixins: [MessageMixin, AuthPlayerNeeded],
  data() {
    return {
      show: false,
      payment: {
        payment_intent: '',
        payment_intent_client_secret: '',
        redirect_status: ''
      }
    }
  },
  methods: {},
  mounted() {
    const q = this.$route.query;
    if (q && q.payment_intent && q.payment_intent_client_secret && q.redirect_status) {
      this.show = true;
    }
  }
}
</script>

<style scoped>

</style>