<template>
  <v-container :fluid="$vuetify.breakpoint.mobile">
    <v-col v-for="category in productCategories" v-bind:key="category.id">
      <h1 class="title pl-3">{{ category.name }}</h1>
      <v-row class="white mt-10 pa-0 pt-4 pb-4 " align="center" no-gutters justify="center"
             v-for="( product , index) in category.products"
             v-bind:key="index">
        <v-col sm="3" cols="4" class="pa-0 pl-3 pr-3 text-center">
          <v-avatar class="pg-hot-gradient pimentos-text white--text ma-auto" size="90" v-if="product.awardPoints >=0">
            <v-row align="center" justify="center" v-if="category.isAwardPointsProduct">{{ product.awardPoints }}
              <jumping-pimentos inline/>
            </v-row>
            <v-row align="center" justify="center" v-else-if="category.isGameCreditsCoinsProduct"><span
                style="font-size: x-large">{{ product.gameCreditsCoins }}</span>
              <SpinningGameCreditsCoin inline x-l/>
            </v-row>
          </v-avatar>
        </v-col>
        <v-col sm="6" cols="8" align-self="start">
          <h2>{{ product.name }}</h2>
          <span v-html="product.description" class="text--black"/>
        </v-col>
        <v-col sm="3" cols="12">
          <v-row no-gutters v-if="product.awardPointsCost">
            <v-btn text dark class="pg-hot-gradient" min-width="100%" :disabled="product.awardPointsCost > awardPoints"
                   @click="askToPayProductWithAwardPoints(product)">
              {{ product.awardPointsCost }}
              <jumping-pimentos/>
            </v-btn>
          </v-row>
          <v-row justify="center" no-gutters v-if="product.awardPointsCost">ou</v-row>
          <v-row no-gutters class="mt-1">
            <v-btn text dark class="pg-hot-gradient" width="100%" @click="addItemToCartAndPay(product)"> {{
                product.price
              }}€
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import ShopMixin from "../../mixins/ShopMixin";
import JumpingPimentos from "../JumpingPimentos";
import PlayerMixin from "../../mixins/PlayerMixin";
import SpinningGameCreditsCoin from "../SpinningGameCreditsCoin";
import PopUpMixin from "../../mixins/PopUpMixin";

export default {
  name: "ProductCategories",
  components: {SpinningGameCreditsCoin, JumpingPimentos},
  mixins: [ShopMixin, PlayerMixin, PopUpMixin],
  created() {
    if (!this.productCategories.length) {
      this.getProductCategories()
    }
  },
  methods:{
    askToPayProductWithAwardPoints(product){
      this.showPopUp({
        title: 'Confirmer l\'achat avec vos pimentos ?',
        message: 'Payer "'+product.name+'" avec '+product.awardPointsCost+' pimentos ?',
        buttonTitle: 'Payer avec vos pimentos ?',
        buttonCancel: 'Annuler',
        persistent: true,
        callback: () => {
          this.payProductWithAwardPoints(product);
        },
        callbackCancel: () => {

        }
      })
    },
  }
}
</script>

<style scoped>

</style>