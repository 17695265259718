<template>
  <v-card>
    <v-card-title>Something wrong happened!</v-card-title>
  </v-card>
</template>

<script>
export default {
  name: "ErrorGame"
}
</script>

<style scoped>

</style>