import APIService from "./api.service";

class GameContestService {

    // Product
    async getCurrentGameContest(pagination = {}) {
        let url = '/game_contests/current?';
        let page = pagination.page != undefined ? pagination.page : 1;
        let itemsPerPage = pagination.itemsPerPage != undefined ? pagination.itemsPerPage : 1;
        url += 'page=' + page;
        if (itemsPerPage > 0) {
            url += '&itemsPerPage=' + itemsPerPage
        }
        return await APIService.get(url);
    }
    async addGameContestParticipation(payload){
        let  url = '/game_contest_participations'
        let data = {
            gameContest : payload.gameContest,
            participant : payload.participant
        }
        return await APIService.post(url,data);
    }
}

export default new GameContestService();