import APIService from "./api.service";

class PlayerService {

    async getUserBoard() {
        return APIService.get('/shop/client/me');
    }

    async setUserProfile(player) {
        return APIService.put('/shop/client/me', player);
    }

    async deleteUserProfile() {
        return APIService.delete('/shop/client/me');
    }

    async deleteTeam(team){
        return APIService.delete('/teams/'+team.id);
    }

    async deleteSendedInvitation(invitationId) {
        return APIService.delete('/team_invitations/' + invitationId);
    }

    async acceptSendedInvitation(invitation) {
        return APIService.get('/team_invitations/' + invitation.id + '/accept');
    }

    async sendInvitation(payload) {
        return APIService.put(payload.player['@id'] + '/invite', payload.emailGuest)
    }

    async getPlayer(player) {
        return APIService.get(player);
    }

    async setUserPublicProfile(player) {
        return APIService.put(player['@id'], player);
    }

    async setUserPassword(passwords) {
        return APIService.put('/shop/client/me/password', passwords);
    }

    async setUserTutorialDone() {
        return APIService.put('/shop/client/me/tutorial');
    }

}

export default new PlayerService();