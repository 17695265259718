export default {
    computed: {
        backgroundHeight() {
            if (this.loggedIn) {
                return ''
            } else {
                return '100vh';
            }
        },
    },
    methods: {
        onScroll: function (e) {
            if (typeof window === 'undefined') return
            const top = window.pageYOffset || e ? e.target.scrollTop : false || 0;
            this.scrolled = top > 20;
        },

    },
}