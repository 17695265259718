<template>
  <div class="text-left" v-if="sendedInvitations.length && !you">
    <h3 class="mb-3">Vos invitations envoyées :</h3>
    <v-row v-for="invitation in sendedInvitations" v-bind:key="invitation.id" align="center" no-gutters justify="center">
      <v-col sm="3" cols="2" class="pa-0" align-self="start">
        <v-img :src="avatarImagePath" contain/>
      </v-col>
      <v-col sm="6" cols="6" class="pa-0 text-center">{{ getGuest(invitation) }}</v-col>
      <v-col sm="3" cols="4" class="text-right">
        <v-btn class="pg-color-deep-red" dark @click="confirmDelete(invitation.id)" :small="$vuetify.breakpoint.mobile">
          <pg-icon color="white" s-m class="mr-1">i-close</pg-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">Supprimer</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="mt-5 mb-5"/>
  </div>
</template>

<script>
import PlayerMixin from "../../../mixins/PlayerMixin";
import {mapActions} from "vuex";
import PgIcon from "../../PGIcon";
import PopUpMixin from "../../../mixins/PopUpMixin";

export default {
  name: "CoupleSendedInvitation",
  components: {PgIcon},
  mixins: [PlayerMixin, PopUpMixin],
  methods: {
    ...mapActions({
        deleteSendedInvitation: 'player/deleteSendedInvitation'
    }),
    confirmDelete(invitationId) {
      this.showPopUp({
        title: "Supprimer votre invitation ?",
        message: 'Voulez-vous vraiment supprimer votre invitation  ?',
        buttonTitle: 'Confirmer',
        callback: function (){
          this.deleteSendedInvitation(invitationId);
        }.bind(this)
      })
    },
    getGuest(invitation) {
      let host = invitation.guestPlayers.length ? invitation.guestPlayers[0] : null;
      return host ? host.nickName : invitation.guestMail;
    }
  }

}
</script>

<style scoped>

</style>