import Login from "../views/auth/Login";
import Register from "../views/auth/Register";
import Verification from "../views/auth/Verification";
import Recovery from "../views/auth/Recovery";

export default {
    path: '/auth',
    name: 'Authorization',
    component: () => import('../layouts/Auth'),
    redirect: { name: 'Login' },
    children: [
        {
            path: "/auth/login",
            name: "Login",
            component: Login,
        },
        {
            path: "/auth/register",
            name: "Register",
            component: Register,
        },
        {
            path: "/auth/verification",
            name: "Verification",
            component: Verification,
        },
        {
            path: "/auth/recovery",
            name: "Recovery",
            component: Recovery,
        },
    ]
};
