<template>
  <v-data-table
      :headers="headers"
      :items="payments"
      :items-per-page="itemsPerPage"
      :page="page"
      :loading="loading"
      :server-items-length="totalPayments"
      loading-text="En cours de chargement"
      class="elevation-1"
      @update:items-per-page="updateItemsPerPage"
      @update:page="updatePage"
  >
    <template v-slot:item.updatedAt="{ item }">
      {{ getDate(item.updatedAt) }}
    </template>
    <template v-slot:item.amount="{ item }">
      <span v-html="item.amount/100"></span> €
    </template>
    <template v-slot:item.status="{ item }">
      <v-avatar :color="getColor(item.status)" size="20"/>
    </template>
    <template v-slot:no-data>
      Aucun paiement pour le moment.
    </template>
  </v-data-table>
</template>

<script>
import {mapActions} from "vuex";
import moment from "moment";

export default {
  name: "ProfilePayment",
  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'Date',
          align: 'start',
          sortable: false,
          value: 'updatedAt',
        },
        {text: 'Montant', value: 'amount', sortable: false},
        {text: 'Status bancaire', value: 'status', sortable: false},

      ],
      payments: [],
      totalPayments: 0,
      page: 1,
      itemsPerPage: 10,
    }
  },
  methods: {
    ...mapActions({
          getPayments: "shop/getPayments",
        },
    ),
    updatePage(page) {
      this.page = page;
      this.initialize();
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.initialize();
    },
    initialize() {
      this.loading = true;
      this.getPayments({page: this.page, itemsPerPage: this.itemsPerPage}).then(data => {
        this.payments = data.payments;
        this.totalPayments = data.totalPayments;
      }).finally(() => {
        this.loading = false;
      });

    },
    getDate(dateFromDb) {
      return dateFromDb ? moment(dateFromDb).format("D-M-Y hh:mm") : "NC";
    },
    getColor(status) {
      let color = 'orange';
      switch (status) {
        case 'succeeded' :
          color = 'green';
          break;
        case 'payment_failed' :
          color = 'red';
          break;

      }
      return color;
    }
  }, created() {
    this.initialize();
  }
}
</script>

<style scoped>

</style>