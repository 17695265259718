<template>
  <!--  <v-switch label="Notification hors ligne" class="ml-1"  v-model="hasAcceptedPushNotificationOnDevice"/>-->
  <v-slider
      v-model="hasAcceptedPushNotificationOnDevice"
      :tick-labels="ticksLabels"
      :min="0"
      :max="2"
      step="1"
      ticks="always"
      :tick-size="3"
      thumb-label="always"
      class="mt-5 ml-2 mr-2"
  >
    <template v-slot:thumb-label="{value}">
      <pg-icon>{{ ticksIcon[value] }}</pg-icon>
    </template>
  </v-slider>
</template>

<script>
import NotificationMixin from "../mixins/NotificationMixin";
import PopUpMixin from "../mixins/PopUpMixin";
import PgIcon from "./PGIcon";

export default {
  name: "NotificationPushSwitch",
  components: {PgIcon},
  mixins: [NotificationMixin, PopUpMixin],
  data() {
    return {
      ticksLabels: [
        'Off',
        'Discret',
        'Complet',
      ],
      ticksIcon: [
        'i-logout-admin',
        'i-accessory',
        'i-notif-white'
      ],
      hasChangeRunning: null,
      lastValue: this.hasAcceptedPushNotificationOnDevice
    }
  },
  created() {
    const token = localStorage.getItem('hasAcceptedPushNotificationOnDevice');
    if (token === null) {

      this.showPopUp({
        title: "Voulez-vous recevoir des notifications ?",
        message: "En acceptant, vous pourrez recevoir des notifications même lorsque vous n'êtes pas  sur l'application.",
        buttonTitle: 'Accepter',
        callback: () => {
          this.hasAcceptedPushNotificationOnDevice = 2;
        },
        callbackCancel: () => {
          this.hasAcceptedPushNotificationOnDevice = 0;
        }
      })
    }
  },
  methods: {
    async updatePushNotificationLevel() {
      if (this.hasAcceptedPushNotificationOnDevice != this.lastValue) {
        localStorage.setItem('hasAcceptedPushNotificationOnDevice', this.hasAcceptedPushNotificationOnDevice);
        if (this.hasAcceptedPushNotificationOnDevice) {
          if (this.lastValue >= 1 && this.hasAcceptedPushNotificationOnDevice >= 1) {
            await this.unsubscribePushNofication();
          }
          let isDiscreet = this.hasAcceptedPushNotificationOnDevice === 1;
          await this.subscribePushNotification(isDiscreet);
        } else {
          await this.unsubscribePushNofication();
        }
      }
      this.hasChangeRunning = null;
      this.lastValue = this.hasAcceptedPushNotificationOnDevice;
    }
  },
  watch: {
    hasAcceptedPushNotificationOnDevice: async function (after, before) {
      if (this.hasChangeRunning === null && (before != after)) {
        this.lastValue = before;
        this.hasChangeRunning = setTimeout(this.updatePushNotificationLevel, 1500);
      }
    }
  }
}
</script>

<style scoped>
/deep/ label {
  font-size: small
}
</style>