<template>
  <v-card elevation="0" style="cursor: pointer" @click="gameToStart=game">
    <v-card-title class="pa-1 text-center">
      <v-img :src="getGameImageUrl(game)"
             :gradient="gradient"
             :width="imgSize"
             :height="imgSize"
             class="justify-center align-center align-self-center"
      >
        <v-row no-gutters>
          <v-col :style="'height:'+imgSize" class="text-right">
            <span v-if="game.pointsCost " class="white--text text-caption"><span class="pimentos-text">{{ game.pointsCost }}</span><jumping-pimentos
                v-if="game.pointsCost " s-m inline/>
            </span>
          </v-col>
        </v-row>
      </v-img>
    </v-card-title>
    <v-card-text class="pa-1 text-bold font-weight-bold text-center fit-in-card-text" v-show="!noText" v-html="game.title"/>
  </v-card>
</template>

<script>
import GameMixin from "../../mixins/GameMixin";
import JumpingPimentos from "../JumpingPimentos";

export default {
  name: "GameTile",
  components: {JumpingPimentos},
  props: {
    game: {Object},
    noText: {Boolean, default: false}
  },
  mixins: [GameMixin],
  computed: {
    imgSize() {
      return this.$vuetify.breakpoint.mobile ? '75px' : '125px';
    },
    gradient(){
     return  this.game.canBeStartedAgain ? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.4)' : 'to bottom, rgba(55,0,0,.5), rgba(0,0,0,.5)';
    }
  }
}
</script>

<style>
.not-more-than-3-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-height: 1.5em; /* fallback */
  max-height: 4.5em; /* fallback */
  font-size: 0.8em;
  margin-bottom: 0 !important;
}

.not-more-than-3-lines p {
  margin-bottom: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
<style>

</style>