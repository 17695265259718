import axios from 'axios';
import store from "../store";

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (typeof error.response === 'undefined') {
        console.error(error)
        if(401 === error.response.status){
            store.dispatch("message/showErrorMessage", "Votre session a expiré.")
        }
        store.dispatch("message/showErrorMessage", "Une erreur réseau est arrivée, merci de réessayer.")
        return  Promise.reject(error.response);
    }
    return Promise.reject(error)
})
console.log('axios ready !')
export default axios