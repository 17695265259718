import Profile from "../views/Profile";

export default {
    path: '/profile/',
    component:Profile,
    children:
        [
            {
                path: 'me',
                name: 'ProfileMe',
                component: Profile,
            },
            {
                path: "public/:player",
                name: "Profile",
                component: Profile,
            }
        ]
};
