<template>
  <v-card light>
    <v-card-title>Mon profil</v-card-title>
    <span class="label ml-3">Notifications hors-ligne</span>
    <v-row no-gutters>
      <v-col cols="12" sm="6" md="4">
        <NotificationPushSwitch/>
      </v-col>
    </v-row>

    <v-card-text>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="text--black"
      >
        <v-text-field
            v-model="lastName"
            :counter="250"
            :rules="nameRules"
            label="Nom"
            required
        ></v-text-field>

        <v-text-field
            v-model="firstName"
            :counter="250"
            :rules="nameRules"
            label="Prénom"
            required
        ></v-text-field>

        <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
        ></v-text-field>
        <span> {{ formatedBirthday }}</span>
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="birthday"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="formatedBirthday"
                label="Date de naissance"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="birthday"
              no-title
              scrollable
              locale="fr"
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.menu.save(birthday)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-select
            v-model="gender"
            :items="items"
            :rules="[v => !!v || 'Item is required']"
            label="Genre"
            item-text="label"
            item-value="abbr"
            required
        ></v-select>

        <v-checkbox
            v-model="subscribedToNewsletter"
            label="Inscription à la newsletter ?"
            required
        ></v-checkbox>
        <departement-selector v-model="departement"/>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-row no-gutters>
        <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4 pg-hot-gradient mb-4"
            @click="validate"
        >
          Mettre à jour
        </v-btn>
        <v-btn
            class="mr-4 pg-color-strong-orange"
            @click="getMe"
            dark
        >
          Annuler
        </v-btn>
        <v-btn
            class="mr-4 pg-color-dark-moderate-red"
            @click="reset"
            dark
            v-show="!$vuetify.breakpoint.mobile"
        >
          Effacer
        </v-btn>
        <v-spacer/>
        <v-btn color="error" @click="clickDeleteAccount" class="mb-4">Supprimer mon compte</v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import AuthPlayerNeeded from "@/mixins/AuthPlayerNeeded";
import {createHelpers} from "vuex-map-fields";
import moment from 'moment';
import MessageMixin from "@/mixins/MessageMixin";
import PopUpMixin from "../../mixins/PopUpMixin";
import DepartementSelector from "../geo/DepartementSelector";
import NotificationPushSwitch from "../NotificationPushSwitch";

const {mapFields} = createHelpers({
  getterType: 'player/getField',
  mutationType: 'player/updateField'
});

export default {
  name: "ProfileForm",
  components: {NotificationPushSwitch, DepartementSelector},
  mixins: [AuthPlayerNeeded, MessageMixin, PopUpMixin],
  data: () => ({
    valid: true,
    menu: false,
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    select: null,
    items: [
      {label: 'Homme', abbr: 'm'},
      {label: 'Femme', abbr: 'f'},
      {label: 'Non genré', abbr: 'u'},
    ],
  }),
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('player/updateMe').then(() => {
          console.log('OK');
          this.showSuccessMessage('Modification apportée avec succès')
          this.getMe()
        }).catch(() => {
          this.showErrorMessage('Une erreur a eu lieu, merci de vérifier vos informations et recommencer.')
        })
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    clickDeleteAccount() {
      this.showPopUp({
        title: "Supprimer votre compte ?",
        message: 'Cette action est irréversible, vos données et vos pimentos seront détruits !',
        buttonTitle: 'Supprimer mon compte',
        showCancel: true,
        callback: () => {
          if (confirm("Cette action est irréversible, voulez-vous continuer ?")) {
            this.$store.dispatch('player/deleteMe');
          }
        }
      })
    },
  },
  computed: {
    ...mapFields(['id', 'firstName', 'lastName', 'email', 'birthday', 'gender', 'phoneNumber', 'subscribedToNewsletter', 'departement']),
    formatedBirthday() {
      return this.birthday ? moment(this.birthday).format('DD-MM-YYYY') : '';
    }
  },
  watch: {
    'email': function () {
      this.email = this.email.toLowerCase();
    }
  }

}
</script>

<style scoped>

</style>