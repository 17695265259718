<template>
<coming-soon/>
</template>

<script>
import ComingSoon from "../ComingSoon";
export default {
  name: "ProfileBadgeAndTrophy",
  components: {ComingSoon}
}
</script>

<style scoped>

</style>