import {getField, updateField} from 'vuex-map-fields';
import GeoService from "../../services/geo.service";

export const geo = {
    namespaced: true,
    state: {
        departements: [],
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
    },
    actions: {
        getDepartements({state}) {
            if (!state.departements.length)
                return new Promise((resolve, reject) => {
                    GeoService.getDepartements().then(response => {
                        state.departements = response.data['hydra:member'];
                        resolve(response.data['hydra:member']);
                    }).catch(e => {
                        console.error(e);
                        reject(e);
                    })
                })

        }
    }
}