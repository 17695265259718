<template>
  <div class="box spin" :style="getSize"></div>
</template>

<script>
const SIZE_MAP = {
  small: '16px',
  default: '24px',
  medium: '28px',
  large: '36px',
  xLarge: '40px',
  xxLarge: '55px'
}
export default {
  name: "SpinningGameCreditsCoin",
  props: {
    SM: Boolean,
    MD: Boolean,
    LG: Boolean,
    XL: Boolean,
    XXL: Boolean,
    inline: Boolean,
  },
  computed: {
    getSize() {
      let inlineStyle = this.inline ? 'display: table-cell; ' : '';
      let size = SIZE_MAP.default;
      if (this.SM) size = SIZE_MAP.small;
      if (this.MD) size = SIZE_MAP.medium;
      if (this.LG) size = SIZE_MAP.large;
      if (this.XL) size = SIZE_MAP.xLarge;
      if (this.XXL) size = SIZE_MAP.xxLarge;
      return inlineStyle + 'height:' + size + '; width:' + size + ';';
    }
  }
}
</script>

<style scoped>
@keyframes spinning {
  0% {
    transform: rotateY(0deg)
  }
  25% {
    transform: rotateY(140deg)
  }
  50% {
    transform: rotateY(0deg)
  }
  75% {
    transform: rotateY(-140deg)
  }
  100% {
    transform: rotateY(0deg)
  }

}

.spin {
  animation-name: spinning;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  /* linear | ease | ease-in | ease-out | ease-in-out */
  animation-timing-function: linear;
}

.box {
  align-self: flex-end;
  background-image: url("~@/assets/icons/i-game-credits-coin.svg");
  background-position: center;
  background-size: cover;
}
</style>