import {getField, updateField} from 'vuex-map-fields';
import GameService from "../../services/game.service";

export const game = {
    namespaced: true,
    state: {
        gameToStart: null,
        gameInvitationToAnswer: false,
        gameInvitationPlayInGameId: null,
        gameFilters: [],
        gameTypes: [],
        gameTypesLoading: false,
        currentGames: [],
        showGameToStart: false,
        gamePage: 1,
        gamesPerPage: 20,
        totalGames: 0,
        gamesByType: [],
        selectedGameFilters: [],
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        SET_FILTERS(state, payload) {
            state.gameFilters = payload;
        },
        SET_TYPES(state, payload) {
            state.gameTypes = payload;
        },
    },
    actions: {
        async clearSelectedGameFilters({state}) {
            state.selectedGameFilters.length = 0;
            state.gamePage = 1;
        },
        async getGameFilters({commit}) {
            return new Promise((resolve, reject) => {
                GameService.getGameFilters().then(response => {
                    commit('SET_FILTERS', response.data['hydra:member']);
                    resolve();
                }).catch(error => {
                    reject(error);
                });
            })
        },
        async getGameTypes({commit, state}) {
            return new Promise((resolve, reject) => {
                if (state.gameTypesLoading) {
                    return resolve(state.gameTypes);
                }
                state.gameTypesLoading = true;
                GameService.getGameTypes().then(response => {
                    commit('SET_TYPES', response.data['hydra:member']);
                    resolve();
                }).catch(error => {
                    reject(error);
                }).finally(() => {
                    state.gameTypesLoading = false;
                });
            })
        },
    }
}