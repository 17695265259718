<template>
  <v-card class="mt-2 mb-3" elevation="3">
    <v-card-text class="pa-1">
      <v-row no-gutters align-content="center" justify="center" align="center">
        <v-col :cols="onlyMe?12:6" align-self="center" class="pa-1">
          <v-btn class="pg-hot-gradient" width="100%" dark :to="{name:'ProfileMe'}">
            <v-avatar class="mr-1">
              <v-img :src="avatarImagePath" contain/>
            </v-avatar>
            <span v-if="!$vuetify.breakpoint.mobile">{{ nickName }}</span>
            <v-divider class="transparent"/>
            <span id="mypimentos" class="pimentos-text">{{ awardPoints ? awardPoints : 0 }}</span>
            <pg-icon :loading="isLoadingPlayer">i-pimentos</pg-icon>
            /
            <span class="pimentos-text ml-1">{{gameCreditsCoins}}</span>
            <pg-icon :loading="isLoadingPlayer">i-game-credits-coin</pg-icon>
          </v-btn>
        </v-col>
        <v-col cols="6" v-if="!onlyMe" id="mypartner">
          <v-btn class="pg-hot-gradient-reversed" width="100%" dark v-if="you" :loading="isLoadingPlayer"
                 :to="{name:'Profile',params:{player:you.id}}">
            <pg-icon :loading="isLoadingPlayer">i-pimentos</pg-icon>
            <span class="pimentos-text">{{ you.awardPoints }}</span>
            <v-divider class="transparent"/>
            <span v-if="!$vuetify.breakpoint.mobile">{{ you.nickName }}</span>
            <v-avatar class="ml-1">
              <v-img :src="youAvatarImagePath" contain/>
            </v-avatar>
          </v-btn>
          <v-btn v-else class="pg-hot-gradient-reversed" dark width="100%" :to="{name:'Profile',hash:'#couple'}" :loading="isLoadingPlayer">
            <span v-if="!$vuetify.breakpoint.mobile">Ajouter un</span>
            <pg-icon v-else md :loading="isLoadingPlayer">i-add-white</pg-icon>
            partenaire
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import PgIcon from "../PGIcon";
import PlayerMixin from "../../mixins/PlayerMixin";
import AuthPlayerNeeded from "../../mixins/AuthPlayerNeeded";

export default {
  name: "GameMeAndYou",
  props: {
    onlyMe: {
      type: Boolean,
      default: false
    }
  },
  components: {PgIcon},
  mixins: [PlayerMixin,AuthPlayerNeeded]
}
</script>

<style scoped>

</style>