import {createHelpers} from 'vuex-map-fields';
import {mapActions, mapGetters} from "vuex";

const {mapFields} = createHelpers({
    getterType: 'game_in_play/getField',
    mutationType: 'game_in_play/updateField'
});

export default {
    computed: {
        ...mapFields(['gamesInPlay', 'nbGamesInPlay', 'gameInPlayInvitation', 'gameInPlayInvitationNotification']),
        ...mapGetters('game_in_play', ['nbGamesInPlay']),
        maxGamesInPlay() {
            return process.env.VUE_APP_MAX_GAMES_IN_PLAY;
        },
        maxGamesStartedByDay() {
            return process.env.VUE_APP_MAX_GAMES_STARTED_BY_DAY;
        }
    },
    methods: {
        ...mapActions({
            getGamesInplay: 'game_in_play/getGamesInPlay',
            getGameInPlay: 'game_in_play/getGameInPlay',
            startGame: 'current_game/startGame',
            createGameInPlay: 'current_game/createGame',
            getMe: 'player/getMe'
        }),
        startGameAndGoToPlay(gameToStart, gameInPlay) {
            this.getMe();
            Object.keys(localStorage).forEach(keys => {
                if (keys.startsWith('gtGames')) {
                    localStorage.removeItem(keys);
                }
            })
            if (gameInPlay) {
                this.$router.push({name: 'GameLauncher', params: {gameInPlayId: gameInPlay['@id']}});
            } else {
                this.createGameInPlay(gameToStart).then((gameInPlay) => {
                    this.$router.push({name: 'GameLauncher', params: {gameInPlayId: gameInPlay['@id']}});
                })
            }
        }
    }

}