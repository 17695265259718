<template>
      <v-btn value="tchat" icon
             @click="showAbort">
        <span>Abandon</span>
        <pg-icon m-d>i-close-gold</pg-icon>
      </v-btn>
</template>
<script>
import PgIcon from "../PGIcon";
import CurrentGameInPlayMixin from "../../mixins/CurrentGameInPlayMixin";
import PopUpMixin from "@/mixins/PopUpMixin";

export default {
  name: "AbortGameButton",
  components: {PgIcon},
  mixins: [CurrentGameInPlayMixin, PopUpMixin],
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    showAbort() {
      this.showPopUp({
        title: "Voulez-vous vraiment abandonner ?",
        message: 'C\'est dommage, on commençait à bien s\'amuser...',
        buttonTitle: 'Abandonner',
        hidePimentos: true,
        callback: () => {
          this.stopGame().then(() => {
            this.$router.push({name: 'Home'});
          });
        }
      })
    },
  }
}
</script>

<style scoped>

</style>