<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndUp" style="min-height:90vh" class="pa-0">
    <v-card>
      <v-card-title>Chat</v-card-title>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Chat"
}
</script>

<style scoped>

</style>