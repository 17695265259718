import {mapActions} from "vuex";
import {createHelpers} from "vuex-map-fields";
import ShopService from "../services/shop.service";

const {mapFields} = createHelpers({
    getterType: 'shop/getField',
    mutationType: 'shop/updateField'
});

export default {
    computed: {
        ...mapFields(['productCategories', 'showPayment', 'cartItems', 'cartTotalAmount', 'currentPaymentIntentId',
            'awardPointsPack', 'gameCreditsCoinsPack']),
    },
    methods: {
        ...mapActions({
            getProductCategories: "shop/getProductCategories",
            addItemToCartAndPay: "shop/addItemToCartAndPay",
            cancelOrder: "shop/cancelOrder",
            getAwardPointsPack: "shop/getAwardPointsPack",
            getGameCreditsCoinsPack:"shop/getGameCreditsCoinsPack"
        }),
        payProductWithAwardPoints(product, callback = null) {
            return new Promise(((resolve, reject) => {
                ShopService.payProductWithAwardPoints(product).then(response => {
                    if(callback){
                        callback();
                    }
                    return resolve(response);
                })
                    .catch(error => {
                        console.error(error);
                        return reject(error);
                    })
            }))

        }
    }
}