<template>
  <v-app v-scroll="onScroll">
    <v-snackbar
        v-model="showSnackBar"
        :color="snackBarColor"
        dark
        app
        width="100vw"
    >
      {{ snackBarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            dark
            text
            @click="showSnackBar = false"
            v-bind="attrs"
        >
          <v-icon>mdi-close</v-icon>
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
    <v-main fill-height  class="pg-hot-gradient">
      <v-row no-gutters align-content="center" justify="center">
          <v-container :fluid="$vuetify.breakpoint.mdAndUp" class="pa-0">
            <router-view/>
          </v-container>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import LayoutMixin from "../mixins/LayoutMixin";
import AuthPlayerNeeded from "../mixins/AuthPlayerNeeded";
import MessageMixin from "../mixins/MessageMixin";

const backgroundsPicLogin = {
  pic_sm: require("@/assets/background-pg.jpg"),
  pic_lg: require("@/assets/background-pg-xl.jpg")
}
export default {
  name: "AuthLayout",
  components: {},
  mixins: [LayoutMixin, AuthPlayerNeeded, MessageMixin],
  computed:{
    backgroundsPicture(){
      return backgroundsPicLogin;
    }
  }
}
</script>

<style scoped>

</style>