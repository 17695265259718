import {getField, updateField} from 'vuex-map-fields';
import GameInPlayService from '../../services/game_in_play.service'

export const game_in_play = {
    namespaced: true,
    state: {
        gamesInPlay: [],
        gameInPlayInvitation: null,
    },
    getters: {
        getField,
        nbGamesInPlay(state) {
            return state.gamesInPlay ? state.gamesInPlay.length : 0;
        }
    },
    mutations: {
        updateField,
        SET_GAMES_IN_PLAY(state, payload) {
            state.gamesInPlay = payload;
        },
        SET_GAME_IN_PLAY_INVITATION(state, payload) {
            state.gameInPlayInvitation = payload;
        },
        SET_GAME_IN_PLAY_INVITATION_NOTIFICATION(state, payload) {
            state.gameInPlayInvitationNotification = payload;
        }
    },
    actions: {
        async getGamesInPlay({commit}) {
            return new Promise((resolve, reject) => {
                GameInPlayService.getGamesInPlay().then(response => {
                    commit('SET_GAMES_IN_PLAY', response.data['hydra:member']);
                    resolve(response.data['hydra:member']);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        async getGameInPlay({commit}, gameInPlayId) {
            return new Promise((resolve, reject) => {
                GameInPlayService.getGameInPlay(gameInPlayId).then(response => {
                    commit('SET_GAME_IN_PLAY_INVITATION', response.data);
                    resolve(response.data);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        setGameInPlayNotificationInvitation({commit}, nofitication) {
            commit('SET_GAME_IN_PLAY_INVITATION_NOTIFICATION', nofitication);
        }
    }
}