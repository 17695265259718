<template>
  <v-dialog transition="dialog-bottom-transition" v-model="showGameToStart" max-width="842px" v-if="showGameToStart">
    <v-card class="fill-height pa-0 ma-0">
      <v-card-title class="pg-hot-gradient pa-0 ma-0">
        <v-row no-gutters class="pt-1 pb-1 pl-1" align="center">
          <v-col cols="8">
            <v-img src="~@/assets/banner-transparent.png" contain max-width="200px" max-height="90px"/>
          </v-col>
          <v-col cols="4">
            <v-row no-gutters align="end" justify="end" align-content="end">
              <v-col cols="8" class="text-right">
                <v-btn icon dark v-if="gameToStart.pointsReward > 0">
                  <span class="mr-0 pimentos-text">{{ gameToStart.pointsReward }}</span>
                  <pg-icon class="ml-0 mr-1">i-pimentos</pg-icon>
                </v-btn>
              </v-col>
              <v-col cols="4" class="text-right">
                <v-btn icon dark right x-small @click="gameToStart=null" class="mt-0 mr-1">
                  <pg-icon color="white" s-m>i-close</pg-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <game-me-and-you only-me/>
      <v-row align="center" justify="center" align-content="center" no-gutters>
        <v-col cols="12" align-self="center">
          <h2 class="full-title text-center pa-1">
            {{ gameToStart.title }}
            <GameFavoriteButton v-if="$vuetify.breakpoint.mobile  && gameToStart" icon v-model="gameToStart"/>
          </h2>
        </v-col>
      </v-row>
      <!--gradient="135deg, rgba(84, 0, 14, 0.6),rgba(129, 31, 49, 0.5),rgba(160, 40, 56, 0.6),rgba(167, 70, 47, 0.5),rgba(184, 100, 42, 0.6),rgba(191, 117, 39, 0.5),rgba(196, 141, 39, 0.6),rgba(196, 180, 39, 0.5), rgba(216, 210, 37, 0.6)"-->
      <v-img
          height="150"
          v-if="gameToStart"
          :src="getGameImageUrl(gameToStart)" class="white--text"
      />
      <!-- ici on gère le texte de présentation -->
      <v-row justify="center" no-gutters class="mt-0" v-if="!gameIsInPlay">
        <v-col cols="12">
          <v-card style="overflow-y: auto;" height="22vh" class="pg-color-strong-orange-transparent pg-scroll-bar">
            <v-card-text v-html="gameToStart.descriptionLong"/>
          </v-card>
        </v-col>
      </v-row>
      <!-- ici on gère les accessoires -->
      <v-row no-gutters v-if="!gameIsInPlay && gameToStart.gameDevices.length>0">
        <v-expansion-panels dark flat accordion>
          <v-expansion-panel class="pg-hot-gradient">
            <v-expansion-panel-header>
              Pour jouer vos aurez besoin :
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-chip v-for="(device,key) in gameToStart.gameDevices" v-bind:key="key" dark target="_blank"
                      :href="device.link" small
                      :link="device.link!=''"
                      class="pg-color-very-dark-pink mt-1 ml-1">
                {{ device.name }}
                <pg-icon s-m v-if="device.link">i-shop</pg-icon>
              </v-chip>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <v-card-actions>
        <v-row no-gutters class="mt-1" dense>
          <v-col cols="12">
            <v-row dense class="mb-1">
              <pg-icon v-for="(categoryElement,n) in gameToStart.categoryElements" v-bind:key="n">
                {{ categoryElement.icon }}
              </pg-icon>
              <v-divider class="transparent"/>
              <span class="caption">Joué {{ gameToStart.playedCounter }} fois</span>
            </v-row>
          </v-col>
          <v-col cols="3" sm="4" :class="$vuetify.breakpoint.mobile?'text-center':''">
            <GameFavoriteButton v-if="gameToStart && !$vuetify.breakpoint.mobile" v-model="gameToStart"/>
          </v-col>
          <v-col cols="9" sm="8" class="text-right" v-if="you">
            <v-btn class="pg-color-deep-red" dark :large="!$vuetify.breakpoint.mobile"
                   @click="startGame" v-if="!isSolo">
              <span v-if="!gameIsInPlay">Jouer</span>
              <span v-else>Continuer </span>
              <pg-icon XL>i-play</pg-icon>
              <jumping-pimentos v-if="hasToPay"/>
              <span v-if="hasToPay"><span class="pimentos-text">{{
                  gameToStart.pointsCost
                }}</span></span>
            </v-btn>
          </v-col>
          <span v-else
                class="text-center">Vous pourrez lancer ce jeu dès que vous aurez un partenaire enregistré.</span>
        </v-row>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import GameMixin from "../../mixins/GameMixin";
import PgIcon from "../PGIcon";
import GameFavoriteButton from "./GameFavoriteButton";
import PlayerMixin from "../../mixins/PlayerMixin";
import GameInPlayMixin from "../../mixins/GameInPlayMixin";
import JumpingPimentos from "../JumpingPimentos";
import PopUpMixin from "../../mixins/PopUpMixin";
import GameMeAndYou from "./GameMeAndYou";

export default {
  name: "GameStarter",
  components: {GameMeAndYou, JumpingPimentos, GameFavoriteButton, PgIcon},
  mixins: [GameMixin, PlayerMixin, GameInPlayMixin, PopUpMixin],
  computed: {
    gameIsInPlay() {
      return this.gamesInPlay.findIndex(gameInPlay => gameInPlay.game['@id'] == this.gameToStart['@id']) >= 0;
    },
    gameInPlay() {
      if (this.gameIsInPlay) {
        return this.gamesInPlay[this.gamesInPlay.findIndex(gameInPlay => gameInPlay.game['@id'] == this.gameToStart['@id'])];
      }
      return null;
    },
    isCommunity() {
      return this.gameToStart.author != null;
    },
    isAuthor() {
      return this.isCommunity && this.gameToStart.author.id === this.playerId;
    },
    hasToPay() {
      return !this.isAuthor && !this.gameIsInPlay && this.gameToStart.pointsCost > 0;
    },
    awardPointsNeededToPlay() {
      if (!this.hasToPay) {
        return 0;
      }
      let neededPointAwardPoints = (this.gameToStart.pointsCost - this.awardPoints);
      neededPointAwardPoints = neededPointAwardPoints < 0 ? 0 : neededPointAwardPoints;
      return neededPointAwardPoints;
    }
  },
  methods: {
    askForGameCreditsCoin() {
      let titleText = "Vous avez dépasser le nombre de parties autorisées : " + this.maxGamesInPlay + ".";
      if (this.nbGamesForToday >= process.env.VUE_APP_MAX_GAMES_STARTED_BY_DAY) {
        titleText = "Vous avez dépassé le nombre de lancements de parties gratuites pour ces dernières 24 H."
      }
      if (!this.gameToStart.canBeStartedAgain) {
        console.log(this.gameToStart)
        titleText = "Vous devez attendre 24 H pour relancer ce jeu gratuitement."
      }
      if (this.gameCreditsCoins > 0) {
        this.showPopUp({
          title: titleText,
          message: 'Lancer une partie supplémentaire vous coutera un jeton.',
          buttonTitle: 'Payer un jeton ?',
          buttonCancel: 'Annuler',
          gameCreditsCoinsNeededToPlay: -1,
          persistent: true,
          callback: () => {
            this.checkHasToPay();
          },
          callbackCancel: () => {
            this.showGameToStart = false;
            this.gameToStart = false;
          }
        })
      } else {
        this.showPopUp({
          title: titleText,
          message: 'Vous n\'avez malheureusement pas suffisamment de jetons. Voulez-vous acheter un jeton pour lancer le jeu ?',
          buttonTitle: 'Payer €',
          buttonCancel: 'Annuler',
          persistent: true,
          gameCreditsCoinsNeededToPlay: 1,
          awardPointsNeededToPlay: this.awardPointsNeededToPlay,
          callback: () => {
            this.startGameAndGoToPlay(this.gameToStart, this.gameInPlay);
          },
          callbackCancel: () => {
            this.showGameToStart = false;
            this.gameToStart = false;
          }
        })
      }
    },
    checkHasToPay() {
      if (this.hasToPay) {
        if (this.awardPointsNeededToPlay) {
          this.showPopUp({
            title: "Acheter des pimentos : " + this.awardPointsNeededToPlay + " nécessaires.",
            message: 'Vous n\'avez malheureusement pas suffisamment de pimentos. Voulez-vous acheter un pack de pimentos pour lancer le jeu ?',
            buttonTitle: 'Payer  €',
            buttonCancel: 'Annuler',
            persistent: true,
            awardPointsNeededToPlay: this.awardPointsNeededToPlay,
            callback: () => {
              this.startGameAndGoToPlay(this.gameToStart, this.gameInPlay);
            },
            callbackCancel: () => {
              this.showGameToStart = false;
              this.gameToStart = false;
            }
          })
        } else {
          this.showPopUp({
            title: "Jouer :" + this.gameToStart.title,
            message: 'Voulez-vous payer un coup en pimentos de ' + this.gameToStart.pointsCost + ' pour jouer ?',
            buttonTitle: 'Payer ' + this.gameToStart.pointsCost,
            buttonCancel: 'Annuler',
            persistent: true,
            callback: () => {
              this.startGameAndGoToPlay(this.gameToStart, this.gameInPlay);
            },
            callbackCancel: () => {
              this.showGameToStart = false;
              this.gameToStart = false;
            }
          })
        }
      } else {
        this.startGameAndGoToPlay(this.gameToStart, this.gameInPlay);
      }
    },
    startGame() {
      if (!this.gameIsInPlay
          && !this.hasToPay
          && (this.nbGamesInPlay >= this.maxGamesInPlay
              || !this.gameToStart.canBeStartedAgain
              || this.nbGamesForToday >= this.maxGamesStartedByDay)
      ) {
        this.askForGameCreditsCoin();
      } else {
        this.checkHasToPay();
      }
    }
  },
  watch: {
    gameToStart: function () {
      if (this.gameToStart) {
        this.showGameToStart = true;
      } else {
        this.showGameToStart = false;
      }
    },
    showGameToStart: function () {
      if (!this.showGameToStart) {
        this.gameToStart = false;
      }
    }
  },
  destroyed() {
    this.showGameToStart = false;
    this.gameToStart = false;
  }
}
</script>

<style scoped>
/deep/ p {
  line-height: 12px;
}

/deep/ .v-dialog {
  margin: 8px;
}
</style>