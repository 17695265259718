<template>
  <v-dialog
      max-width="650"
      v-model="dialog"
      transition="dialog-bottom-transition"
      v-if="isTutorialDone && dialog"
      :persistent="persistent"

  >
    <v-card>
      <v-toolbar class="pg-hot-gradient" dark>{{ title }}</v-toolbar>
      <v-card-text>
        <div class="pa-5" v-html="message"/>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
            text
            @click="onCancel"
            v-show="showCancel"
        >{{ buttonCancel }}
        </v-btn>
        <v-btn
            text dark
            @click="onAction"
            class="pg-hot-gradient"
            v-if="(!gameCreditsCoinsNeededToPlay || gameCreditsCoinsNeededToPlay ===-1) && !awardPointsNeededToPlay"
        >{{ buttonTitle }}
          <spinning-game-credits-coin v-if="gameCreditsCoinsNeededToPlay===-1"/>
          <jumping-pimentos s-m v-show="!hidePimentos" v-else/>
        </v-btn>
        <v-btn v-if="packForNeededAwardPoints" class="pg-hot-gradient" dark
               @click="onBuy">
          {{ packForNeededAwardPoints.name }}
          <jumping-pimentos s-m/>
          ({{ packForNeededAwardPoints.price }}€)
        </v-btn>
        <v-btn v-if="packForGameCreditsCoins" class="pg-hot-gradient" dark :disabled="packForGameCreditsCoins.awardPointsCost > awardPoints"
               @click="onBuyWithAwardPoints">
          {{ packForGameCreditsCoins.gameCreditsCoins }}
          <spinning-game-credits-coin/>
          ({{ packForGameCreditsCoins.awardPointsCost }}<jumping-pimentos s-m/>)
        </v-btn>
        <v-btn v-if="packForGameCreditsCoins" class="pg-hot-gradient" dark
               @click="onBuy">
          {{ packForGameCreditsCoins.gameCreditsCoins }}
          <spinning-game-credits-coin/>
          ({{ packForGameCreditsCoins.price }}€)
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {createHelpers} from "vuex-map-fields";
import JumpingPimentos from "./JumpingPimentos";
import {mapActions} from "vuex";
import AuthPlayerNeeded from "../mixins/AuthPlayerNeeded";
import ShopMixin from "../mixins/ShopMixin";
import SpinningGameCreditsCoin from "./SpinningGameCreditsCoin";
import PlayerMixin from "../mixins/PlayerMixin";

const {mapFields} = createHelpers({
  getterType: 'pop_up/getField',
  mutationType: 'pop_up/updateField'
});
export default {
  name: "PopUp",
  components: {SpinningGameCreditsCoin, JumpingPimentos},
  mixins: [AuthPlayerNeeded, ShopMixin, PlayerMixin],
  computed: {
    ...mapFields(['dialog', 'title', 'message', 'buttonTitle', 'callback', 'showCancel', 'callbackCancel',
      'buttonCancel', 'persistent', 'hidePimentos', 'awardPointsNeededToPlay', 'gameCreditsCoinsNeededToPlay']),
    packForNeededAwardPoints() {
      if (!this.awardPointsNeededToPlay || this.awardPointsNeededToPlay === -1) return null;
      return this.awardPointsPack.find(pack => pack.awardPoints >= this.awardPointsNeededToPlay);
    },
    packForGameCreditsCoins() {
      if (!this.gameCreditsCoinsNeededToPlay || this.gameCreditsCoinsNeededToPlay === -1) return null;
      return this.gameCreditsCoinsPack.find(pack => pack.gameCreditsCoins >= this.gameCreditsCoinsNeededToPlay);
    }
  },
  methods: {
    ...mapActions({
      hidePopUp: "pop_up/hidePopUp",
    }),
    onCancel() {
      this.callbackCancel();
      this.hidePopUp();
    },
    onAction() {
      this.callback();
      this.hidePopUp();
    },
    onBuy() {
      let item = this.packForGameCreditsCoins != null ? {...this.packForGameCreditsCoins} : {...this.packForNeededAwardPoints};
      item.noRedirect = true;
      this.addItemToCartAndPay(item);
      this.hidePopUp();
    },
    onBuyWithAwardPoints(){
      let item = {...this.packForGameCreditsCoins};
      this.payProductWithAwardPoints(item,this.getMe);
      this.hidePopUp();
    }
  },
  beforeDestroy() {
    this.hidePopUp();
  },
  watch: {
    awardPointsNeededToPlay: function () {
      if (this.awardPointsNeededToPlay != null) {
        this.getAwardPointsPack();
      }
    },
    gameCreditsCoinsNeededToPlay: function () {
      if (this.gameCreditsCoinsNeededToPlay != null) {
        this.getGameCreditsCoinsPack();
      }
    }
  }
}
</script>

<style scoped>

</style>