<template>
  <v-card min-height="75vh" height="100%">
    <v-card-title>Gestion avatar</v-card-title>
    <v-card-text>
      <v-row align-content="center" justify="center" align="center" no-gutters>
        <avatar-builder v-if="!isPublic"/>
        <v-img :src="getAvatarImagePath(publicPlayer.avatarSavedImage)" v-else width="200" height="200" contain></v-img>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ProfileMixins from "../../mixins/ProfileMixins";
import PlayerMixin from "../../mixins/PlayerMixin";
import AvatarBuilder from "../avatar/AvatarBuilder";

export default {
  name: "ProfileAvatar",
  components: {AvatarBuilder},
  mixins:[ProfileMixins, PlayerMixin]
}
</script>

<style scoped>

</style>