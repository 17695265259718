<template>
  <v-dialog v-model="gameInvitationToAnswer" max-width="842px">
    <v-card class="fill-height pa-0 ma-0" v-if="gameInPlayInvitation">
      <v-card-title class="pg-hot-gradient pa-0 ma-0">
        <v-row no-gutters class="pt-1 pb-1 pl-1" align="center">
          <v-col cols="8">
            <v-img src="~@/assets/banner-transparent.png" contain max-width="200px" max-height="90px"/>
          </v-col>
          <v-col cols="4">
            <v-row no-gutters align="end" justify="end" align-content="end">
              <v-col cols="8" class="text-right">
                <v-btn icon dark v-if="gameInPlayInvitation.game.pointsReward > 0">
                  <b class="mr-0 font-weight-medium">{{ gameInPlayInvitation.game.pointsReward }}</b>
                  <pg-icon class="ml-0 mr-1">i-pimentos</pg-icon>
                </v-btn>
              </v-col>
              <v-col cols="4" class="text-right">
                <v-btn icon dark right x-small @click="gameInvitationToAnswer=false" class="mt-0 mr-1">
                  <pg-icon color="white" s-m>i-close</pg-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-img
          height="150"
          :src="getGameImageUrl(gameInPlayInvitation.game)" class="white--text"
      />
      <v-card-text>
        <v-row class="text-center" align="center" justify="center" style="height: 30vh">
          <v-col cols="12">
            <span>
              {{ gameInPlayInvitationNotification.text }}<br/>
            </span>
            <strong>{{ gameInPlayInvitation.game.title }}</strong>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters class="mt-1" dense>
          <v-col cols="12">
            <v-row dense class="mb-1">
              <pg-icon>i-clock</pg-icon>
              <pg-icon color="gold">i-home</pg-icon>
              <pg-icon>i-fast-forward</pg-icon>
              <v-divider class="transparent"/>
              <span class="caption">Joué {{ gameInPlayInvitation.game.playedCounter }} fois</span>
            </v-row>
          </v-col>
          <v-col cols="3" sm="4" :class="$vuetify.breakpoint.mobile?'text-center':''">
            <GameFavoriteButton v-if="!$vuetify.breakpoint.mobile"/>
          </v-col>
          <v-col cols="9" sm="8" class="text-right">
            <v-btn class="pg-color-deep-red" dark :large="!$vuetify.breakpoint.mobile" @click="startGameAndClose">
              Voir le jeu
              <pg-icon XL>i-play</pg-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import GameMixin from "../../mixins/GameMixin";
import PgIcon from "../PGIcon";
import GameFavoriteButton from "./GameFavoriteButton";
import GameInPlayMixin from "../../mixins/GameInPlayMixin";
import MessageMixin from "../../mixins/MessageMixin";

export default {
  name: "GamePlayInvite",
  components: {GameFavoriteButton, PgIcon},
  mixins: [GameMixin, GameInPlayMixin, MessageMixin],
  methods: {
    startGameAndClose() {
      this.gameInvitationToAnswer = false;
      this.startGameAndGoToPlay({}, this.gameInPlayInvitation);
    },
    onClose() {
      this.gameInvitationToAnswer = false;
      this.gameInvitationPlayInGameId = null;
      this.gameInPlayInvitation = null;
    },
    loadGameInvitation() {
      if (this.gameInvitationPlayInGameId) {
        this.getGameInPlay(this.gameInvitationPlayInGameId).catch(e => {
          console.error(e);
          this.showErrorMessage('Ce jeu a expiré');
          this.onClose();
        }).finally(() => {
          if (!this.gameInPlayInvitation) {
            this.showErrorMessage('Ce jeu a expiré');
            this.onClose();
          }
        });
      }
    }
  },
  beforeDestroy() {
    this.onClose();
  },
  watch: {
    gameInvitationToAnswer: function () {
      if (!this.gameInvitationToAnswer) {
        this.onClose();
      } else {
        this.loadGameInvitation();
      }
    },
    gameInvitationPlayInGameId: function () {
      this.loadGameInvitation();
    }
  }
}
</script>

<style scoped>

</style>