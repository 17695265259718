var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panels',{attrs:{"focusable":""}},[_c('v-expansion-panel',{staticClass:"pg-hot-gradient"},[_c('v-expansion-panel-header',{staticClass:"text-uppercase white--text",attrs:{"ripple":""}},[_c('pg-icon',[_vm._v("i-filter")]),_vm._v(" "+_vm._s(_vm.title)+" "),_c('div',{ref:"title",staticStyle:{"display":"none"}},[_vm._t("default")],2)],1),_c('v-expansion-panel-content',{staticClass:"pa-0 ma-0"},[_c('v-row',{attrs:{"align":"start","justify":"start","no-gutters":!_vm.$vuetify.breakpoint.mobile}},[_vm._l((_vm.filteredCategories),function(gameCategory,n){return _c('v-col',{key:gameCategory.id,class:!_vm.$vuetify.breakpoint.mobile?'mr-5':'',attrs:{"cols":"6","sm":"5"}},[_c('v-select',{attrs:{"dark":"","items":gameCategory.gameCategoryElements,"item-value":"id","deletable-chips":"","clearable":"","chips":"","attach":!_vm.$vuetify.breakpoint.mobile,"full-width":_vm.$vuetify.breakpoint.mobile,"dense":_vm.$vuetify.breakpoint.mobile,"label":gameCategory.name,"item-text":"name","validate-on-blur":"","multiple":""},on:{"blur":_vm.updateValue},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var disabled = ref.disabled;
return [_c('v-chip',{staticClass:"pg-color-dark-moderate-red",attrs:{"dark":"","disabled":disabled,"close":""},on:{"click:close":function($event){return _vm.removeFilter(n,item.id)}}},[_c('pg-icon',{staticClass:"mr-5"},[_vm._v(_vm._s(item.icon))]),(!_vm.$vuetify.breakpoint.mobile)?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e()],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-checkbox',{model:{value:(attrs.inputValue),callback:function ($$v) {_vm.$set(attrs, "inputValue", $$v)},expression:"attrs.inputValue"}}),_c('pg-icon',{key:item.icon,staticClass:"mr-5"},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.name)+" ")]}}],null,true),model:{value:(_vm.selectedFilter[n]),callback:function ($$v) {_vm.$set(_vm.selectedFilter, n, $$v)},expression:"selectedFilter[n]"}})],1)}),(_vm.selectedFilter.length)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"6"}},[_c('v-btn',{class:_vm.$vuetify.breakpoint.smAndDown?'pg-color-maroon mb-2':'pg-color-maroon',attrs:{"dark":""},on:{"click":_vm.reset}},[_c('pg-icon',[_vm._v("i-clear")]),_vm._v(" Effacer ")],1),_c('v-btn',{staticClass:"ml-2 pg-color-maroon",attrs:{"dark":""},on:{"click":_vm.refresh}},[_c('pg-icon',[_vm._v("i-search")]),_vm._v(" Chercher ")],1)],1):_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }