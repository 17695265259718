import {getField, updateField} from 'vuex-map-fields';
import GameContestService from "../../services/game_contest.service";

export const game_contest = {
    namespaced: true,
    state: {
        currentGameContest: null
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
    },
    actions: {
        async getCurrentGameContest({state}) {
            return new Promise((resolve, reject) => {
                GameContestService.getCurrentGameContest().then(response => {
                    if (response.data["@id"]) {
                        state.currentGameContest = response.data;
                    } else {
                        state.currentGameContest = null;
                    }
                    resolve();
                }).catch(error => {
                    reject(error);
                });
            })
        },
        async addGameContestParticipation({state},payload){
            return new Promise((resolve, reject) => {
                GameContestService.addGameContestParticipation(payload).then( response => {
                    if (response.data["@id"]) {
                        state.currentGameContest.currentPlayerHasParticipated = true;
                    }
                    resolve();
                }).catch(error => {
                    reject(error);
                })
            })
        }
    }
}