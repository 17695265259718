<template>
<v-img contain src="@/assets/play/load.gif"></v-img>
</template>

<script>
export default {
  name: "GameLoading"
}
</script>

<style scoped>

</style>