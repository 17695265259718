import {createHelpers} from 'vuex-map-fields';
import {mapActions} from "vuex";


const {mapFields} = createHelpers({
    getterType: 'message/getField',
    mutationType: 'message/updateField'
});

export default {
    computed: {
        ...mapFields(['snackBarMessage', 'showSnackBar', 'snackBarTimeOut', 'snackBarColor'])
    },
    methods: {
        ...mapActions({
            showInfoMessage: "message/showInfoMessage",
            showErrorMessage: "message/showErrorMessage",
            showSuccessMessage: "message/showSuccessMessage"
        }),
    }
}