<template>
  <v-select label="Forme" clearable :items="avatarShapeTypes" item-text="code" v-model="selected" item-value="@id">
    <template v-slot:item="{item}">
      <v-chip >{{ item.code }}</v-chip>
    </template>
    <template v-slot:selection="{item}">
      <v-chip >{{ item.code }}</v-chip>
    </template>
  </v-select>
</template>

<script>
import AvatarMixin from "../../mixins/AvatarMixin";

export default {
  name: "AvatarShapeTypeSelect",
  mixins: [AvatarMixin],
  props: ['value', 'avatarShapeTypes'],
  data() {
    return {
      selected: "",
    }
  },
  watch: {
    selected: function () {
      this.$emit('input', this.selected);
    },
    value: function () {
      this.selected = this.value;
    }
  }
}
</script>

<style scoped>

</style>