<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndUp" style="min-height:90vh" class="pa-0">
    <v-card>
      <GameMeAndYou only-me/>
      <product-categories/>
    </v-card>
    <v-footer>
      <v-row no-gutters>
        <router-link class="ma-auto" :to="{name:'CGV'}">Conditions générales de vente</router-link>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
import GameMeAndYou from "../components/game/GameMeAndYou";
import ProductCategories from "../components/shop/ProductCategories";

export default {
  name: "Shop",
  components: {ProductCategories, GameMeAndYou},
  created() {

  }
}
</script>

<style scoped>

</style>