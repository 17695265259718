import {createHelpers} from 'vuex-map-fields';
import {mapActions, mapGetters} from "vuex";

const {mapFields} = createHelpers({
    getterType: 'current_game/getField',
    mutationType: 'current_game/updateField'
});

export default {
    data() {
        return {}
    },
    computed: {
        ...mapFields(['currentGame', 'currentStageInstructions', 'currentPlayerInGame', 'currentStageActions',
            'currentStagePlayerInteractionsFrom', 'currentGameInPlayStagePlayerInteractionsFrom', 'defaultGameInPlayStagePlayerInteraction',
            'currentGameInPlayStagePlayerInteractionsDestination','hasAnsweredInvitation']),
        ...mapGetters('player', {
            playerId: 'getPlayerId',
        }),
        ...mapGetters('current_game', {
            getCurrentPlayerHasAcceptedInvitation: 'getCurrentPlayerHasAcceptedInvitation'
        }),
        Codes() {
            return {
                'PLAYER': this.currentPlayerInGame.player !== undefined ? this.currentPlayerInGame.player.nickName : '',
                'PLAYER_1': this.currentGame ? this.currentGame.players.find(player => player.number === 1).player.nickName : '',
                'PLAYER_2': this.currentGame ? this.currentGame.players.find(player => player.number === 2).player.nickName : '',
            }
        },
        gameInfo() {
            return this.currentGame ? this.currentGame.game : {};
        },
        playersInGame() {
            return this.currentGame ? this.currentGame.players : [];
        },
        currentPlayerHasAccepted() {
            return this.currentPlayerInGame ? this.currentPlayerInGame.hasAcceptedInvitation : false;
        },
        gameStarted() {
            return this.currentGame.startedAt != null;
        },
        currentStage() {
            return this.currentGame ? this.currentGame.currentStage : null;
        },
        isFinalStage() {
            return this.currentStage ? this.currentStage.isFinal : false;
        },
        liveGameUpdate(game) {
            this.currentGame = game;
        }
    },
    methods: {
        ...mapActions({
            startGame: 'current_game/startGame',
            stopGame: 'current_game/stopGame',
            getMe: 'player/getMe',
            updateGameInPlayPlayer: 'current_game/updateGameInPlayPlayer',
            getStageInstructions: 'current_game/getStageInstructions',
            getStagePlayerInteractionFrom: 'current_game/getStagePlayerInteractionFrom',
            getGameInPlayStagePlayerInteractionDestination: 'current_game/getGameInPlayStagePlayerInteractionDestination',
            getStageActions: 'current_game/getStageActions',
            setGameInPlayWinner: 'current_game/setGameInPlayWinner',
            extendGameDuration: 'current_game/extendDuration',
            goToStage: 'current_game/goToStage'
        }),
        replaceCodesForText(text) {
            if (text) {
                let regex = /\[(.*?)\]/g; //regex global all tags between []
                return text.replaceAll(regex, (codeTag, code) => {
                    return this.Codes[code] || code;
                });
            }
            return '';
        }

    }
}