<template>
  <div class="tutorial-frame" v-if="!isLoadingPlayer && !isTutorialDone">
    <v-btn
        elevation="16"
        fab
        right
        top
        fixed
        small
        dark
        class="pg-color-dark-moderate-red"
        @click="end"
    ><pg-icon s-m color="white">i-close</pg-icon></v-btn>
    <div class="pyro" v-show="currentFrameIndex === frames.length-1">
      <div class="before"></div>
      <div class="after"></div>
    </div>
    <transition name="fade">
      <img :src="imgSrc" id="image-frame" v-show="showImage">
    </transition>
    <v-row no-gutters align="center" justify="space-around" :style="buttonPosition"
           class="text-center pg-color-petite-orchid elevation-10 pa-2">
      <v-col cols="6" align-self="center">
        <v-btn class="pg-hot-gradient ma-auto" dark @click="previous" v-show="currentFrameIndex>0" x-large>
          Précédent
        </v-btn>
      </v-col>
      <v-col cols="6" align-self="center" v-show="currentFrameIndex < frames.length-1">
        <v-btn class="pg-hot-gradient ma-auto button-next" dark @click="next" x-large
        >
          Suivant
        </v-btn>
      </v-col>
      <v-col cols="6" v-show="currentFrameIndex === frames.length-1">
        <v-btn class="pg-hot-gradient ma-auto button-next" dark @click="end" x-large>
          Terminer
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AuthPlayerNeeded from "../../mixins/AuthPlayerNeeded";
import {mapActions} from "vuex";
import NavigationMixin from "../../mixins/NavigationMixin";
import PgIcon from "../PGIcon";

export default {
  name: "TutorialFrame",
  components: {PgIcon},
  mixins: [AuthPlayerNeeded, NavigationMixin],
  data: function () {
    return {
      currentFrameIndex: 0,
      showTutorial: true,
      showImage: false,
      interval: null,
      frames: [
        {
          mobile: {
            imageSrc: require('../../assets/tutorial/1-ajouter-partenaire-mobile.png'),
            refererId: 'mypartner',
            position: 'left',
            buttonPosition: 'bottom',
            offsetLeft: 0,
            offsetTop: -30,
          },
          largeScreen: {
            imageSrc: require('../../assets/tutorial/1-ajouter-partenaire-SMUP.png'),
            refererId: 'mypartner',
            position: 'right',
            buttonPosition: 'bottom',
            offsetLeft: 0,
            offsetTop: -30,
          }
        },
        {
          mobile: {
            imageSrc: require('../../assets/tutorial/2-gagner-pimentos.png'),
            imageLargeScreenSrc: require('../../assets/tutorial/2-gagner-pimentos.png'),
            refererId: 'mypimentos',
            position: 'bottom',
            buttonPosition: 'bottom',
            offsetLeft: 0,
            offsetTop: 0,
          }
        },
        {
          mobile: {
            imageSrc: require('../../assets/tutorial/3-defis.png'),
            imageLargeScreenSrc: require('../../assets/tutorial/3-defis.png'),
            refererId: 'defi',
            position: 'bottom',
            buttonPosition: 'bottom',
            offsetLeft: 0,
            offsetTop: 0,
          }
        },
        {
          mobile: {
            imageSrc: require('../../assets/tutorial/4-scenarios.png'),
            imageLargeScreenSrc: require('../../assets/tutorial/4-scenarios.png'),
            refererId: 'scenarios',
            position: 'top',
            buttonPosition: 'top',
            offsetLeft: 105,
            offsetTop: 3,
          }
        },
        {
          mobile: {
            imageSrc: require('../../assets/tutorial/5-ecrivez.png'),
            imageLargeScreenSrc: require('../../assets/tutorial/5-ecrivez.png'),
            refererId: 'gamewrite',
            position: 'top',
            buttonPosition: 'top',
            offsetLeft: -39,
            offsetTop: 0,
          }
        },
        {
          mobile: {
            imageSrc: require('../../assets/tutorial/6-partagez.png'),
            imageLargeScreenSrc: require('../../assets/tutorial/6-partagez.png'),
            refererId: 'gamewrite',
            position: 'top',
            buttonPosition: 'top',
            offsetLeft: 70,
            offsetTop: 0,
          }
        },
        {
          mobile: {
            imageSrc: require('../../assets/tutorial/7-bienvenue.png'),
            imageLargeScreenSrc: require('../../assets/tutorial/7-bienvenue.png'),
            refererId: 'mypimentos',
            position: 'center',
            buttonPosition: 'bottom',
            offsetLeft: 0,
            offsetTop: 0,
          }
        },
      ]
    }
  },

  mounted() {
    window.document.addEventListener("scroll", this.scroll);
    if (!this.interval) {
      this.interval = window.setInterval(this.setFramePosition, 400);
    }
    //window.addEventListener('unload', this.removeListeners);
  },
  destroyed() {
    this.removeListeners();
  },
  watch: {
    'isLoadingPlayer': function () {
      if (!this.isLoadingPlayer && !this.isTutorialDone) {
        window.setTimeout(this.setFramePosition, 300);
      }
    },
    'isTutorialDone': function (after, before) {
      if (!after && before) {
        this.currentFrameIndex = 0;
        this.showImage = true;
        window.setTimeout(this.setFramePosition, 1000);
      }
    }
  },
  computed: {
    currentFrame() {
      let frame = this.frames[this.currentFrameIndex];
      if (!this.$vuetify.breakpoint.mobile) {
        frame = frame.largeScreen ? frame.largeScreen : frame.mobile;
      } else {
        frame = frame.mobile;
      }
      return frame;
    },
    imgSrc() {
      return this.currentFrame.imageSrc;
    },
    buttonPosition() {
      return 'width:100%;position:fixed;' + (this.currentFrame.buttonPosition === 'bottom' ? 'bottom:5vh;' : 'top:8vh;');
    }
  },
  methods: {
    ...mapActions({
      setTutorialDone: 'player/setTutorialDone',
    }),
    removeListeners() {
      window.document.removeEventListener("scroll",this.scroll);
      window.clearInterval(this.interval);
      this.interval = null;
    },
    scroll() {
      if (this.showImage) {
        this.showImage = false;
      }
    },
    end() {
      this.showNavigation = true;
      this.setTutorialDone();
    },
    next() {
      this.showImage = false;
      window.setTimeout(() => {
        this.currentFrameIndex++;
        this.setFramePosition();
      }, 400);
    },
    previous() {
      this.showImage = false;
      window.setTimeout(() => {
        this.currentFrameIndex--;
        this.setFramePosition();
      }, 400);
    },
    setFramePosition() {
      if (this.isLoadingPlayer || this.isTutorialDone) {
        return;
      }
      let frame = this.currentFrame;
      let element = window.document.getElementById(frame.refererId)
      if (!element) {
        return;
      }
      element.scrollIntoViewIfNeeded(true);
      let rect = element.getBoundingClientRect();
      let image = window.document.getElementById('image-frame');
      if (image === null) {
        return;
      }
      let left = '';
      let right = '';
      let top = '';
      let bottom = '';
      let position = '';
      switch (frame.position) {
        case 'bottom': {
          left = rect.x + rect.width + frame.offsetLeft + 'px';
          position = 'fixed'
          top = rect.y + rect.height + frame.offsetTop - (window.scrollY / 4) + 'px';
        }
          break;
        case 'left': {
          position = 'fixed';
          left = 0 + frame.offsetLeft + 'px';
          top = rect.top - (rect.height) + frame.offsetTop - (window.scrollY / 4) + 'px';
        }
          break;
        case 'right': {
          position = 'fixed';
          right = 0 + frame.offsetLeft + 'px';
          top = rect.top - (rect.height) + frame.offsetTop - (window.scrollY / 4) + 'px';
        }
          break;
        case 'top': {
          left = rect.x - (image.getBoundingClientRect().width / 2) + frame.offsetLeft + 'px';
          position = 'fixed';
          top = rect.y - image.getBoundingClientRect().height + frame.offsetTop + (window.scrollY / 4) + 'px';
        }
          break;
        case 'center': {
          position = 'absolute';
          top = '50%';
          left = '50%';
          image.style.marginRight = '-50%';
          image.style.transform = 'translate(-50%, -50%)';
        }
          break;
      }
      image.style.position = position;
      image.style.left = left;
      image.style.top = top;
      image.style.right = right;
      image.style.bottom = bottom;
      this.showImage = true;
    }
  }
  ,
}
</script>

<style scoped lang="scss">
.tutorial-frame {
  width: 100vw;
  height: 100vh;
  margin: 0em;
  left: 0em;
  top: 0em;
  background: rgb(133 30 30 / 10%);
  position: fixed;
  z-index: 9999;
}
.button-next{
  cursor: pointer;
}
.button-next::before {
  content: '';
  border-radius: 1000px;
  min-width: calc(150px + 12px);
  min-height: calc(30px + 12px);
  border: 6px solid #D8D225;
  box-shadow: 0 0 30px rgba(255, 166, 0, 0.64);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .3s ease-in-out 0s;
}
.button-next::after {
  content: '';
  width: 30px; height: 20px;
  border-radius: 100%;
  border: 6px solid #D8D225;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}
@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
    border: 6px solid #ab3e3e;
  }
  100% {
    width: 200px;
    height: 200px;
    opacity: 0;
    border: 6px solid #D8D225;
  }
}
img {
  position: fixed;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

$particles: 50;
$width: 500;
$height: 500;

// Create the explosion...
$box-shadow: ();
$box-shadow2: ();
@for $i from 0 through $particles {
  $box-shadow: $box-shadow,
  random($width)-$width / 2 + px random($height)-$height / 1.2 + px hsl(random(360), 100, 50);
  $box-shadow2: $box-shadow2, 0 0 #fff
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @-ms-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation-delay($settings) {
  -moz-animation-delay: $settings;
  -webkit-animation-delay: $settings;
  -o-animation-delay: $settings;
  -ms-animation-delay: $settings;
  animation-delay: $settings;
}

@mixin animation-duration($settings) {
  -moz-animation-duration: $settings;
  -webkit-animation-duration: $settings;
  -o-animation-duration: $settings;
  -ms-animation-duration: $settings;
  animation-duration: $settings;
}

@mixin animation($settings) {
  -moz-animation: $settings;
  -webkit-animation: $settings;
  -o-animation: $settings;
  -ms-animation: $settings;
  animation: $settings;
}

@mixin transform($settings) {
  transform: $settings;
  -moz-transform: $settings;
  -webkit-transform: $settings;
  -o-transform: $settings;
  -ms-transform: $settings;
}

body {
  margin: 0;
  padding: 0;
  background: #000;
  overflow: hidden;
}

.pyro > .before, .pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: $box-shadow2;
  @include animation((1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards));
}

.pyro > .after {
  @include animation-delay((1.25s, 1.25s, 1.25s));
  @include animation-duration((1.25s, 1.25s, 6.25s));
}

@include keyframes(bang) {
  to {
    box-shadow: $box-shadow;
  }
}

@include keyframes(gravity) {
  to {
    @include transform(translateY(200px));
    opacity: 0;
  }
}

@include keyframes(position) {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

</style>