<template>
  <v-row align-content="center" align="center" justify="center">
    <v-col cols="12">
      <v-row>
        <v-col align-self="center" class="text-right"><h2>Cette fonctionnalité arrive prochainement !</h2></v-col>
        <v-col align-self="center" class="text-left">
          <jumping-pimentos/>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-img :src="require('@/assets/site-en-construction.png')" contain max-width="1080" class="ma-auto mb-6"></v-img>
    </v-col>
  </v-row>
</template>

<script>
import JumpingPimentos from "./JumpingPimentos";

export default {
  name: "ComingSoon",
  components: {JumpingPimentos}
}
</script>

<style scoped>

</style>