import APIService from "./api.service";

class GameInPlayService {
    async getGamesInPlay() {
        return APIService.get('/game_in_plays');
    }

    async getGameInPlay(gameInPlayId) {
        return APIService.get(gameInPlayId);
    }

    async createGameInPlay(data) {
        return APIService.post('/game_in_plays', data);
    }

    async createCurrentStagePlayerInteractions(data) {
        return APIService.post('/game_in_play_stage_player_interactions', data)
    }

    async updateGameInPlay(data) {
        return APIService.put(data['@id'], data);
    }

    async updateGameInPlayPlayer(gameInPlayPlayer) {
        return APIService.put(gameInPlayPlayer['@id'], gameInPlayPlayer);
    }

    async deleteGameInPlay(gameInPlayId) {
        return APIService.delete(gameInPlayId);
    }

    async selectWinner(payload) {
        let url = payload.gameId + '/winner';
        if (payload.playerWinnerId !== null) {
            url += payload.playerWinnerId;
        } else {
            url += '/players'
        }
        return APIService.put(url, payload);
    }

    async extendDuration(payload) {
        let url = payload.gameId + '/extend_duration'
        return APIService.put(url, payload);
    }

    async getGameInPlayStageInstructions(currentGame, playerNumber) {
        let url = currentGame.game['@id'];
        url += currentGame.currentStage['@id'];
        url += '/game_stage_instructions';
        url += '?playerNumber=' + playerNumber
        return APIService.get(url);
    }

    async getGameInPlayStagePlayerInteractionFrom(currentGame, playerNumber) {
        let url = currentGame.game['@id'];
        url += currentGame.currentStage['@id'];
        url += '/game_stage_player_interactions_froms';
        url += '?playerNumberFrom=' + playerNumber
        return APIService.get(url);
    }

    async getGameInPlayStagePlayerInteractionDestination(currentGame, playerNumber) {
        let url = '/game_in_play_stage_player_interactions';
        url += '?playerTo=' + playerNumber
        url += '&gameInPlay=' + currentGame.id;
        url += '&gameStagePlayerInteraction.gameStageDestination=' + currentGame.currentStage.id;
        return APIService.get(url);
    }

    async getGameInPlayStageActions(currentGame, playerNumber) {
        let url = currentGame.game['@id'];
        url += currentGame.currentStage['@id'];
        url += '/game_stage_actions';
        url += '?playerNumber=' + playerNumber
        return APIService.get(url);
    }
}

export default new GameInPlayService();