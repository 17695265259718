import {getField, updateField} from 'vuex-map-fields';
import PlayerService from '../../services/player.service';

// async function sleep(ms) {
//     return new Promise(resolve => setTimeout(resolve, ms));
// }

export const player = {
    namespaced: true,
    state: {
        id: null,
        '@id': null,
        playerId: null,
        avatar: null,
        avatarSavedImage: null,
        nickName: null,
        firstName: null,
        lastName: null,
        email: null,
        birthday: null,
        gender: null,
        phoneNumber: null,
        subscribedToNewsletter: null,
        teams: [],
        awardPoints: 0,
        personnalDescription: null,
        departement: '',
        receivedInvitations: [],
        sendedInvitations: [],
        isLoadingPlayer: false,
        isTutorialDone: false,
        gameCreditsCoins:0,
        nbGamesForToday:0

    },
    getters: {
        getField,
        getPlayerId(state) {
            return state.playerId;
        },
        getPlayerTeams(state) {
            return state.teams;
        },
        getPlayerHydraId(state) {
            return state['@id'];
        },
    },
    mutations: {
        updateField,
        SET_PLAYER(state, payload) {
            state.id = payload.id;
            state['@id'] = '/players/' + payload.playerId;
            state.firstName = payload.firstName
            state.lastName = payload.lastName;
            state.email = payload.email;
            state.birthday = payload.birthday;
            state.gender = payload.gender;
            state.phoneNumber = payload.phoneNumber;
            state.subscribedToNewsletter = payload.subscribedToNewsletter;
            state.nickName = payload.nickName;
            state.playerId = payload.playerId;
            state.teams = payload.teams;
            state.awardPoints = payload.awardPoints;
            state.gameCreditsCoins = payload.gameCreditsCoins;
            state.personnalDescription = payload.personnalDescription;
            state.departement = payload.departement;
            state.sendedInvitations = payload.sendedInvitations;
            state.receivedInvitations = payload.receivedInvitations;
            state.avatar = payload.avatar;
            state.avatarSavedImage = payload.avatarSavedImage;
            state.isTutorialDone = payload.isTutorialDone;
            state.nbGamesForToday = payload.nbGamesForToday;
        }
    },
    actions: {
        async getMe({commit, state}) {
            state.isLoadingPlayer = true;
            return new Promise((resolve, reject) => {
                PlayerService.getUserBoard().then(response => {
                    commit('SET_PLAYER', response.data);
                    commit('mercure/SET_JWT_TOKEN', response.data.notificationsJWT, {root: true});
                    return resolve('/players/' + response.data.id);
                }).catch(error => {
                    return reject(error);
                }).finally(() => {
                    state.isLoadingPlayer = false;
                });
            })

        },
        async setTutorialDone({state, dispatch}) {
            return new Promise((resolve, reject) => {
                PlayerService.setUserTutorialDone().then(response => {
                    state.isTutorialDone = true;
                    resolve(response.data);
                }).catch(e => {
                    console.error(e);
                    dispatch('message/showErrorMessage', 'Erreur lors de la validation du tutorial', {root: true});
                    reject(e);
                })
            })
        },
        async updateMe({state, commit, dispatch}) {
            let playerUpdate = {
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                birthday: state.birthday,
                gender: state.gender,
                phoneNumber: state.phoneNumber,
                subscribedToNewsletter: state.subscribedToNewsletter,
                departement: state.departement,
            }
            return new Promise((resolve, reject) => {
                PlayerService.setUserProfile(playerUpdate).then(response => {
                    commit('SET_PLAYER', response.data);
                    resolve(response.data);
                }).catch(e => {
                    console.error(e);
                    dispatch('message/showErrorMessage', 'Erreur lors de la mise à jour', {root: true});
                    reject(e);
                })
            })
        },
        async deleteMe({dispatch}) {
            return new Promise((resolve, reject) => {
                PlayerService.deleteUserProfile().then(() => {
                    dispatch('message/showSuccessMessage', 'Votre compte a été supprimé avec succès, nous espérons vous revoir prochainement !', {root: true});
                    dispatch('auth/logout', '', {root: true});
                    resolve();
                }).catch(e => {
                    console.error(e);
                    dispatch('message/showErrorMessage', 'Erreur lors de la suppression de votre compte', {root: true});
                    reject(e);
                })
            })
        },
        async removeCouple({dispatch}, team) {
            return new Promise((resolve, reject) => {
                PlayerService.deleteTeam(team).then(() => {
                    dispatch('message/showSuccessMessage', 'Votre lien de partenaire a été supprimé avec succès !', {root: true});
                    dispatch('getMe');
                    resolve();
                }).catch(e => {
                    console.error(e);
                    dispatch('message/showErrorMessage', 'Erreur lors de la suppression de votre lien de partenaire', {root: true});
                    reject(e);
                })
            })
        },
        async deleteSendedInvitation({dispatch}, invitationId) {
            return new Promise((resolve, reject) => {
                PlayerService.deleteSendedInvitation(invitationId).then(() => {
                    dispatch('message/showSuccessMessage', 'Votre invitation a été supprimée avec succès !', {root: true});
                    dispatch('getMe');
                    resolve();
                }).catch(e => {
                    console.error(e);
                    dispatch('message/showErrorMessage', 'Erreur lors de la suppression de votre invitation', {root: true});
                    reject(e);
                })
            })
        },
        async acceptSendedInvitation({dispatch}, invitation) {
            return new Promise((resolve, reject) => {
                invitation['@id'] = '/team_invitations/' + invitation.id;
                PlayerService.acceptSendedInvitation(invitation).then(() => {
                    dispatch('message/showSuccessMessage', 'Votre acceptation a été envoyée avec succès !', {root: true});
                    dispatch('getMe');
                    resolve();
                }).catch(e => {
                    console.error(e);
                    dispatch('message/showErrorMessage', 'Erreur lors de l\'envoie de votre acceptation', {root: true});
                    reject(e);
                })
            })
        },
        async sendInvitation({dispatch, state}, emailGuest) {
            return new Promise((resolve, reject) => {
                let payload = {
                    player: {'@id': state['@id']},
                    emailGuest: {
                        "emailToInvite": emailGuest
                    }
                }
                PlayerService.sendInvitation(payload).then(() => {
                    dispatch('message/showSuccessMessage', 'Votre invitation a été envoyée avec succès !', {root: true});
                    dispatch('getMe');
                    resolve();
                }).catch(e => {
                    console.error(e);
                    dispatch('message/showErrorMessage', 'Erreur lors de l\'envoie de votre invitation', {root: true});
                    reject(e);
                })
            })
        }
    }
}
