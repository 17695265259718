import AvatarService from "../../services/avatar.service"

export const avatar = {
    namespaced: true,
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        async saveAvatar({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                let avatar = {
                    player: payload.player,
                    avatarElements: payload.avatarElements,
                    genderType: payload.genderType,
                    skinColor: payload.skinColor,
                    shapeType: payload.shapeType
                }
                if (payload['@id']) {
                    avatar['@id'] = payload['@id'];
                    AvatarService.updateAvatar(payload).then(response => {
                        dispatch("message/showSuccessMessage", "Avatar sauvegardé avec succès !", {root: true})
                        return resolve(response.data);
                    }).catch(error => {
                        dispatch("message/showErrorMessage", "Erreur sauvegarde d'avatar", {root: true})
                        return reject(error);
                    })
                } else {
                    AvatarService.createAvatar(avatar).then(response => {
                        dispatch("message/showSuccessMessage", "Avatar sauvegardé avec succès !", {root: true})
                        return resolve(response.data);
                    }).catch(error => {
                        dispatch("message/showErrorMessage", "Erreur sauvegarde d'avatar", {root: true})
                        return reject(error);
                    })
                }
            })
        }
    }
}