<template>
  <div class="text-left" v-if="receivedInvitations.length && !you">
    <h3 class="mb-3">Vous avez reçu les invitations suivantes :</h3>
    <v-row v-for="invitation in receivedInvitations" v-bind:key="invitation.id" align="center" no-gutters>
      <v-col sm="3" cols="2" class="pa-0">
        <v-img :src="avatarImagePath" contain/>
      </v-col>
      <v-col sm="6" cols="5" class="pa-0 text-center">{{ invitation.hostPlayer.nickName }}</v-col>
      <v-col sm="3" cols="5" class="text-right">
        <v-btn @click="confirmAccept(invitation)" class="pg-color-petite-orchid" dark :disabled="hasMadeAChoice">
          <pg-icon s-m>i-couple</pg-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">Accepter</span>
        </v-btn>
        <v-btn class="pg-color-deep-red" dark @click="confirmDelete(invitation.id)" :disabled="hasMadeAChoice">
          <pg-icon color="white" s-m class="mr-1">i-close</pg-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">Refuser</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="mt-5 mb-5"/>
  </div>
</template>

<script>
import PlayerMixin from "../../../mixins/PlayerMixin";
import PgIcon from "../../PGIcon";
import {mapActions} from "vuex";
import PopUpMixin from "../../../mixins/PopUpMixin";

export default {
  name: "CoupleReceivedInvitation",
  data() {
    return {
      hasMadeAChoice: false,
    }
  },
  components: {PgIcon},
  mixins: [PlayerMixin, PopUpMixin],
  methods: {
    ...mapActions({
      deleteSendedInvitation: 'player/deleteSendedInvitation',
      acceptSendedInvitation: 'player/acceptSendedInvitation'
    }),
    confirmDelete(invitationId) {
      this.hasMadeAChoice=true;
      this.showPopUp({
        title: "Refuser une invitation ?",
        message: 'Voulez-vous vraiment refuser cette invitation  ?',
        buttonTitle: 'Confirmer',
        callback: function () {
          this.deleteSendedInvitation(invitationId);
        }.bind(this),
        callbackCancel: function () {
          this.hasMadeAChoice = false;
        }.bind(this)
      })
    },
    confirmAccept(invitation) {
      this.hasMadeAChoice=true;
      this.showPopUp({
        title: "Accepter une invitation ?",
        message: 'Voulez-vous vraiment accepter cette invitation  ?',
        buttonTitle: 'Confirmer',
        callback: function () {
          this.acceptSendedInvitation(invitation);
        }.bind(this),
        callbackCancel: function () {
          this.hasMadeAChoice = false;
        }.bind(this)
      })
    },
  }
}
</script>

<style scoped>

</style>