<template>
  <v-footer min-width="100vw" class="pa-0" app fixed>
    <v-bottom-navigation class="wave" v-model="value" dark>
      <v-container class="ma-0 pa-0">
        <v-row no-gutters align="center" align-content="center" justify="center" style="max-width: 1785px">
          <v-col cols="3">
            <AbortGameButton/>
          </v-col>
          <v-col cols="6" align-self="center" class="text-center">
            <v-btn value="tchat" :to="{name:'Home'}" disabled icon class="ma-auto">
              <span>Tchat</span>
              <pg-icon XL>i-chat</pg-icon>
            </v-btn>
            <v-btn value="tchat" :to="{name:'Home'}" icon class="ma-auto">
              <span>Accueil</span>
              <pg-icon XL>i-home</pg-icon>
            </v-btn>
          </v-col>
          <v-col cols="3">
          </v-col>
        </v-row>
      </v-container>
    </v-bottom-navigation>
  </v-footer>
</template>

<script>


import PgIcon from "../PGIcon";
import AbortGameButton from "./AbortGameButton";

export default {
  name: "FooterPlayMenu",
  components: {AbortGameButton, PgIcon},
  data: () => ({value: 'play'}),
  methods: {
    giveUp() {
      window.alert('Vous voulez abandonner ?');
    }
  }
}
</script>

<style scoped>
.wave {
  position: relative;
  height: 50px;
  width: 100%;
  background: linear-gradient(135deg, #54000E, #811F31, #A02838, #A7462F, #B8642A, #BF7527, #C48D27, #C4B427, #D8D225);
  border-radius: 0% 44% 0% 0%;
}
</style>