import axios from "../plugins/axios";
import authHeader from "@/services/auth/auth-headers";
import store from "../store";

const API_URL = process.env.VUE_APP_API_URL;

class APIService {
    async post(path, data, config) {
        authHeader();
        let response;
        try {
            response = await axios.post(API_URL + path, data, config);
        } catch (e) {
            if (e.response && e.response.status === 401) {
                console.error(e.data)
                if (e.response.data && e.response.data.message && e.response.data.message === 'Invalid credentials.') {
                    store.dispatch("message/showErrorMessage", "Mauvaise combinaison de login/mot de passe.")
                } else {
                    store.dispatch("message/showErrorMessage", "Votre session a expiré.")
                    store.dispatch("auth/logout");
                }
            }
            if (e.response && e.response.status === 403) {
                console.error(e.response.data.message)
                    store.dispatch("message/showErrorMessage", e.response.data.message)
                    store.dispatch("auth/logout");
            }
            else {
                let message = e;
                if (e.response.data) {
                    message = e.response.data;
                }
                store.dispatch("message/showErrorMessage", message);
            }
            return Promise.reject(e.response);
        }

        return Promise.resolve(response);
    }

    async put(path, data) {
        authHeader();
        let response;
        try {
            response = await axios.put(API_URL + path, data);
        } catch (e) {
            console.error(e)
            if (e.response && e.response.status === 401) {
                store.dispatch("message/showErrorMessage", "Votre session a expiré.")
                store.dispatch("auth/logout");
            } else {
                store.dispatch("message/showErrorMessage", e);
            }
            return Promise.reject(e.response);
        }

        return Promise.resolve(response);
    }

    async get(path) {
        authHeader();
        let response;
        try {
            response = await axios.get(API_URL + path);
        } catch (e) {
            console.error(e)
            if (e.response && e.response.status === 401) {
                store.dispatch("message/showErrorMessage", "Votre session a expiré.")
                store.dispatch("auth/logout");
            } else {
                store.dispatch("message/showErrorMessage", e);
            }
            return Promise.reject(e.response);
        }

        return Promise.resolve(response);
    }

    async delete(path) {
        authHeader();
        let response;
        try {
            response = await axios.delete(API_URL + path);
        } catch (e) {
            console.error(e);
            if (e.response && e.response.status === 401) {
                store.dispatch("message/showErrorMessage", "Votre session a expiré.")
                store.dispatch("auth/logout");
            } else {
                store.dispatch("message/showErrorMessage", e);
            }
            return Promise.reject(e.response);
        }
        return Promise.resolve(response);
    }
}

export default new APIService();