import {getField, updateField} from 'vuex-map-fields';

export const pop_up = {
    namespaced: true,
    state: {
        dialog: false,
        showCancel: true,
        defaultPopUp: {
            id: -1,
            title: '',
            message: '',
            buttonTitle: 'OK',
            buttonCancel: 'Annuler',
            callback: function () {
                console.log('pop-up callback not initialized !')
            },
            callbackCancel: function () {
                console.log('pop-up callback cancel not initialized !')
            },
            awardPointsNeededToPlay: null,
            gameCreditsCoinsNeededToPlay: null,
        },
        currentId: -1,
        title: '',
        message: '',
        buttonTitle: 'OK',
        buttonCancel: 'Annuler',
        callback: function () {
            console.log('pop-up callback not initialized !')
        },
        callbackCancel: function () {
            console.log('pop-up callback cancel not initialized !')
        },
        currentPopUp: null,
        popUpPile: [],
        interValId: null,
        nextId: 0,
        persistent: false,
        hidePimentos: false,
        awardPointsNeededToPlay: null,
        gameCreditsCoinsNeededToPlay: null,
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
    },
    actions: {
        async showPopUp({state, dispatch}, payload) {
            return new Promise((resolve) => {
                let popUp = {};
                Object.assign(popUp, state.defaultPopUp);
                popUp.id = state.nextId;
                popUp.message = payload.message;
                popUp.title = payload.title;
                popUp.buttonTitle = payload.buttonTitle;
                popUp.buttonCancel = payload.buttonCancel != undefined ? payload.buttonCancel : 'Annuler';
                popUp.callback = payload.callback;
                popUp.callbackCancel = payload.callbackCancel != undefined ? payload.callbackCancel : function () {
                    console.log('pop-up callback cancel not initialized !')
                };
                popUp.showCancel = payload.showCancel != undefined ? payload.showCancel : true;
                popUp.persistent = payload.persistent != undefined ? payload.persistent : false;
                popUp.hidePimentos = payload.hidePimentos != undefined ? payload.hidePimentos : false;
                popUp.awardPointsNeededToPlay = payload.awardPointsNeededToPlay != undefined ? payload.awardPointsNeededToPlay : null;
                popUp.gameCreditsCoinsNeededToPlay = payload.gameCreditsCoinsNeededToPlay != undefined ? payload.gameCreditsCoinsNeededToPlay : null;
                state.popUpPile.push(popUp);
                state.nextId++;
                if (!state.dialog) {
                    dispatch("popPopUp");
                }
                return resolve(popUp.id);
            })

        },
        async popPopUp({state}) {
            let popUp = state.popUpPile.pop();
            if (popUp !== undefined) {
                state.dialog = true;
                state.title = popUp.title;
                state.currentId = popUp.id;
                state.message = popUp.message;
                state.callback = popUp.callback;
                state.callbackCancel = popUp.callbackCancel;
                popUp.buttonTitle ? state.buttonTitle = popUp.buttonTitle : false;
                popUp.buttonCancel ? state.buttonCancel = popUp.buttonCancel : false;
                state.showCancel = popUp.showCancel;
                state.persistent = popUp.persistent;
                state.hidePimentos = popUp.hidePimentos;
                state.awardPointsNeededToPlay = popUp.awardPointsNeededToPlay;
                state.gameCreditsCoinsNeededToPlay = popUp.gameCreditsCoinsNeededToPlay;
            }
            if (state.interValId !== null) {
                clearInterval(state.interValId);
                state.interValId = null;
            }
        },
        async removePopUp({state, dispatch}, popUpId) {
            const removeIndex = state.popUpPile.findIndex(popUp => popUp.id === popUpId);
            state.popUpPile.splice(removeIndex, 1);
            if (state.currentId === popUpId) {
                dispatch('hidePopUp');
            }
        },
        async hidePopUp({state, dispatch}) {
            state.dialog = false;
            state.currentId = -1;
            state.title = "";
            state.message = "";
            state.buttonTitle = 'OK';
            state.buttonCancel = 'Annuler';
            state.callback = function () {
                console.log('pop-up callback not initialized !')
            };
            state.callbackCancel = function () {
                console.log('pop-up callback cancel not initialized !')
            };
            if (state.interValId === null) {
                state.interValId = setInterval(() => {
                    dispatch('popPopUp');
                }, 500);
            }
            state.persistent = false;
            state.awardPointsNeededToPlay = null;
            state.gameCreditsCoinsNeededToPlay = null;
        }

    }
}