import {getField, updateField} from 'vuex-map-fields';
import ShopService from "../../services/shop.service";

export const shop = {
    namespaced: true,
    state: {
        productCategories: [],
        awardPointsPack: [],
        gameCreditsCoinsPack: [],
        showPayment: false,
        cartItems: [],
        cartTotalAmount: 0,
        currentPaymentIntentId: null
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
    },
    actions: {
        async getProductCategories({state}) {
            return new Promise((resolve, reject) => {
                ShopService.getProductCategories().then(response => {
                    state.productCategories = response.data['hydra:member'];
                    resolve();
                }).catch(error => {
                    reject(error);
                });
            })
        },
        async getAwardPointsPack({state}) {
            return new Promise((resolve, reject) => {
                if (state.awardPointsPack.length > 0) {
                    return resolve(state.awardPointsPack);
                }
                ShopService.getAwardPointsPacks().then(response => {
                    state.awardPointsPack = response.data['hydra:member'];
                    resolve();
                }).catch(error => {
                    reject(error);
                });
            })
        },
        async getGameCreditsCoinsPack({state}) {
            return new Promise((resolve, reject) => {
                if (state.gameCreditsCoinsPack.length > 0) {
                    return resolve(state.gameCreditsCoinsPack);
                }
                ShopService.getGameCreditsCoinsPacks().then(response => {
                    state.gameCreditsCoinsPack = response.data['hydra:member'];
                    resolve();
                }).catch(error => {
                    reject(error);
                });
            })
        },
        addItemToCartAndPay({state}, item) {
            let price = 0;
            if (item.price) {
                price = parseInt(parseFloat(item.price) * 100);
            }
            state.cartItems = [];
            state.cartItems.push(item);
            state.cartTotalAmount += price;
            state.showPayment = true;
        },
        cancelOrder({state}) {
            state.cartItems = [];
            state.cartTotalAmount = 0;
            state.showPayment = false;
            if (state.currentPaymentIntentId != null) {
                ShopService.deletePaymentIntent(state.currentPaymentIntentId).finally(() => {
                    state.currentPaymentIntentId = null;
                })
            }
        },
        getPayments({rootGetters}, pagination) {
            let myId = rootGetters["player/getPlayerId"];
            let payload = {
                pagination: pagination,
                playerId: myId,
            }
            return new Promise((resolve, reject) => {
                ShopService.getPayments(payload).then(response => {
                    resolve({
                        payments: response.data['hydra:member'],
                        totalPayments: response.data['hydra:totalItems']
                    });
                }).catch(error => {
                    reject(error);
                })
            })
        }
    }
}