<template>
  <v-container fluid class="pa-0 mb-6">
    <v-row no-gutters align="center" justify="center" v-show="!(loading || avatarElementCategories.length<=0)">
      <v-col cols="12" sm="7" align-self="start" class="text-center">
        <v-row justify="center" align="center">
          <v-col cols="7">
            <p-g-avatar v-model="selectedAvatarElements" :size="200" id="avatar-view" class="clickable"
                        @click="$vuetify.goTo('#avatar-elements')"/>
          </v-col>
          <v-col cols="5">
            <v-row>
              <v-btn class="ma-auto pg-hot-gradient mb-2" dark @click="generateRandom(true)"
                     :disabled="loadingAvatar">
                <pg-icon>i-dice</pg-icon>
                Aléatoire
              </v-btn>
            </v-row>
            <v-row>
              <v-btn class="ma-auto pg-hot-gradient mb-2" dark @click="clear" :disabled="loadingAvatar">
                <pg-icon>i-stop</pg-icon>
                Effacer
              </v-btn>
            </v-row>
            <v-row>
              <v-btn class="ma-auto pg-hot-gradient" dark @click="save" :disabled="loadingAvatar">
                <pg-icon>i-play</pg-icon>
                Sauver
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="9" align-self="center" id="avatar-filters">
        <v-row align="center" justify="center">
          <v-col cols="6" sm="3">
            <avatar-gender-type-select v-model="genderTypeSelected" :avatar-gender-types="avatarGenderTypes"/>
          </v-col>
          <v-col cols="6" sm="3">
            <avatar-skin-color-select v-model="skinColorSelected" :avatar-skin-colors="avatarSkinColors"/>
          </v-col>
          <v-col cols="6" sm="3">
            <avatar-hair-color-select v-model="hairColorSelected" :avatar-hair-colors="avatarHairColors"/>
          </v-col>
          <v-col cols="6" sm="3" v-show="false">
            <avatar-shape-type-select v-model="shapeTypeSelected" :avatar-shape-types="avatarShapeTypes"/>
          </v-col>
        </v-row>
        <v-row class="purple pa-1" align="center" justify="start" id="avatar-elements">
          <v-col cols="3" sm="1" v-for="(avatarElementCategory, index) in avatarElementCategories"
                 :key="avatarElementCategory['@id']"
                 :class="$vuetify.breakpoint.mobile?'pa-1':'pa-2'">
            <v-avatar rounded class="elevation-10" width="64px" height="64px">
              <v-img contain width="64px" max-width="64px" :src="getImagePath(avatarElementCategory.img.contentUrl)"
                     class="white clickable ma-auto pa-0 ma-0"
                     v-if="avatarElementCategory.img && avatarElementCategory.img.contentUrl"
                     @click="selectCategory(avatarElementCategory, index)">
              </v-img>
            </v-avatar>
          </v-col>
        </v-row>
        <v-row :class="($vuetify.breakpoint.mobile?'pa-1':'pa-2')+' pink'" style="min-height: 200px">
          <v-col cols="3" sm="2" class="pa-1"
                 v-if="selectedCategory && !firstCategorSelected && !selectedCategory.isSkinRelated ">
            <v-avatar rounded class="elevation-10" width="96px" height="96px">
              <v-img contain width="96px" max-width="96px"
                     src="@/assets/cancellation-avatar.png"
                     class="white clickable elevation-10"
                     @click="removeSelectedElementForCategory"/>
            </v-avatar>
          </v-col>
          <v-col class="pa-1" v-show="isShown(avatarElement)"
                 v-for="(avatarElement,i) in avatarParentElements"
                 :key="avatarElement['@id']" cols="3" sm="2">
            <v-avatar rounded class="elevation-10" width="96px" height="96px" v-if="avatarElement.img"
                      @click="$set(showedChildElements,i,!showedChildElements[i])"
            >
              <v-img contain width="96px" max-width="96px" :src="getImagePath(avatarElement.img.contentUrl)"
                     class="white pa-0 ma-0 clickable parent">
              </v-img>
            </v-avatar>
            <v-dialog v-model="showedChildElements[i]" max-width="130px" scrollable v-if="showedChildElements[i]">
              <v-row no-gutters>
                <v-col v-for="(avatarElementChild,j) in avatarElement.childAvatarElements" :key="j" cols="12"
                       class="pa-2" v-show="isShown(avatarElementChild)">
                  <v-avatar rounded class="elevation-10" width="96px" height="96px" v-if="isShown(avatarElementChild)">
                    <v-img width="96px" max-width="96px"
                           :src="getImagePath(avatarElementChild.img.contentUrl)"
                           v-if="avatarElementChild.img && avatarElementChild.img.contentUrl"
                           class="white zoom clickable"
                           @click="selectElement(avatarElementChild)">
                      <aside class=ribbon-banner :style="getRubbanColor(avatarElementChild)"></aside>
                    </v-img>
                  </v-avatar>
                </v-col>
              </v-row>
            </v-dialog>
          </v-col>
          <v-col cols="3" sm="2" v-for="(avatarElement,index) in avatarElements" :key="index" class="pa-1"
                 v-show="isShown(avatarElement)">
            <v-avatar rounded class="elevation-10" width="96px" height="96px">
              <v-img contain width="96px" max-width="96px" :src="getImagePath(avatarElement.img.contentUrl)"
                     class="white clickable elevation-10 zoom" v-if="avatarElement.img && avatarElement.img.contentUrl"
                     @click="selectElement(avatarElement)">
                <aside class=ribbon-banner :style="getRubbanColor(avatarElement)"></aside>
              </v-img>
            </v-avatar>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" v-if="loading">
      <v-progress-circular
          :size="300"
          :width="7"
          color="purple"
          indeterminate
          class="ma-auto"

      ></v-progress-circular>
    </v-row>
  </v-container>
</template>

<script>
import AvatarGenderTypeSelect from "./AvatarGenderTypeSelect";
import AvatarSkinColorSelect from "./AvatarSkinColorSelect";
import AvatarShapeTypeSelect from "./AvatarShapeTypeSelect";
import AvatarHairColorSelect from "./AvatarHairColorSelect";
import AvatarMixin from "../../mixins/AvatarMixin";
import {mapActions} from "vuex";
import PlayerMixin from "../../mixins/PlayerMixin";
import PGAvatar from "./Avatar";
import AuthPlayerNeeded from "../../mixins/AuthPlayerNeeded";
import PgIcon from "../PGIcon";
import AvatarService from "../../services/avatar.service";

export default {
  name: "AvatarBuilder",
  components: {
    PgIcon,
    PGAvatar, AvatarShapeTypeSelect, AvatarHairColorSelect, AvatarSkinColorSelect, AvatarGenderTypeSelect
  },
  mixins: [AvatarMixin, PlayerMixin, AuthPlayerNeeded],
  data() {
    return {
      selectedAvatarElements: [],
      elementsForSelectedCategory: [],
      genderTypeSelected: null,
      skinColorSelected: null,
      hairColorSelected: null,
      shapeTypeSelected: null,
      src: null,
      selectedCategory: null,
      showedChildElements: [],
      loadingAvatar: false,
      avatarElementCategories: [],
      avatarGenderTypes: [],
      avatarShapeTypes: [],
      avatarHairColors: [],
      avatarSkinColors: [],
      loading: false,
      firstCategorSelected: false,
    }
  },
  computed: {
    avatarElements: function () {
      return this.elementsForSelectedCategory.filter((element) => {
        return element.firstLevel && !element.childAvatarElements.length;
      });
    },
    avatarParentElements: function () {
      return this.elementsForSelectedCategory.filter(element => element.firstLevel && element.childAvatarElements.length);
    },
  },
  methods: {
    ...mapActions({
      saveAvatar: "avatar/saveAvatar"
    }),
    async getAvatarGenderTypes() {
      return new Promise((resolve, reject) => {
        if (this.avatarGenderTypes.length <= 0) {
          AvatarService.getAvatarGenderTypes().then(response => {
            this.avatarGenderTypes = response.data['hydra:member'];
            return resolve();
          }).catch(error => {
            return reject(error);
          });
        } else {
          console.log("Loading gender types from cache.");
          return resolve();
        }
      })
    },
    async getAvatarShapeTypes() {
      return new Promise((resolve, reject) => {
        if (this.avatarShapeTypes.length <= 0) {
          AvatarService.getAvatarShapeTypes().then(response => {
            this.avatarShapeTypes = response.data['hydra:member'];
            return resolve();
          }).catch(error => {
            return reject(error);
          });
        } else {
          console.log("Loading shape types from cache.");
          return resolve();
        }
      })
    },
    async getAvatarHairColors() {
      return new Promise((resolve, reject) => {
        if (this.avatarHairColors.length <= 0) {
          AvatarService.getAvatarHairColors().then(response => {
            this.avatarHairColors = response.data['hydra:member'];
            return resolve();
          }).catch(error => {
            return reject(error);
          });
        } else {
          console.log("Loading hair colors from cache.");
          return resolve();
        }
      })
    },
    async getAvatarSkinColors() {
      return new Promise((resolve, reject) => {
        if (this.avatarSkinColors.length <= 0) {
          AvatarService.getAvatarSkinColors().then(response => {
            this.avatarSkinColors = response.data['hydra:member'];
            return resolve();
          }).catch(error => {
            return reject(error);
          });
        } else {
          console.log("Loading skin colors from cache.")
          return resolve();
        }
      })
    },
    async getAvatarElementCategories() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        if (this.avatarElementCategories.length > 0) {
          console.log("Loading avatar elements and categories from cache.")
          return resolve();
        }
        AvatarService.getAvatarElementCategories().then(response => {
          this.avatarElementCategories = response.data['hydra:member'].sort(function (a, b) {
            return a.zIndex - b.zIndex;
          });
          return resolve();
        }).catch(error => {
          return reject(error);
        }).finally(() => {
          this.loading = false;
        });
      })
    },
    clear() {
      this.selectedAvatarElements = [];
      this.shapeTypeSelected = null;
      this.skinColorSelected = null;
      this.hairColorSelected = null;
      this.genderTypeSelected = null;
      this.selectElement(this.avatarElementCategories[0].avatarElements[0]);
    }
    ,
    save() {
      let avatarElements = [];
      this.loadingAvatar = true;
      this.selectedAvatarElements.forEach(selectedAvatarElement => {
        try {
          avatarElements.push(selectedAvatarElement.element['@id']);
        } catch (e) {
          console.error(selectedAvatarElement)
        }
      })
      let avatarToSave = {
        player: this.playerHydraId,
        avatarElements: avatarElements,
        genderType: this.genderTypeSelected,
        skinColor: this.skinColorSelected,
        shapeType: this.shapeTypeSelected
      }
      if (this.playerAvatar != null) {
        avatarToSave['@id'] = this.playerAvatar;
      }
      this.saveAvatar(avatarToSave).then((avatar) => {
        this.playerAvatar = avatar['@id'];
      }).finally(() => {
        this.loadingAvatar = false;
        this.getMe();
      })
    }
    ,
    getRubbanColor(element) {
      if (element && element.hairColor) {
        return 'background-color:' + this.getHairColor(element.hairColor) + ';';
      }
    }
    ,
    getHairColor(hairColorId) {
      return this.avatarHairColors.find(hairColor => hairColor['@id'] === hairColorId).color;
    }
    ,
    isShown(element, hideParent = false) {
      if (hideParent && element.childAvatarElements.length > 0) {
        return !hideParent;
      }
      if (this.selectedCategory && this.selectedCategory.isSkinRelated) {
        if (this.skinColorSelected !== null) {
          if (element.skinColor && (element.skinColor !== this.skinColorSelected)) {
            return false
          }
        }
      }
      if (element.hairColor != null && this.hairColorSelected != null && (element.hairColor !== this.hairColorSelected)) {
        return false;
      }
      if (element.shapeType != null && this.shapeTypeSelected != null && element.shapeType !== this.shapeTypeSelected) {
        return false;
      }
      if (element.genderType != null && this.genderTypeSelected != null && element.genderType !== this.genderTypeSelected) {
        return false;
      }
      return true;
    }
    ,
    selectCategory(category, index = 1) {
      this.firstCategorSelected = index === 0;
      this.$vuetify.goTo("#avatar-elements");
      this.selectedCategory = category;
      this.elementsForSelectedCategory = category.avatarElements.sort((a, b) => {
        return a.avatarElementCategory > b.avatarElementCategory ? 1 : -1;
      });
    }
    ,
    selectElement(element) {
      this.$vuetify.goTo("#avatar-view");
      if (element && element.avatarElementCategory) {
        let index = this.selectedAvatarElements.findIndex(selectedElement => selectedElement.category === element.avatarElementCategory)
        if (index >= 0) {
          this.selectedAvatarElements[index].element = {...element};
        } else {
          let zIndex = this.avatarElementCategories.find((elementCategory) => {
            return elementCategory['@id'] === element.avatarElementCategory
          }).zIndex;
          this.selectedAvatarElements.push({
            category: element.avatarElementCategory,
            zIndex: zIndex,
            element: element
          })
          this.$forceUpdate();
        }
      }
    }
    ,
    removeSelectedElementForCategory() {
      this.$vuetify.goTo("#avatar-view");
      if (this.selectedCategory) {
        let index = this.selectedAvatarElements.findIndex(selectedElement => selectedElement.category === this.selectedCategory['@id']);
        if (index >= 0) {
          this.selectedAvatarElements.splice(index, 1);
        }
      }
    }
    ,
    generateRandom(forced = false) {
      if (forced) {
        this.selectedAvatarElements = [];
      }
      if ((forced) && this.avatarElementCategories.length && !this.selectedAvatarElements.length && this.avatarSkinColors.length && this.avatarHairColors.length && this.avatarGenderTypes.length && this.avatarShapeTypes.length) {
        this.skinColorSelected = this.avatarSkinColors[Math.floor(Math.random() * this.avatarSkinColors.length)]['@id'];
        this.genderTypeSelected = this.avatarGenderTypes[Math.floor(Math.random() * this.avatarGenderTypes.length)]['@id'];
        this.shapeTypeSelected = this.avatarShapeTypes[Math.floor(Math.random() * this.avatarShapeTypes.length)]['@id'];
        this.hairColorSelected = this.avatarHairColors[Math.floor(Math.random() * this.avatarHairColors.length)]['@id'];
        this.avatarElementCategories.forEach((category) => {
          this.selectedCategory = category;
          let avatarElements = category.avatarElements.filter(element => this.isShown(element, true));
          let index = avatarElements ? Math.floor(Math.random() * avatarElements.length) : null;
          if (index >= 0) {
            this.selectedAvatarElements.push({
              category: category['@id'],
              zIndex: category.zIndex,
              element: index !== null ? avatarElements[index] : index
            })
          }
        });
        this.uniformSkinColor();
        this.selectedCategory = null;

      }
    }
    ,
    loadPlayerAvatar() {
      if (this.playerAvatar != null) {
        this.getAvatar(this.playerAvatar).then(avatar => {
          if (avatar && avatar.avatarElements.length) {
            avatar.avatarElements.forEach(avatarElement => {
              this.selectedAvatarElements.push({
                category: avatarElement.avatarElementCategory['@id'],
                zIndex: avatarElement.avatarElementCategory.zIndex,
                element: avatarElement
              })
            });
            this.shapeTypeSelected = avatar.shapeType;
            this.skinColorSelected = avatar.skinColor;
            this.hairColorSelected = avatar.hairColor;
            this.genderTypeSelected = avatar.genderType;
          }
        }).catch(error => {
          console.error(error);
        });
      }
    }
    ,
    uniformSkinColor() {
      let saveCurrentCategory = this.selectedCategory;
      this.avatarElementCategories.forEach((category) => {
        if (category.isSkinRelated) {
          this.selectedCategory = category;
          let avatarElements = category.avatarElements.filter(element => this.isShown(element));
          let index = avatarElements ? Math.floor(Math.random() * avatarElements.length) : null;
          this.selectElement(avatarElements[index]);
        }
      });
      this.selectedCategory = saveCurrentCategory;
    }
  },

  created() {
    this.loading = true;
    this.getAvatarGenderTypes().then(() => {
      this.getAvatarHairColors().then(() => {
        this.getAvatarSkinColors().then(() => {
          this.getAvatarShapeTypes().then(() => {
            this.getAvatarElementCategories().then(() => {
              this.loadPlayerAvatar();
            }).finally(() => {
              this.loading = false;
              if (this.selectedAvatarElements.length === 0) {
                this.selectElement(this.avatarElementCategories[0].avatarElements[0]);
              }
            });
          });
        });
      });
    });
  }
  ,

  watch: {
    skinColorSelected: function (after, before) {
      if (before !== after) {
        if (after != null) {
          this.uniformSkinColor();
        }
      }
    }
  }
}
</script>

<style scoped>
/* 45° CSS Ribbon Banner */
aside.ribbon-banner {
  display: block;
  position: absolute;
  top: 50px;
  left: -40px;
  width: 400px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  opacity: 0.7;
  transform: rotate(
      45deg);
  font-size: 24px;
  font-weight: 700;
  color: white;
  border: 1px solid white;
}

.flip-list-move {
  transition: transform 0.5s;
  display: inline-block;
}

.parent {
  border: 1.5mm ridge rgb(161, 28, 28);
}

/deep/ .zoom {

  background-size: cover;
}

/deep/ .zoom:hover {
  -ms-transform: scale(2.2);
  -moz-transform: scale(2.2);
  -webkit-transform: scale(2.2);
  -o-transform: scale(2.2);
  transform: scale(2.2);
}

.clickable {
  cursor: pointer;
}
</style>