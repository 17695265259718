import {createHelpers} from "vuex-map-fields";
import {EventSourcePolyfill} from 'event-source-polyfill';
import {mapActions} from "vuex";

const {mapFields} = createHelpers({
    getterType: 'mercure/getField',
    mutationType: 'mercure/updateField'
});

export default {
    computed: {
        ...mapFields({
            mercureStarted: 'started',
            jwtToken: 'jwtToken',
            lastMessage: 'lastMessage',
            lastEventId: 'lastEventId'
        }),
    },
    data: function () {
        return {
            updateEventSource: null
        }
    },
    methods: {
        ...mapActions({
            addLiveNotification: "notification/addLiveNotification",
            updateLiveGameInPlay: "current_game/updateLiveGameInPlay"
        }),
        setupMercure(topics, callBack) {
            const url = new URL(process.env.VUE_APP_MERCURE_URL);
            topics.forEach(topic => {
                url.searchParams.append('topic', topic);
            });
            this.updateEventSource && this.updateEventSource.close();
            let headers = {
                Authorization: `Bearer ` + this.jwtToken,
            };
            this.updateEventSource = new EventSourcePolyfill(url, {
                headers: headers,
            });
            this.updateEventSource.onmessage = e => {
                this.lastMessage = e.data;
                this.lastEventId = e.lastEventId;
                callBack(e);
            }
            this.updateEventSource.onopen = () => {
                console.log("Mercure ready to listen");
                this.mercureStarted = true;
            };
            this.updateEventSource.onerror = (e) => {
                if(!e.error.message || !e.error.message.startsWith('No activity within 45000 milliseconds.'))
                {
                    console.error(e);
                }
                this.mercureStarted = false;
            }
        },
        setupMercureNotification(playerId) {
            if (playerId) {
                console.log('setting up event source notifications');
                let topics = [process.env.VUE_APP_MERCURE_NOTIFICATIONS_TOPIC_URL + playerId];
                this.setupMercure(topics, (event) => {
                    let notification = JSON.parse(event.data);
                    this.addLiveNotification(notification);
                });
            }
        },
        setupMercureGameInPlay(gameInPlayId) {
            if (gameInPlayId) {
                console.log('setting up event source game in play');
                let topics = [process.env.VUE_APP_MERCURE_GAMES_IN_PLAY_TOPIC_URL + gameInPlayId];
                this.setupMercure(topics, (event) => {
                    let actions = JSON.parse(event.data);
                    this.updateLiveGameInPlay(actions);
                });
            }
        }
    },
    destroyed() {
        console.log("Destroyed : we shut down the event source")
        this.mercureStarted=false;
        if (this.updateEventSource) {
            this.updateEventSource.close();
        }
    }
}