import APIService from "./api.service";

class ShopService {

    // Product
    async getProducts(pagination = {}) {
        let url = '/products?';
        let page = pagination.page != undefined ? pagination.page : 1;
        let itemsPerPage = pagination.itemsPerPage != undefined ? pagination.itemsPerPage : 50;
        url += 'page=' + page;
        if (itemsPerPage > 0) {
            url += '&itemsPerPage=' + itemsPerPage
        }
        return await APIService.get(url);
    }

    // Product Category
    async getProductCategories(pagination = {}) {
        let url = '/product_categories?';
        let page = pagination.page != undefined ? pagination.page : 1;
        let itemsPerPage = pagination.itemsPerPage != undefined ? pagination.itemsPerPage : 10;
        url += 'page=' + page;
        if (itemsPerPage > 0) {
            url += '&itemsPerPage=' + itemsPerPage
        }
        return await APIService.get(url);
    }

    async getAwardPointsPacks(pagination = {}) {
        let url = '/products?';
        let page = pagination.page != undefined ? pagination.page : 1;
        let itemsPerPage = pagination.itemsPerPage != undefined ? pagination.itemsPerPage : 30;
        url += 'page=' + page;
        if (itemsPerPage > 0) {
            url += '&itemsPerPage=' + itemsPerPage
        }
        url += '&category.isAwardPointsProduct=true';
        return await APIService.get(url);
    }

    async getGameCreditsCoinsPacks(pagination = {}) {
        let url = '/products?';
        let page = pagination.page != undefined ? pagination.page : 1;
        let itemsPerPage = pagination.itemsPerPage != undefined ? pagination.itemsPerPage : 30;
        url += 'page=' + page;
        if (itemsPerPage > 0) {
            url += '&itemsPerPage=' + itemsPerPage
        }
        url += '&category.isGameCreditsCoinsProduct=true';
        return await APIService.get(url);
    }

    async getPayments(payload = {}) {
        let url = '/payments?';
        let pagination = payload.pagination;
        let playerId = payload.playerId;
        let page = pagination.page != undefined ? pagination.page : 1;
        let itemsPerPage = pagination.itemsPerPage != undefined ? pagination.itemsPerPage : 30;
        url += 'page=' + page;
        if (itemsPerPage > 0) {
            url += '&itemsPerPage=' + itemsPerPage
        }
        url += '&player.id=' + playerId;
        return await APIService.get(url);
    }

    async getPaymentIntent(items) {
        let url = '/shop/payment/intent';
        return await APIService.post(url, items)
    }

    async deletePaymentIntent(paymentIntentId) {
        let url = '/shop/payment/intent/';
        return await APIService.delete(url + paymentIntentId);
    }

    async payProductWithAwardPoints(product) {
        let url = '/shop/buy_with_award_points/pay_product_with_award_points' + product['@id'];
        return await APIService.post(url);
    }
}

export default new ShopService();