<template>
  <div>
    <pop-up/>
    <p-w-a-manager/>
    <router-view/>
  </div>
</template>

<script>
import AuthPlayerNeeded from "./mixins/AuthPlayerNeeded";
import PWAManager from "./components/PWAManager";
import PopUp from "./components/PopUp";
import PopUpMixin from "@/mixins/PopUpMixin";
import NotificationMixin from "./mixins/NotificationMixin";

export default {
  name: "App",
  mixins: [AuthPlayerNeeded, PopUpMixin, NotificationMixin, PopUpMixin],
  components: {PopUp, PWAManager},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    }
  },
  watch: {
    $route() {
      if (this.isAuthRoute) {
        this.$forceUpdate();
      }
    },
    isMobile(before, after) {
      if (before != after) {
        this.$forceUpdate();
      }
    },
    loggedIn: function (before, after) {
      if (before != after) {
        this.$nextTick(this.checkNeedToLogin());
      }
    }
  },
  methods: {
    onLeave: function (event) {
      const unsaved_changes_warning = 'C\'est dommage, on commençait à bien s\'amuser...';
      event.returnValue = unsaved_changes_warning;
      this.showPopUp({
        title: "Voulez-vous vraiment quitter ?",
        message: unsaved_changes_warning,
        buttonTitle: 'Quitter',
        callback: () => {
          event.returnValue = unsaved_changes_warning;
        }
      })
      return unsaved_changes_warning;
    }
  },
  created() {
    this.checkNeedToLogin();
    // if (process.env.NODE_ENV === 'production') {
    //   window.addEventListener('beforeunload', function (event) {
    //     const unsaved_changes_warning = "C'est dommage, on commençait à bien s'amuser...";
    //     event.returnValue = unsaved_changes_warning;
    //     return unsaved_changes_warning;
    //   });
    // }
  }


};
</script>
<style lang="scss">

$color-white: #ffffff;
$color-white-cream : #f2f6f7;
$color-black: #000000;
$color-very-dark-pink: #660033;
$color-grey-80: #cccccc;
$color-dark-moderate-red: #993333;
$color-maroon: #660000;
$color-deep-red: #660033;
$color-petite-orchid: #cc9999;
$color-strong-orange: #cc9933;
$color-persian-red: #cc3333;
$color-strong-orange-transparent: rgba(204, 153, 51, 0.2);
$color-black-transparent: rgba(0, 0, 0, 0.25);
$color-egg-yellow : #fff8eb;

$body-font-family: 'Roboto';
$title-font: 'Lato';

* {
  word-break: break-word;
}
html, body {
  overflow-y: auto !important;
}
.pimentos-text {
  font-family: "Luckiest Guy";
  font-size: large;
}

.v-application {
  font-family: $body-font-family, sans-serif !important;

  .title { // To pin point specific classes of some components
    font-family: $title-font, cursive !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
  }

  .full-title { // To pin point specific classes of some components
    font-family: $title-font, cursive !important;
  }
}

.pg-color-very-dark-pink {
  background-color: $color-very-dark-pink !important;
}

.pg-color-maroon {
  background-color: $color-maroon !important;
}
.pg-color-egg-yellow{
  background-color: $color-egg-yellow !important;
}
.pg-color-deep-red {
  background-color: $color-maroon !important;
}

.pg-color-dark-moderate-red {
  background-color: $color-dark-moderate-red !important;
}

.pg-color-petite-orchid {
  background-color: $color-petite-orchid !important;
}

.pg-color-strong-orange {
  background-color: $color-strong-orange !important;
}

.pg-color-persian-red {
  background-color: $color-persian-red !important;
}

.pg-color-grey-80 {
  background-color: $color-grey-80 !important;
}

.pg-color-black {
  background-color: $color-black !important;
}

.pg-color-black-transparent {
  background-color: $color-black-transparent !important;
}
.pg-color-black-input-transparent fieldset{

  background-color: $color-black-transparent !important;
}

.pg-color-strong-orange-transparent {
  background-color: $color-strong-orange-transparent !important;
}

.pg-color-white {
  background-color: $color-white !important;
}

.pg-color-white-cream {
  background-color: $color-white-cream !important;
}

.pg-hot-gradient {
  background: linear-gradient(135deg, #54000E, #811F31, #A02838, #A7462F, #B8642A, #BF7527, #C48D27, #C4B427, #D8D225);
}

.pg-hot-gradient-reversed {
  background: linear-gradient(-45deg, #54000E, #811F31, #A02838, #A7462F, #B8642A, #BF7527, #C48D27, #C4B427, #D8D225);
}

.pg-text-field input {
  text-align: center;
}

.pg-scroll-bar {
  scrollbar-color: $color-petite-orchid $color-deep-red !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0px solid;
  -webkit-text-fill-color: white;
  background-color: transparent;
  transition: background-color 5000s ease-in-out 0s;
}

.glow {
  color: #fff;
  text-align: center;
  -webkit-animation: glow 0.5s ease-in-out infinite alternate;
  -moz-animation: glow 0.5s ease-in-out infinite alternate;
  animation: glow 0.5s ease-in-out infinite alternate;
}

@-webkit-keyframes glow {

  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #cc9933, 0 0 40px #cc9933, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #cc9999, 0 0 40px #cc9933, 0 0 50px #cc9933, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}

.v-application p {
  margin-bottom: 4px !important;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}
.ql-editor{
  min-height: 100px;
  max-height: 30vh !important;
  overflow: auto;
}
.ql-toolbar.ql-snow{
  background-color: $color-strong-orange-transparent;
}

.fullscreen-dialog-with-footer {
  max-height: calc(100vh - 56px) !important;
}
.fit-in-card-text{
  max-width: calc( 100% - 10px );
  margin: auto;
}
</style>
