<template>
  <v-select label="Couleur de peau" clearable :items="avatarSkinColors" item-text="color" v-model="selected"
            item-value="@id">
    <template v-slot:item="{item}">
      <v-chip :style="'background-color: '+item.color">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-chip>
    </template>
    <template v-slot:selection="{item}">
      <v-chip :style="'background-color: '+item.color">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-chip>
    </template>
  </v-select>
</template>

<script>
import AvatarMixin from "../../mixins/AvatarMixin";

export default {
  name: "AvatarSkinColorSelect",
  mixins: [AvatarMixin],
  props: ['value','avatarSkinColors'],
  data() {
    return {
      selected: "",
    }
  },
  watch: {
    selected: function () {
      this.$emit('input', this.selected);
    },
    value: function () {
      this.selected = this.value;
    }
  }
}
</script>

<style scoped>

</style>