<template>
  <v-row no-gutters align-content="start" justify="start" align="center" class="white mt-2 mb-2 elevation-4">
    <v-col cols="12" v-if="games.length">
      <v-row no-gutters>
        <pg-icon m-d v-if="favorite" inline>i-favori-full</pg-icon>
        <h1 class="title pl-3" :id="!favorite?type.code:'favori'">{{ !favorite ? type.name : 'Favoris' }}</h1>
      </v-row>
      <swiper ref="mySwiper" :options="swiperOptions" :key="slidesPerView" nav style="width: 100%" v-show="!loading">
        <swiper-slide v-for="game in games" v-bind:key="game.id">
          <game-tile :game="game"/>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </v-col>
    <v-col cols="12" v-if="loading">
      <v-progress-circular :size="30"
                           :width="3"
                           indeterminate
                           color="purple" class="ma-auto"/>
    </v-col>
    <v-col cols="12" align-self="end" class="text-right" v-if="games.length">
      <v-btn class="pg-color-strong-orange white--text mt-2 mb-2 mr-2" ripple
             :to="link">
        <pg-icon>i-go-next</pg-icon>
        Parcourir
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>

import GameTile from "./GameTile";
import GameMixin from "../../mixins/GameMixin";
import PgIcon from "../PGIcon";

export default {
  name: "GameType",
  components: {PgIcon, GameTile},
  mixins: [GameMixin],
  props: {
    type: Object,
    favorite: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        slidesPerView: this.$vuetify.breakpoint.smAndUp ? 6 : 3,
        loop: false,
        spaceBetween: 15,
        freeMode: true,
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
      },
      games: [],
      loading: true,
    }
  },
  computed: {
    showLoader() {
      if (this.loading) {
        return true
      } else {
        return false
      }
    },
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    slidesPerView() {
      return this.$vuetify.breakpoint.smAndUp ? 6 : 3;
    },
    link() {
      if (!this.favorite) {
        return {name: 'GameType', params: {typeCode: this.type.code}};
      } else {
        return {name: 'GameType', params: {typeCode: 'favorite'}};
      }
    },
    isMobile() {
      return !this.$vuetify.breakpoint.smAndUp;
    }
  },
  mounted() {
    let storedName = 'gtGames_' + (this.favorite ? 'favorite' : this.type.code);
    let storedGames = window.localStorage.getItem(storedName);
    storedGames = storedGames ? JSON.parse(storedGames) : null;
    let needUpdate = storedGames && storedGames.lastUpdate ? (new Date().getTime() - new Date(storedGames.lastUpdate).getTime() > 1000 * 1800) : true;
    if (!storedGames || needUpdate) {
      this.getGamesByType(this.type ? this.type.code : null, [], {
        page: 1,
        itemsPerPage: 10
      }, false, this.favorite).then(games => {
        this.games = games;
        this.games.sort((a, b) => {
          if (a.canBeStartedAgain && b.canBeStartedAgain) {
            return 0;
          }
          if (a.canBeStartedAgain) {
            return -1;
          }
          return 1;
        });
        let storedGames = {
          lastUpdate: new Date().toISOString(),
          games: this.games
        }
        window.localStorage.setItem(storedName, JSON.stringify(storedGames));
      }).finally(() => {
        this.loading = false;
        this.swiperOptions.slidesPerView = this.$vuetify.breakpoint.smAndUp ? 6 : 3;
        this.$refs.mySwiper.updateSwiper();
      });
    } else {
      this.games = storedGames.games;
      this.loading = false;
      this.swiperOptions.slidesPerView = this.$vuetify.breakpoint.smAndUp ? 6 : 3;
    }
  },
  watch: {
    'isMobile': function (before, after) {
      if (before != after) {
        this.swiperOptions.slidesPerView = this.$vuetify.breakpoint.smAndUp ? 6 : 3;
        this.$refs.mySwiper.updateSwiper();
      }
    }
  }
}
</script>

<style scoped lang="scss">
.swiper-pagination {
  position: relative;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10000;
  bottom: 0px;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullets {
  bottom: 0 !important;
}

$color-strong-orange: #cc9933;
.swiper-button-next, .swiper-button-prev {
  top: 32%;
  @media (max-width: 501px) {
    display: none;
  }
  color: $color-strong-orange;
}
</style>