import {getField, updateField} from 'vuex-map-fields';
import GameInPlayService from "../../services/game_in_play.service";

export const current_game = {
    namespaced: true,
    state: {
        currentGame: {},
        hasAnsweredInvitation: false,
        currentStageInstructions: null,
        currentStageActions: [],
        currentStagePlayerInteractionsFrom: [],
        currentPlayerInGame: null,
        defaultGameInPlayStagePlayerInteraction:
            {
                gameInPlay: "",
                playerFrom: "",
                playerTo: "",
                message: "",
                gameStagePlayerInteraction: ""
            },
        currentGameInPlayStagePlayerInteractionsFrom: [],
        currentGameInPlayStagePlayerInteractionsDestination: [],

    },
    getters: {
        getField,
        getCurrentPlayerHasAcceptedInvitation(state) {
            return state.currentPlayerInGame ? state.currentPlayerInGame.hasAcceptedInvitation : false;
        }
    },
    mutations: {
        updateField,
        SET_CURRENT_GAME_IN_PLAY_STAGE_PLAYER_INTERACTIONS_FROM(state, payload) {
            state.currentGameInPlayStagePlayerInteractionsFrom = payload
        },
        SET_CURRENT_GAME_IN_PLAY_STAGE_PLAYER_INTERACTIONS_DESTINATION(state, payload) {
            state.currentGameInPlayStagePlayerInteractionsDestination = payload
        },

        SET_CURRENT_STAGE_INSTRUCTIONS(state, instructions) {
            if (instructions.length) {
                state.currentStageInstructions = instructions[0];
            }
        },
        SET_CURRENT_STAGE_ACTIONS(state, actions) {
            if (actions.length) {
                state.currentStageActions = actions
            } else {
                state.currentStageActions = [];
            }
        },
        SET_CURRENT_STAGE_PLAYER_INTERACTIONS_FROM(state, interactions) {
            state.currentGameInPlayStagePlayerInteractionsFrom = [];
            state.currentStagePlayerInteractionsFrom = [];
            if (interactions.length) {
                state.currentStagePlayerInteractionsFrom = interactions
                state.currentStagePlayerInteractionsFrom.forEach((interactionFrom) => {
                    let interaction = {...state.defaultGameInPlayStagePlayerInteraction}
                    interaction.gameInPlay = state.currentGame['@id'];
                    interaction.playerFrom = state.currentPlayerInGame.player['@id'];
                    interaction.message = "";
                    let playerTo = state.currentGame.players.find(player => player.number == interactionFrom.playerNumberDestination);
                    interaction.playerTo = playerTo.player['@id'];
                    interaction.gameStagePlayerInteraction = interactionFrom['@id'];
                    state.currentGameInPlayStagePlayerInteractionsFrom.push(interaction);
                })
            }
        },
        SET_CURRENT_PLAYER(state, playerHydraId) {
            let currentPlayer = {};
            if (state.currentGame != undefined) {
                state.currentPlayerInGame = {};
                state.currentGame.players.some(playerInGame => {
                    if (playerInGame.player['@id'] === playerHydraId) {
                        Object.assign(currentPlayer, playerInGame);
                        return true;
                    }
                })
                Object.assign(state.currentPlayerInGame, currentPlayer);
            }
        },
        SET_CURRENT_GAME(state, payload) {
            state.currentGame = payload.currentGame;
            if (state.currentGame) {
                localStorage.setItem('gameInPlayId', payload.currentGame['@id']);
            } else {
                localStorage.setItem('gameInPlayId', null);
                state.currentPlayerInGame = null;
                state.currentStageActions = [];
                state.currentGameInPlayStagePlayerInteractionsFrom = [];
                state.currentStageInstructions = [];
                state.currentStagePlayerInteractionsFrom = [];
                state.currentGameInPlayStagePlayerInteractionsDestination = [];
            }
        }
    },
    actions: {
        async updateLiveGameInPlay({dispatch, commit, state}, payload) {
            const sound = new Audio(require('@/assets/sound/graceful.ogg'));
            const delay = (n) => new Promise(r => setTimeout(r, n));
            sound.play();
            if (state.hasAnsweredInvitation) {
                await delay(1000);
                state.hasAnsweredInvitation = false;
            }
            switch (payload.action) {
                case 'gameAbort':
                case 'winnerSelection':
                    commit('SET_CURRENT_GAME', {});
                    break;
                case 'acceptedInvitation':
                    dispatch('startGame');
                    break;
                case 'refusedInvitation':
                    commit('SET_CURRENT_GAME', {});
                    break;
                default: {
                    dispatch('startGame');
                }
            }
        },
        async startGame({commit, dispatch, rootGetters}, payload) {
            let gameInPlayId = payload ? payload.gameInPlayId : undefined;
            if (gameInPlayId === undefined) {
                gameInPlayId = localStorage.getItem('gameInPlayId');
                if (!gameInPlayId) {
                    dispatch('message/showErrorMessage', 'Erreur de chargement du jeu', {root: true});
                    throw new Error("Jeu non chargé");
                }
            }
            let playerId = rootGetters['player/getPlayerId'];
            if (!playerId) {
                dispatch('message/showErrorMessage', 'Erreur de chargement du joueur', {root: true});
                throw new Error("Joueur non chargé");
            }
            return new Promise((resolve, reject) => {
                GameInPlayService.getGameInPlay(gameInPlayId).then(response => {
                    commit('SET_CURRENT_GAME', {currentGame: response.data, playerId: playerId});
                    let playerHydraId = rootGetters['player/getPlayerHydraId'];
                    commit('SET_CURRENT_PLAYER', playerHydraId);
                    let jwtToken = response.data.jwtToken;
                    commit('mercure/SET_JWT_TOKEN', jwtToken, {root: true});
                    resolve(response.data);
                }).catch(e => {
                    dispatch('message/showErrorMessage', 'Erreur de chargement du jeu', {root: true});
                    reject(e);
                })
            })
        },
        async createGame({commit, dispatch, rootGetters}, game) {
            return new Promise((resolve, reject) => {
                let playerTeams = rootGetters['player/getPlayerTeams'][0];
                let team = '/teams/' + playerTeams.id;
                let playerHydraId = rootGetters['player/getPlayerHydraId'];
                let playerId = rootGetters['player/getPlayerId'];
                let players = [];
                let numPlayer = 1;
                players.push({player: '/players/' + playerId, number: numPlayer});
                playerTeams.players.forEach(player => {
                    if (player.id !== playerId) {
                        numPlayer++;
                        players.push({
                            player: '/players/' + player.id,
                            number: numPlayer
                        })
                    }
                })
                let gameInPlay = {
                    game: game['@id'],
                    team: team,
                    playerHost: playerHydraId,
                    players: players,
                };
                GameInPlayService.createGameInPlay(gameInPlay).then(response => {
                    commit('SET_CURRENT_GAME', {currentGame: response.data, playerId: playerId});
                    dispatch('message/showSuccessMessage', 'Jeu lancé avec succès et invitations envoyées !', {root: true});
                    commit('SET_CURRENT_PLAYER', playerHydraId);
                    resolve(response.data);
                }).catch(e => {
                    reject(e);
                    let message = 'Erreur de lancement du jeu';
                    console.error(e);
                    if (e && e.data && e.data['hydra:description']) {
                        message = e.data['hydra:description'];
                    }
                    dispatch('message/showErrorMessage', message, {root: true});
                })
            });
        },
        async stopGame({commit, state, dispatch}) {
            return new Promise((resolve, reject) => {
                GameInPlayService.deleteGameInPlay(state.currentGame['@id']).then(response => {
                    commit('SET_CURRENT_GAME', {});
                    resolve(response.data);
                }).catch(e => {
                    console.error(e);
                    dispatch('message/showErrorMessage', 'Erreur de suppression de la partie en cours !', {root: true});
                    reject(e);
                })
            })
        },
        updateGameInPlayPlayer({dispatch, commit, state}, gameInPlayPlayer) {
            state.hasAnsweredInvitation = true;
            return new Promise((resolve, reject) => {
                GameInPlayService.updateGameInPlayPlayer(gameInPlayPlayer).then(response => {
                    commit('SET_CURRENT_PLAYER', response.data);
                    resolve(response);
                }).catch(e => {
                    console.error(e)
                    dispatch('message/showErrorMessage', 'Erreur de modification de votre invitation', {root: true});
                    reject(e);
                })
            })
        },
        async getStageInstructions({commit, dispatch}, payload) {
            console.log("loading stage instructions")
            return new Promise((resolve, reject) => {
                if (!payload.currentGame || !payload.currentGame.currentStage['@id']) {
                    return resolve(null);
                }
                GameInPlayService.getGameInPlayStageInstructions(payload.currentGame, payload.playerNumber).then(response => {
                    commit('SET_CURRENT_STAGE_INSTRUCTIONS', response.data['hydra:member']);
                }).catch(e => {
                    console.error(e)
                    dispatch('message/showErrorMessage', 'Erreur de chargement des instructions de jeu', {root: true});
                    reject(e);
                })
            })
        },
        async getStagePlayerInteractionFrom({commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_CURRENT_STAGE_PLAYER_INTERACTIONS_FROM', []);
                GameInPlayService.getGameInPlayStagePlayerInteractionFrom(payload.currentGame, payload.playerNumber).then(response => {
                    commit('SET_CURRENT_STAGE_PLAYER_INTERACTIONS_FROM', response.data['hydra:member']);
                    resolve(response.data['hydra:member']);
                }).catch(e => {
                    dispatch('message/showErrorMessage', 'Erreur de chargement des intéractions de jeu', {root: true});
                    reject(e);
                })
            })
        },

        async getGameInPlayStagePlayerInteractionDestination({commit, dispatch}, payload) {
            console.log("loading stage Player Interaction Destination")
            commit('SET_CURRENT_GAME_IN_PLAY_STAGE_PLAYER_INTERACTIONS_DESTINATION', []);
            return new Promise((resolve, reject) => {
                GameInPlayService.getGameInPlayStagePlayerInteractionDestination(payload.currentGame, payload.playerNumber).then(response => {
                    commit('SET_CURRENT_GAME_IN_PLAY_STAGE_PLAYER_INTERACTIONS_DESTINATION', response.data['hydra:member']);
                    resolve(response.data['hydra:member']);
                }).catch(e => {
                    dispatch('message/showErrorMessage', 'Erreur de chargement des intéractions de jeu', {root: true});
                    reject(e);
                })
            })
        },

        async getStageActions({commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                GameInPlayService.getGameInPlayStageActions(payload.currentGame, payload.playerNumber).then(response => {
                    commit('SET_CURRENT_STAGE_ACTIONS', response.data['hydra:member']);
                    resolve(response.data['hydra:member']);
                }).catch(e => {
                    console.error(e)
                    dispatch('message/showErrorMessage', 'Erreur de chargement des instructions de jeu', {root: true});
                    reject(e);
                })
            })
        },
        async extendDuration({dispatch, state}) {
            let currentGame = state.currentGame;
            let payload = {
                gameId: currentGame['@id']
            }
            return new Promise((resolve, reject) => {
                GameInPlayService.extendDuration(payload).then(response => {
                    resolve(response)
                }).catch(e => {
                    console.error(e);
                    dispatch('message/showErrorMessage', 'Erreur d\'extension de la durée de votre partie !', {root: true});
                    reject(e);
                })
            })
        },
        async setGameInPlayWinner({commit, dispatch, state}, payload) {
            let currentGame = state.currentGame;
            let playerWinnerId = payload ? payload.player['@id'] : null;
            payload = {
                playerWinnerId: playerWinnerId,
                gameId: currentGame['@id']
            }
            return new Promise((resolve, reject) => {
                GameInPlayService.selectWinner(payload).then(response => {
                    commit('SET_CURRENT_GAME', {});
                    resolve(response);
                }).catch(e => {
                    console.error(e);
                    dispatch('message/showErrorMessage', 'Erreur de sélection du vainqueur de la partie en cours !', {root: true});
                    reject(e);
                })
            })
        },
        async saveCurrentStagePlayerInteractions({dispatch, state, commit}) {
            return new Promise((resolve, reject) => {
                if (state.currentGameInPlayStagePlayerInteractionsFrom.length > 0) {
                    try {
                        state.currentGameInPlayStagePlayerInteractionsFrom.forEach(async (gameInPlayStagePlayerInteraction, index) => {
                            await GameInPlayService.createCurrentStagePlayerInteractions(gameInPlayStagePlayerInteraction);
                            state.currentGameInPlayStagePlayerInteractionsFrom.splice(index, 1);
                        })
                    } catch (e) {
                        dispatch('message/showErrorMessage', 'Erreur d\'envoie des informations à l\'autre joueur !', {root: true});
                        reject();
                    }
                    commit('SET_CURRENT_STAGE_PLAYER_INTERACTIONS_FROM', []);
                    resolve();
                } else {
                    resolve();
                }
            })

        },
        async goToStage({dispatch, state}, stageId) {
            let currentGame = state.currentGame;
            let updatedGame = {
                '@id': currentGame['@id'],
                currentStage: stageId
            }
            return new Promise((resolve, reject) => {
                dispatch('saveCurrentStagePlayerInteractions').then(() => {
                    GameInPlayService.updateGameInPlay(updatedGame).then(response => {
                        resolve(response.data);
                    }).catch(e => {
                        console.error(e);
                        dispatch('message/showErrorMessage', 'Erreur de changement de niveau de la partie en cours !', {root: true});
                        reject(e);
                    })
                });
            })
        }
    }
}