export default {
    path: '/games',
    name: 'Games',
    component: () => import('../layouts/Game'),
    meta: {layout: "base-layout"},
    children: [
        {
            name: 'GameType',
            path: ':typeCode',
            component: () => import('../views/game/ShowType')
        },
        {
            name: 'WriteGame',
            path: 'write',
            component: () => import('../views/game/WriteGame')
        },
        {
            name: 'Community',
            path: 'community',
            component: () => import('../views/game/Community')
        },
    ]
};
