import {createHelpers} from 'vuex-map-fields';

const {mapFields} = createHelpers({
    getterType: 'navigation/getField',
    mutationType: 'navigation/updateField'
});

export default {
    computed: {
        ...mapFields(['showNavigation']),
        isLargeScreen(){
            return this.$vuetify.breakpoint.mdAndUp;
        }
    },
    created() {
        if (this.$vuetify.breakpoint.mdAndUp) {
            this.showNavigation = true;
        }
    },
    watch:
        {
        isLargeScreen : function () {
            if (this.isLargeScreen) {
                this.showNavigation = true;
            }
        }
    }
}