<template>
  <v-select :items="departements" label="Départements" item-text="name" item-value="@id" v-model="selectedDepartement"
            @input="updateValue"/>
</template>

<script>
import {createHelpers} from "vuex-map-fields";
import {mapActions} from "vuex";

const {mapFields} = createHelpers({
  getterType: 'geo/getField',
  mutationType: 'geo/updateField'
});
export default {
  name: "DepartementSelector",
  data: () => ({
    selectedDepartement: "",
  }),
  props: {
    value: {
      type: String
    }
  },
  computed: {
    ...mapFields(['departements']),
  },
  methods: {
    ...mapActions({
      getDepartements: 'geo/getDepartements'
    }),
    updateValue: function () {
      this.$emit('input', this.selectedDepartement);
    },
  },
  created() {
    this.getDepartements();
    this.selectedDepartement=this.value;
  },
  watch: {
    value: function () {
      this.selectedDepartement = this.value;
    }
  }
}
</script>

<style scoped>

</style>