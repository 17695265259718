<template>
  <div class="box jumpy-pimentos" :style="getSize"></div>
</template>

<script>
const SIZE_MAP = {
  small: '16px',
  default: '24px',
  medium: '28px',
  large: '36px',
  xLarge: '40px',
  xxLarge: '55px'
}
export default {
  name: "JumpingPimentos",
  props: {
    SM: Boolean,
    MD: Boolean,
    LG: Boolean,
    XL: Boolean,
    XXL: Boolean,
    inline: Boolean,
  },
  computed:{
    getSize() {
      let inlineStyle = this.inline?'display: table-cell; ':'';
      let size = SIZE_MAP.default;
      if (this.SM) size=SIZE_MAP.small;
      if (this.MD) size=SIZE_MAP.medium;
      if (this.LG) size=SIZE_MAP.large;
      if (this.XL) size=SIZE_MAP.xLarge;
      if (this.XXL) size=SIZE_MAP.xxLarge;
      return  inlineStyle+'height:'+size+'; width:'+size+';';
    }
  }
}
</script>

<style scoped>
.jumpy-pimentos {
  animation-name: jumpy-pimentos;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
}

@keyframes jumpy-pimentos {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, .9) translateY(0);
  }
  30% {
    transform: scale(.9, 1.1) translateY(-10px);
  }
  50% {
    transform: scale(1.05, .95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

.box {
  align-self: flex-end;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  background-image: url("~@/assets/icons/i-pimentos.svg");
  background-position: center;
  background-size: cover;
}
</style>