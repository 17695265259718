var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.games,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"loading":_vm.loading,"server-items-length":_vm.totalGames,"loading-text":"En cours de chargement"},on:{"update:items-per-page":_vm.updateItemsPerPage,"update:page":_vm.updatePage},scopedSlots:_vm._u([{key:"item.validationStatus",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":_vm.getColor(item.validationStatus),"size":"20"}})]}},{key:"item.publicationStart",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.publicationStart))+" ")]}},{key:"item.pointsReward",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.pointsReward)),_c('jumping-pimentos',{attrs:{"inline":"","s-m":""}})],1)]}},{key:"item.playedCounter",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.pointsReward)+" "),(!_vm.publicPlayer)?_c('span',[_vm._v("/ "+_vm._s(_vm.maxGameInPlayToReward))]):_vm._e()])]}},{key:"item.isPrivate",fn:function(ref){
var item = ref.item;
return [(!_vm.isSolo && item.isPrivate)?_c('v-btn',{staticClass:"pg-color-deep-red",attrs:{"dark":"","large":!_vm.$vuetify.breakpoint.mobile},on:{"click":function($event){return _vm.reStartGame(item)}}},[(_vm.gameIsInPlay(item['@id'])<0)?_c('span',[_vm._v("Rejouer")]):_c('span',[_vm._v("Continuer ")]),_c('pg-icon',{attrs:{"XL":""}},[_vm._v("i-play")]),(_vm.hasToPay(item))?_c('span',[_c('b',[_vm._v(_vm._s(item.pointsCost))])]):_vm._e()],1):_vm._e()]}},{key:"no-data",fn:function(){return [_vm._v(" Aucun jeu publié pour le moment. ")]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }