import {mapActions, mapGetters} from "vuex";
import {createHelpers} from 'vuex-map-fields';

const {mapFields} = createHelpers({
    getterType: 'player/getField',
    mutationType: 'player/updateField'
});
export default {
    computed: {
        isAuthRoute() {
            return window.location.pathname === '/auth/verification'
                || window.location.pathname === '/auth/login'
                || window.location.pathname ==='/auth/register'
                || window.location.pathname ==='/auth/recovery';
        },
        ...mapGetters('auth', {
            loggedIn: 'loggedIn',
        }),
        ...mapGetters('player', {
            playerId: 'getPlayerId',
        }),
        ...mapFields(['nickName', 'personnalDescription', 'isLoadingPlayer', 'isTutorialDone'])
    },
    methods: {
        ...mapActions('auth', {
            logoutStore: 'logout',
        }),
        ...mapActions('player', {
            getMe: "getMe"
        }),
        logout() {
            this.logoutStore();
            this.$router.push({name: 'Login'}).catch(err => {
                console.log('Avoir duplicate route :' + err)
            });
        },
        checkNeedToLogin() {
            if (!this.loggedIn && !this.isAuthRoute && this.$route.name != 'Login') {
                this.$router.push({name: 'Login'}).catch(err => {
                    console.log('Avoir duplicate route :' + err)
                });
            }
        }
    },

}