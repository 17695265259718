<template>
  <v-app v-scroll="onScroll">
    <tutorial-frame class="show-over-avatar-over" v-if="playerId"/>
    <game-starter class="show-over-avatar-over"/>
    <game-play-invite class="show-over-avatar-over"/>
    <game-write class="show-over-avatar-over"/>
    <notification class="show-over-avatar-over"/>
    <payment/>
    <v-snackbar
        v-model="showSnackBar"
        :color="snackBarColor"
        dark
        app
        width="100vw"
        class="show-over-avatar-over"
    >
      {{ snackBarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            dark
            text
            @click="showSnackBar = false"
            v-bind="attrs"
        >
          <v-icon>mdi-close</v-icon>
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
    <v-navigation-drawer :expand-on-hover="$vuetify.breakpoint.mdAndUp" app clipped dark
                         v-model="showNavigation" class="pg-hot-gradient show-over-avatar-over">
      <Menu/>
    </v-navigation-drawer>
    <v-app-bar class="pg-color-white show-over-avatar" clipped-left dense :hide-on-scroll="hideOnScroll" v-show="!isWritingGame"
               app>
      <v-row no-gutters align="center" @click="$router.push({name:'Home'})">
        PRIVATE
        <v-img
            alt="Logo Private-Games"
            class="shrink mt-1 ml-1 mr-1"
            contain
            min-width="10"
            src="~@/assets/logo-transparent.png"
            width="28"
            @click="$router.push({name:'Home'})"
        />
        GAMES
      </v-row>
      <v-spacer></v-spacer>
      <v-btn icon small :to="{name:'ProfileMe'}">
        <pg-icon mono>i-profil</pg-icon>
      </v-btn>
      <v-btn icon small @click="showNotifications=!showNotifications">
        <v-badge
            color="red"
            :content="nbNotificationToRead"
            overlap
            :dot="nbNotificationToRead<=0"
        >
          <pg-icon mono>i-notif</pg-icon>
        </v-badge>
      </v-btn>
      <v-btn icon @click="showNavigation=!showNavigation" v-show="$vuetify.breakpoint.smAndDown">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main class="mb-16 pg-color-egg-yellow">
      <checkout/>
      <v-row no-gutters align-content="center" justify="center">
        <v-container :fluid="$vuetify.breakpoint.mdAndUp" class="pa-0" style="max-width: 1446px; min-height: 100vh">
          <router-view/>
        </v-container>
      </v-row>
    </v-main>
    <footer-menu class="show-over-avatar"/>
  </v-app>
</template>

<script>
import AuthPlayerNeeded from "@/mixins/AuthPlayerNeeded";
import MessageMixin from "@/mixins/MessageMixin";
import PgIcon from "@/components/PGIcon";
import Menu from "@/components/Menu";
import NavigationMixin from "@/mixins/NavigationMixin";
import FooterMenu from "@/components/FooterMenu";
import Notification from "@/components/Notification";
import NotificationMixin from "@/mixins/NotificationMixin";
import LayoutMixin from "../mixins/LayoutMixin";
import GameStarter from "../components/game/GameStarter";
import GamePlayInvite from "../components/game/GamePlayInvite";
import GameInPlayMixin from "../mixins/GameInPlayMixin";
import MercureMixin from "../mixins/MercureMixin";
import AuthPlayerMixin from "../mixins/AuthPlayerMixin";
import GameWrite from "../components/game/GameWrite";
import TutorialFrame from "../components/tutorial/TutorialFrame";
import {mapGetters} from "vuex";
import Checkout from "../components/shop/Checkout";
import Payment from "../components/shop/Payment";


const backgroundsPic = {
  pic_sm: require("@/assets/background-pg-xl-white.png"),
  pic_lg: require("@/assets/background-pg-xl-white.png")
}

export default {
  name: "BaseLayout",
  components: {
    Payment,
    Checkout, TutorialFrame, GameWrite, GamePlayInvite, GameStarter, Notification, FooterMenu, Menu, PgIcon},
  mixins: [AuthPlayerNeeded, MessageMixin, NavigationMixin, NotificationMixin, LayoutMixin, GameInPlayMixin, MercureMixin, AuthPlayerMixin],
  data: () => ({
    drawer: true,
    scrolled: false,
  }),
  methods: {},
  created() {
    console.log('App is launched');
    this.onScroll();
    this.getMe().catch(e => {
      if (e.status === 500 || e.response.status === 500) {
        this.logout();
      }
    });
    this.getGamesInplay();
    if (!this.mercureStarted && this.playerId) {
      this.setupMercureNotification(this.playerId);
    }

  },
  computed: {
    ...mapGetters({isWritingGame:'game_write/isWritingGame'}),
    backgroundsPicture() {
      return backgroundsPic;
    },
    isIOS() {
      return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
          ].includes(navigator.platform)
          // iPad on iOS 13 detection
          || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    },
    hideOnScroll() {
      return !this.isIOS && this.$vuetify.breakpoint.smAndDown;
    }
  },
  watch: {
    'playerId': function () {
      if (!this.mercureStarted && this.playerId) {
        this.setupMercureNotification(this.playerId);
      }
    },
    'mercureStarted': function () {
      if (!this.mercureStarted && this.playerId) {
        this.setupMercureNotification(this.playerId);
      }
    }
  }

}
</script>

<style scoped>
.show-over-avatar{
  z-index: 999999 !important;
}
.show-over-avatar-over{
  z-index: 9999999 !important;
}
</style>