import axios from "axios";

export default function authHeader() {
    let token = localStorage.getItem('token');
    if (token) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        return {Authorization: 'Bearer ' + token};
    } else {
        axios.defaults.headers.common['Authorization']=''
    }
}