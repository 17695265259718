<template>
  <v-dialog v-model="showPayment"
            persistent
            width="600"
  >
    <v-row no-gutters>
      <v-card class="ma-auto" width="100%">
        <v-card-text>
          <v-btn fab elevation="8" absolute
                 color="pink"
                 class="mt-8"
                 top
                 dark
                 small
                 right
                 @click="cancelOrder">
            <pg-icon color="white" s-m>i-close</pg-icon>
          </v-btn>
          <v-row no-gutters v-if="loading">
            <v-progress-circular indeterminate class="ma-auto" color="purple" size="200"/>
          </v-row>
          <v-form id="payment-form">
            <div id="payment-element"/>
            <v-btn id="submit" class="pg-hot-gradient" type="submit" large dark
                   :disabled="payementLoading || !acceptPayementCondition"
                   width="100%" @click="pay">
              <v-progress-circular indeterminate class="mr-3" v-if="payementLoading"/>
              <span id="button-text">Payer {{ cartTotalAmount / 100 }}€</span>
            </v-btn>
            <div id="payment-message" class="hidden"></div>
            <v-row no-gutters>
              <v-simple-checkbox v-model="acceptPayementCondition"/>
              <span>J'accepte les &nbsp;</span><a @click="showCGV">conditions générales de vente</a>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-text v-if="showCGVComponent">
          <CGVContent/>
        </v-card-text>
      </v-card>
    </v-row>
  </v-dialog>
</template>

<script>
import ShopService from "../../services/shop.service";
import {loadStripe} from "@stripe/stripe-js"
import MessageMixin from "../../mixins/MessageMixin";
import ShopMixin from "../../mixins/ShopMixin";
import PgIcon from "../PGIcon";
import AuthPlayerNeeded from "../../mixins/AuthPlayerNeeded";
import CGVContent from "../cgv/CGVContent";

export default {
  name: "Payment",
  components: {CGVContent, PgIcon},
  mixins: [MessageMixin, ShopMixin, AuthPlayerNeeded],
  data() {
    return {
      loading: true,
      acceptPayementCondition: true,
      showCGVComponent: false,
      payementLoading: false,
      elementOptions: {
        appearance: {
          theme: 'stripe',
          variables: {
            colorPrimary: '#660033',
            colorBackground: '#ffffff',
            colorText: '#30313d',
            colorDanger: '#cc3333',
            fontFamily: 'Roboto, Ideal Sans, system-ui, sans-serif',
            spacingUnit: '2px',
            borderRadius: '4px',
          }
        }, // appearance options
        clientSecret: ''
      },
      confirmParams: {
        return_url: 'http://localhost:8080/success', // success url
      },
      publishableKey: process.env.VUE_APP_STRIPE_API_KEY,
      stripe: null,
      elements: null,
      payment: null
    }
  },
  computed: {
    stripeApiKey() {
      return process.env.VUE_APP_STRIPE_API_KEY
    },
    apiPGPaymentIntent() {
      return process.env.VUE_APP_API_URL + '/shop/payment/intent'
    },
    checkoutURL() {
      return process.env.VUE_APP_STRIPE_CHECKOUT;
    },
    hasRedirect() {
      return (this.cartItems.find(item => item.noRedirect) != undefined) ? 'if_required' : 'always';
    }
  },
  created() {
    this.initStripeElements();
    if (this.showPayment) {
      this.generatePaymentIntent();
    }
  },
  methods: {
    showCGV() {
      this.showCGVComponent = !this.showCGVComponent;
    },
    async generatePaymentIntent() {
      ShopService.getPaymentIntent({items: this.cartItems}).then(response => {
        let paymentIntent = response.data;
        this.currentPaymentIntentId = paymentIntent.id;
        this.elementOptions.clientSecret = paymentIntent.clientSecret;
        this.elements = this.stripe.elements(this.elementOptions);
        this.paymentElement = this.elements.create('payment');
        this.paymentElement.mount('#payment-element');
        this.loading = false;
      }).catch(error => {
        console.error(error);
      });
    },
    async pay() {
      this.payementLoading = true;
      let elements = this.elements;
      this.stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: this.checkoutURL,
        },
        redirect: this.hasRedirect
      }).then((response) => {
        const {error} = response;
        if (error) {
          if (error.type === "card_error" || error.type === "validation_error") {
            this.showErrorMessage(error.message);
          } else {
            this.showErrorMessage("Une erreur inattendue est arrivée.");
          }
        } else {
          this.showPayment = false;
        }
      }).catch(() => {
        this.showErrorMessage("Une erreur inattendue est arrivée.");
      }).finally(() => {
        this.payementLoading = false;
      });

    },
    async initStripeElements() {
      if (!this.stripe) {
        this.stripe = await loadStripe(this.stripeApiKey);
      }
      this.checkStatus();
    },
    async checkStatus() {
      const clientSecret = new URLSearchParams(window.location.search).get(
          "payment_intent_client_secret"
      );
      if (!clientSecret) {
        return;
      }
      const {paymentIntent} = await this.stripe.retrievePaymentIntent(clientSecret);
      switch (paymentIntent.status) {
        case "succeeded":
          this.showSuccessMessage("Payment succeeded!");
          break;
        case "processing":
          this.showInfoMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          this.showErrorMessage("Your payment was not successful, please try again.");
          break;
        default:
          this.showErrorMessage("Something went wrong.");
          break;
      }
    }
  },
  watch: {
    showPayment: function () {
      if (this.showPayment) {
        this.loading = true;
        this.generatePaymentIntent();
      }
    }
  }
}
</script>

<style scoped lang="css">

form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
  0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}
</style>