import {getField, updateField} from "vuex-map-fields";

export const mercure = {
    namespaced: true,
    state: {
        started: false,
        jwtToken: '',
        lastMessage: null,
        lastEventId:null
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        SET_JWT_TOKEN(state, payload) {
            state.jwtToken = payload;
        },
    }
}