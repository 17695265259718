<template>
  <v-card min-height="75vh" height="100%">
    <v-card-title>Profil public</v-card-title>
    <v-card-text>
      <v-row align-content="center" justify="center" align="center" no-gutters>
        <v-img :src="getAvatarImagePath(publicPlayer.avatarSavedImage)" v-if="isPublic" width="200" height="200" contain></v-img>
      </v-row>
      <v-row align-content="start" justify="start" align="start" no-gutters>
        <label class="subtitle-2" v-if="nickName">Pseudo</label> :{{ (isPublic && publicPlayer!=null) ? publicPlayer.nickName : nickName }}
      </v-row>
      <label class="subtitle-2">Message de présentation:</label><br>
      <v-row align-content="start" justify="start" align="start" no-gutters>
        <quill-editor class="white" v-model="personnalDescription" :options="editorOptions" v-if="!isPublic" style="min-width: 70%"/>
        <v-card v-if="isPublic && publicPlayer!=null"
                elevation="2"
                class="pg-hot-gradient"
                dark
                width="100%">
          <v-card-text v-html="publicPlayer.personnalDescription"></v-card-text>
        </v-card>
      </v-row>

    </v-card-text>
    <v-card-actions v-if="!isPublic">
      <v-spacer/>
      <v-btn class="pg-hot-gradient" dark @click="updatePublicProfile">Modifier</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AuthPlayerNeeded from "../../mixins/AuthPlayerNeeded";
import QuillEditorMixin from "../../mixins/QuillEditorMixin";
import ProfileMixins from "../../mixins/ProfileMixins";
import PlayerService from "../../services/player.service";
import MessageMixin from "../../mixins/MessageMixin";
import PlayerMixin from "../../mixins/PlayerMixin";

export default {
  name: "ProfilePublic",
  props: ['publicPlayer'],
  mixins: [AuthPlayerNeeded, QuillEditorMixin, ProfileMixins, MessageMixin, PlayerMixin],
  methods: {
    updatePublicProfile() {
      let player = {
        '@id': '/players/' + this.playerId,
        personnalDescription: this.personnalDescription
      }
      PlayerService.setUserPublicProfile(player).then(() => {
            this.showSuccessMessage('Profil public mis à jour avec succès!');
            this.getMe();
          }
      ).catch(e => {
        console.error(e);
        this.showErrorMessage('Une erreur est apparue lors de la modification de votre profil public');
      })
    }
  }
}
</script>

<style scoped>

</style>