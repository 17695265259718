export default {
    debug: 'warn',
    modules: {
        toolbar: {
            container: [
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                [{color: []}, {background: []}], // dropdown with defaults from theme
            ],

        },

    },
    placeholder: 'Votre texte ici ...',
    theme: 'snow'
}