<template>
  <v-avatar :height="size+'px'" :width="size+'px'" class="avatar">
    <img :width="size" :height="size"
         :src="elementSelected.element?getImagePath(elementSelected.element.img.contentUrl):''"
         :style="'z-index:'+elementSelected.zIndex"
         v-for="(elementSelected,index) in value" :key="index"
         v-show="elementSelected.element"/>
  </v-avatar>
</template>

<script>
import AvatarMixin from "../../mixins/AvatarMixin";

export default {
  name: "PGAvatar",
  props:{
    value :Array,
    size : {
      type: Number,
      Default:200
    }
  },
  mixins:[AvatarMixin]
}
</script>

<style scoped>
.avatar {
  position: relative;
  margin: auto;
}

.avatar img {
  position: absolute;
  top: 0px;
  left: 0px;
}
</style>