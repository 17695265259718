import {getField, updateField} from 'vuex-map-fields';
import NotificationService from "../../services/notification.service";

const token = localStorage.getItem('hasAcceptedPushNotificationOnDevice');
export const notification = {
    namespaced: true,
    state: {
        notificationsActionTypes: [],
        showNotifications: false,
        notifications: [],
        totalNotifications: 0,
        pushSubscription: null,
        hasAcceptedPushNotificationOnDevice: token ? token : -1,
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        SET_NOTIFICATIONS(state, payload) {
            state.notifications = payload.notifications;
            state.totalNotifications = payload.totalNotifications;
        },
        SET_NOTIFICATION_ACTION_TYPES(state, payload) {
            state.notificationsActionTypes = payload;
        },
        MARK_NOTIFICATION_READ(state, payload) {
            let index = state.notifications.findIndex(notification => notification['@id'] === payload);
            state.notifications.splice(index, 1);
            state.totalNotifications--;
        },
        ADD_LIVE_NOTIFICATION(state, payload) {
            if (payload['@id'] === undefined) {
                payload['@id'] = '/player_notifications/' + payload.id
            }
            state.notifications.unshift(payload);
            state.totalNotifications++;
        }
    },
    actions: {
        async getNotifications({rootGetters, commit}) {
            let playerId = rootGetters['player/getPlayerId'];
            return new Promise((resolve, reject) => {
                NotificationService.getUserNotifications(playerId).then(response => {
                        commit('SET_NOTIFICATIONS', {
                            notifications: response.data['hydra:member'],
                            totalNotifications: response.data['hydra:totalItems']
                        });
                    }
                ).catch(e => {
                    return reject(e);
                });
            })
        },
        async getNotificationActionTypes({commit}) {
            return new Promise((resolve, reject) => {
                NotificationService.getNotificationActionTypes().then(response => {
                    commit('SET_NOTIFICATION_ACTION_TYPES', response.data['hydra:member']);
                }).catch(e => {
                    return reject(e);
                })
            })
        },
        async markNotificationRead({commit, state, dispatch}, notificationId) {
            return new Promise((resolve, reject) => {
                NotificationService.markNotificationRead(notificationId).then(() => {
                    if (state.totalNotifications > 10 && state.notifications.length < 10) {
                        dispatch('getNotifications');
                    } else {
                        commit('MARK_NOTIFICATION_READ', notificationId);
                    }
                    return resolve(true);
                }).catch(e => {
                    return reject(e);
                })
            })
        },
        async deleteAllUserNotifications({state, dispatch}) {
            return new Promise((resolve, reject) => {
                NotificationService.deleteAllUserNotifications().then(() => {
                    state.totalNotifications = 0;
                    state.notifications = [];
                    return resolve(true);
                }).catch(e => {
                    dispatch('message/showErrorMessage', 'Impossible de supprimer les notifications pour le moment !', {root: true});
                    return reject(e);
                })
            })
        },
        async addLiveNotification({commit, dispatch, state}, notification) {
            commit('ADD_LIVE_NOTIFICATION', notification);
            const sound = new Audio(require('@/assets/sound/unconvinced.ogg'));
            sound.play();
            if (notification.actionType) {
                let actionTypeForNotif = state.notificationsActionTypes.find(actionType => actionType['@id'] === notification.actionType);
                if (actionTypeForNotif != undefined) {
                    if (actionTypeForNotif.code === 'ORDER_FINISHED') {
                        dispatch('player/getMe', null, {root: true});
                    }
                    if (actionTypeForNotif.code === 'GAME_INVITE') {
                        dispatch('game_in_play/getGamesInPlay', null, {root: true});
                    }
                }
            }
            dispatch('message/showSuccessMessage', 'Nouvelle notification reçue !', {root: true});
        },

        async unsubscribePushNofication() {
            const register = await navigator.serviceWorker.getRegistration('/push');
            register.pushManager.getSubscription().then(pushSubscription => {
                if (pushSubscription) {
                    pushSubscription.unsubscribe();
                }
            })
        },

        async subscribePushNotification({state}, isDiscreet = false) {
            if (!state.hasAcceptedPushNotificationOnDevice) {
                return;
            }
            const register = await navigator.serviceWorker.getRegistration('/push');
            //register push
            console.log('Registering push...')
            register.pushManager.getSubscription().then(pushSubscription => {
                if (!pushSubscription) {
                    //the user was never subscribed
                    NotificationService.subscribe(register, isDiscreet).then(newSubscription => {
                        pushSubscription = newSubscription;
                    }).catch(() => {
                        "erreur de nouvelle subscription"
                    });
                }
            })
        }
    }
}