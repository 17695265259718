<template>
  <v-dialog transition="dialog-bottom-transition" v-model="showGameToWrite" v-if="showGameToWrite" fullscreen persistent
            hide-overlay scrollable content-class="fullscreen-dialog-with-footer">
    <v-card class="ma-auto fill-height" min-height="90vh">
      <v-toolbar
          dark
          class="pg-hot-gradient"
      >
        <v-btn
            icon
            dark
            @click="exit"
        >
          <pg-icon>i-close-white</pg-icon>
        </v-btn>
        <v-toolbar-title>Créez votre propre défi</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-0 ma-0">
        <v-row no-gutters align="center" justify="center">
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              :style="$vuetify.breakpoint.smAndUp?'min-width:70%;max-width:80vw;':''"
          >
            <v-text-field
                v-model="titreDefi"
                :counter="200"
                :rules="titleRules"
                label="Titre du défi"
                required
                light
            ></v-text-field>
            <game-filter v-model="selectedGategory">Sélectionner les catégories</game-filter>
            <v-spacer/>
            <div class="caption text-right"><span :class="nbSelectedCategory<=0?'red--text':''">{{
                nbSelectedCategory
              }} sélectionnée(s)</span>
            </div>
            <v-divider class="mt-2 mb-2"/>
            <label class="subtitle-2">Écrivez ici votre propre jeu</label>
            <quill-editor class="white" v-model="texteDefi" :options="editorOptions"/>
            <div class="caption text-right">
            <span :class="textDefiSize<=0?'red--text text-right':'text-right'">({{
                textDefiSize
              }}) sur {{ minimumCharForGameText }} minimum</span></div>
            <v-row no-gutters class="pl-2 pr-2 mt-3" align="center" justify="center">
              <v-col>
                <game-duration-slider v-model="duration"/>
              </v-col>
            </v-row>
            <v-divider class="mt-2 mb-2"/>
            <v-row no-gutters justify="center" align="center" align-content="center">
              <v-col v-if="you" align-self="center" class="text-center">
                <v-avatar class="ml-1" size="100">
                  <v-img :src="youAvatarImagePath" contain/>
                </v-avatar>
                <v-switch
                    v-model="sendToPlayer"
                    :label="'Envoyer à '+you.nickName"
                    class="text-center"
                    dense
                />
              </v-col>
              <v-col class="text-center" align-self="center">
                <v-avatar size="100">
                  <pg-icon x-x-l>i-community</pg-icon>
                </v-avatar>
                <v-switch
                    v-model="sendToCommunity"
                    label="Envoyer à la communauté"
                    dense
                />
              </v-col>
            </v-row>
            <v-row no-gutters align="center" justify="start">
              <v-col cols="3">
                <v-select :items="listPimentosRewardPoint" v-model="nbPimentosReward"
                          v-if="sendToPlayer||sendToCommunity">
                  <template v-slot:item="{item}">
                    <pg-icon class="mr-5">i-pimentos</pg-icon>
                    {{ item }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="3"><span v-if="sendToPlayer||sendToCommunity"><jumping-pimentos inline/> seront attribués au vainqueur !</span>
              </v-col>
              <v-col cols="6" v-if="sendToCommunity" class="caption">
                ( <span class="mr-1">{{ nbPimentosCost }}</span>
                <span><jumping-pimentos inline s-m/> valeur payée par un membre de la communauté.</span> )
              </v-col>
            </v-row>
            <v-divider class="mt-2 mb-2"/>
          </v-form>
        </v-row>
      </v-card-text>
      <v-card-actions class="mb-8">
        <v-btn :class="(valid && receiverSelected)?'pg-hot-gradient ma-auto':'ma-auto'" large max-width="200px"
               width="100%" :disabled="!valid || !receiverSelected"
               @click="validate">
          <pg-icon x-x-l>i-play</pg-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PgIcon from "../PGIcon";
import QuillEditorMixin from "../../mixins/QuillEditorMixin";
import GameFilter from "./GameFilter";
import JumpingPimentos from "../JumpingPimentos";
import PlayerMixin from "../../mixins/PlayerMixin";
import {createHelpers} from "vuex-map-fields";
import {mapActions} from "vuex";
import GameMixin from "../../mixins/GameMixin";
import GameInPlayMixin from "../../mixins/GameInPlayMixin";
import MessageMixin from "../../mixins/MessageMixin";
import PopUpMixin from "../../mixins/PopUpMixin";
import GameDurationSlider from "./GameDurationSlider";

const {mapFields} = createHelpers({
  getterType: 'game_write/getField',
  mutationType: 'game_write/updateField'
});
const POINTS_REWARD_RATIO = process.env.VUE_APP_POINTS_REWARD_RATIO;
const POINTS_COST_MIN_VALUE = process.env.VUE_APP_POINTS_COST_MIN_VALUE;
const MINIMUM_CHAR_FOR_GAME_TEXT = 10;
export default {
  name: "GameWrite",
  components: {GameDurationSlider, JumpingPimentos, GameFilter, PgIcon},
  mixins: [QuillEditorMixin, PlayerMixin, GameMixin, GameInPlayMixin, MessageMixin, PopUpMixin],
  data: () => {
    return {
      valid: true,
      titleRules: [
        v => !!v || 'Le titre est obligatoire',
        v => (v && v.length <= 200) || 'Le titre ne peut pas dépasser 200 caractères',
      ],
      minimumCharForGameText: MINIMUM_CHAR_FOR_GAME_TEXT,
    }
  },
  computed: {
    ...mapFields(['showGameToWrite', 'sendToPlayer', 'sendToCommunity', 'nbPimentosCost', 'nbPimentosReward', 'texteDefi', 'titreDefi', 'selectedGategory', 'duration']),
    nbSelectedCategory() {
      return this.selectedGategory.flat().length;
    },
    hasChanges() {
      return this.titreDefi.length || this.textDefiSize;
    },
    textDefiSize() {
      return this.texteDefi.replace(/(<([^>]+)>)/gi, "").length;
    },
    listPimentosRewardPoint() {
      return [10, 20, 30, 40, 50];
    },
    receiverSelected() {
      return this.sendToCommunity || this.sendToPlayer;
    }
  },
  methods: {
    ...mapActions({
      sendGame: "game_write/sendGame",
    }),
    exit() {
      if (this.hasChanges) {
        this.showPopUp({
          title: "Quitter ?",
          message: '"Êtes vous sûr de vouloir quitter cette page? Vos données seront perdues !',
          buttonTitle: 'Oui',
          showCancel: true,
          callback: () => {
            this.$store.commit("game_write/RESET_GAME_WRITE");
          }
        })
      } else {
        this.$store.commit("game_write/RESET_GAME_WRITE");
      }

    },
    validate() {
      if (this.$refs.form.validate()) {
        if (!this.nbSelectedCategory) {
          this.valid = false;
          this.showErrorMessage('Vous n\'avez pas sélectionné de catégorie.');
          return;
        }
        if (this.textDefiSize < 10) {
          this.valid = false;
          this.showErrorMessage('Le texte de votre défi doit faire au moins 10 caractères.');
          return;
        }
        let gameCategories = [];
        let selectedCategories = this.selectedGategory.flat();
        this.gameFilters.forEach(gameFilter => {
          gameFilter.gameCategoryElements.forEach(categoryElement => {
            let findCategorySelected = selectedCategories.find(category => category === categoryElement.id);
            if (findCategorySelected != undefined) {
              gameCategories.push(categoryElement['@id']);
            }
          })
        })
        let payload = {
          author: this.playerHydraId,
          gameType: this.defiGameType['@id'],
          gameCategories: gameCategories
        }
        if (this.sendToPlayer && (
            this.nbGamesForToday >= this.maxGamesStartedByDay
            || this.nbGamesInPlay >= this.maxGamesInPlay)
        ) {
          this.askForGameCreditsCoin(payload);
        } else {
          this.sendGame(payload).then(game => {
            if (this.sendToPlayer) {
              this.startGameAndGoToPlay(game, false);
            }
            this.$store.commit("game_write/RESET_GAME_WRITE");
          })
        }
      } else {
        this.showErrorMessage('Le formulaire n\'est pas complet, vérifiez les informations en rouge');
      }
    },
    askForGameCreditsCoin(payload) {
      let titleText = "Vous avez dépasser le nombre de parties autorisées : " + this.maxGamesInPlay + ".";
      if (this.nbGamesForToday >= process.env.VUE_APP_MAX_GAMES_STARTED_BY_DAY) {
        titleText = "Vous avez dépassé le nombre de lancements de parties gratuites pour ces dernières 24 H."
      }
      if (this.gameCreditsCoins > 0) {
        this.showPopUp({
          title: titleText,
          message: 'Lancer une partie supplémentaire vous coutera un jeton.',
          buttonTitle: 'Payer un jeton ?',
          buttonCancel: 'Annuler',
          gameCreditsCoinsNeededToPlay: -1,
          persistent: true,
          callback: () => {
            this.sendGame(payload).then(game => {
              if (this.sendToPlayer) {
                this.startGameAndGoToPlay(game, false);
              }
              this.$store.commit("game_write/RESET_GAME_WRITE");
            })
          },
          callbackCancel: () => {}
        })
      } else {
        this.showPopUp({
          title: titleText,
          message: 'Vous n\'avez malheureusement pas suffisamment de jetons. Voulez-vous acheter un jeton pour lancer le jeu ?',
          buttonTitle: 'Payer €',
          buttonCancel: 'Annuler',
          persistent: true,
          gameCreditsCoinsNeededToPlay: 1,
          awardPointsNeededToPlay: this.awardPointsNeededToPlay,
          callback: () => {
            this.sendGame(payload).then(game => {
              if (this.sendToPlayer) {
                this.startGameAndGoToPlay(game, false);
              }
              this.$store.commit("game_write/RESET_GAME_WRITE");
            })
          },
          callbackCancel: () => {
            this.showGameToStart = false;
            this.gameToStart = false;
          }
        })
      }
    },
  },
  watch: {
    'nbSelectedCategory': function (before, after) {
      if (before === 0) {
        if (!this.valid && after > 0) {
          this.valid = true;
        }
      }
    },
    'textDefiSize': function (before, after) {
      if (before <= MINIMUM_CHAR_FOR_GAME_TEXT) {
        if (!this.valid && after >= MINIMUM_CHAR_FOR_GAME_TEXT) {
          this.valid = true;
        }
      }
    },
    'nbPimentosReward': function (before, after) {
      if (POINTS_COST_MIN_VALUE > this.nbPimentosCost) {
        this.nbPimentosCost = POINTS_COST_MIN_VALUE
      }
      if (before != after) {
        this.nbPimentosCost = this.nbPimentosReward / POINTS_REWARD_RATIO;
      }
    },
    'sendToCommunity': function () {
      if (this.sendToCommunity) {
        this.nbPimentosCost = this.nbPimentosReward / POINTS_REWARD_RATIO;
      }
    },
  }
}
</script>

<style>
</style>