<template>
  <v-card>
    <v-card-title>Sécurité</v-card-title>
    <v-card-text>
      <v-form
          ref="form"
          v-model="valid"
      >
        <v-text-field
            v-model="currentPassword"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min,badActualPassword]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Votre mot de passe actuel"
            hint="Au moins 8 caractères"
            counter
            @click:append="show1 = !show1"
            class="pg-text-field"
            outlined
            required
            @keydown="currentPasswordWrong=false"
        ></v-text-field>
        <v-text-field
            v-model="plainPassword"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min, passwordConfirmationRule]"
            :type="show2 ? 'text' : 'password'"
            name="input-10-2"
            label="Votre nouveau mot de passe"
            hint="Au moins 8 caractères"
            counter
            @click:append="show2 = !show2"
            class="pg-text-field"
            outlined
            required
        ></v-text-field>
        <v-text-field
            v-model="plainPasswordConfirm"
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min, passwordConfirmationRule]"
            :type="show3 ? 'text' : 'password'"
            name="input-10-3"
            label="Confirmation de votre nouveau mot de passe"
            hint="Au moins 8 caractères"
            counter
            @click:append="show3 = !show3"
            class="pg-text-field"
            outlined
            required
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4 pg-hot-gradient"
          @click="validate"
      >
        Mettre à jour
      </v-btn>
      <v-btn
          class="mr-4 pg-color-dark-moderate-red"
          @click="reset"
          dark
      >
        Effacer
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

import AuthPlayerNeeded from "../../mixins/AuthPlayerNeeded";
import PlayerService from "../../services/player.service";
import MessageMixin from "../../mixins/MessageMixin";

export default {
  name: "ProfilePassword",
  mixins: [AuthPlayerNeeded, MessageMixin],
  data: () => ({
        valid: true,
        show1: false,
        show2: false,
        show3: false,
        currentPassword: "",
        currentPasswordWrong: false,
        plainPassword: "",
        plainPasswordConfirm: "",
        rules: {
          required: value => !!value || 'Requis.',
          min: v => v.length >= 8 || 'Au moins 8 caractères',
        },
      }
  ),
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.currentPasswordWrong = false;
        this.updatePassword().then(() => {
          this.showSuccessMessage('Mot de passe mis à jour avec succès!');
          this.$refs.form.reset();
        }).catch((e) => {
          console.error(e)
          if (e && e.status === 404) {
            this.setBadPassWordError();
            this.showErrorMessage('Votre mot de passe actuel n\'est pas le bon');
          } else {
            this.showErrorMessage('Une erreur est apparue lors de la modification de votre mot de passe');
          }
        })
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    setBadPassWordError() {
      this.currentPasswordWrong = true;
      this.valid = false;
      this.$refs.form.validate()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    async updatePassword() {
      let passwords = {
        currentPassword: this.currentPassword,
        newPassword: {
          first: this.plainPassword,
          second: this.plainPasswordConfirm
        }
      }
      return new Promise((resolve, reject) => {
        PlayerService.setUserPassword(passwords).then((response) => {
          resolve(response);
        }).catch(e => {
          reject(e);
        })
      })
    }
  },
  computed: {
    passwordConfirmationRule() {
      if (!this.plainPassword.length || !this.plainPasswordConfirm.length) return true;
      if (this.plainPasswordConfirm === this.plainPassword) return true;
      return 'Les mots de passes doivent être identiques'
    },
    badActualPassword() {
      return () => (!this.currentPasswordWrong) || 'Vous devez rentrer votre mot de pass actuel'
    }
  }
}
</script>

<style scoped>

</style>