import APIService from "./api.service";


class GeoService {
    async getDepartements() {
        let url = '/departements?pagination=false';
        return APIService.get(url);
    }
}

export default new GeoService();