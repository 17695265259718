<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndUp" style="min-height:90vh" class="pa-0">
    <v-card min-height="80vh" class="mb-16">
      <v-toolbar dark class="pg-color-dark-moderate-red"
      >
        <v-toolbar-title>Profil utilisateur : {{
            (isPublic && publicPlayer != null) ? publicPlayer.nickName : nickName
          }}
        </v-toolbar-title>
      </v-toolbar>

      <v-tabs :vertical="$vuetify.breakpoint.smAndUp" class="left" v-model="tab" center-active show-arrows
              :height="$vuetify.breakpoint.mobile ? '60px':'100%'">
        <v-tab href="#profile" v-if="!isPublic">
          <pg-icon>i-accessory-black</pg-icon>
          Privé
        </v-tab>
        <v-tab href="#avatar" v-if="!isPublic">
          <pg-icon>i-avatar-admin</pg-icon>
          Avatar
        </v-tab>
        <v-tab href="#public">
          <pg-icon>i-profil-black</pg-icon>
          Public
        </v-tab>
        <v-tab href="#publications">
          <pg-icon>i-wrote</pg-icon>
          Publications
        </v-tab>
        <v-tab href="#couple" v-if="!isPublic">
          <pg-icon>i-couple</pg-icon>
          Mon couple
        </v-tab>
        <v-tab href="#badge">
          <pg-icon>i-scores</pg-icon>
          Badges et trophées
        </v-tab>
        <v-tab href="#playersFollowed">
          <pg-icon>i-community-gold</pg-icon>
          Joueurs suivis
        </v-tab>
        <v-tab href="#payments">
          <pg-icon>i-shop</pg-icon>
          Historique achats
        </v-tab>
        <v-tab-item value="profile" v-if="!isPublic">
          <profile-password/>
          <profile-form/>
        </v-tab-item>
        <v-tab-item value="avatar" v-if="!isPublic">
          <profile-avatar/>
        </v-tab-item>
        <v-tab-item value="public">
          <profile-public :public-player="publicPlayer"/>
        </v-tab-item>
        <v-tab-item value="publications">
          <ProfilePublication :public-player="publicPlayer"/>
        </v-tab-item>
        <v-tab-item value="couple" v-if="!isPublic">
          <profile-couple/>
        </v-tab-item>
        <v-tab-item value="badge">
          <profile-badge-and-trophy/>
        </v-tab-item>
        <v-tab-item value="playersFollowed">
          <coming-soon/>
        </v-tab-item>
        <v-tab-item value="payments">
          <ProfilePayment/>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import ProfileForm from "../components/profile/ProfileForm";
import PgIcon from "../components/PGIcon";
import ProfileBadgeAndTrophy from "../components/profile/ProfileBadgeAndTrophy";
import ProfileCouple from "../components/profile/ProfileCouple";
import ProfilePublic from "../components/profile/ProfilePublic";
import AuthPlayerNeeded from "../mixins/AuthPlayerNeeded";
import ProfileMixins from "../mixins/ProfileMixins";
import PlayerService from "../services/player.service";
import MessageMixin from "../mixins/MessageMixin";
import ComingSoon from "../components/ComingSoon";
import ProfilePassword from "../components/profile/ProfilePassword";
import ProfilePublication from "../components/profile/ProfilePublication";
import ProfileAvatar from "../components/profile/ProfileAvatar";
import ProfilePayment from "../components/profile/ProfilePayment";

export default {
  name: "Profile",
  data: () => ({
    tab: null,
    publicPlayer: null,
  }),
  mixins: [AuthPlayerNeeded, ProfileMixins, MessageMixin],
  components: {
    ProfilePayment,
    ProfileAvatar,
    ProfilePublication,
    ProfilePassword, ComingSoon, ProfilePublic, ProfileCouple, ProfileBadgeAndTrophy, PgIcon, ProfileForm
  },
  created() {
    let idPublicPlayer = this.$route.params.player;
    if (idPublicPlayer) {
      this.loadPublicProfile(idPublicPlayer);
    }
  },
  methods: {
    loadPublicProfile(idPlayer) {
      PlayerService.getPlayer('/players/' + idPlayer).then(
          response => {
            this.publicPlayer = response.data;
          }
      ).catch(e => {
        console.error(e);
        this.showErrorMessage('Erreur au chargement du profil public');
      });
    },
    checkTabFromHash() {
      if (this.$route.hash) {
        this.tab = this.$route.hash.slice(1);
      }
    }
  },
  watch: {
    $route() {
      this.publicPlayer = this.$route.params.player;
      this.checkTabFromHash();
    }
  },
  mounted() {
    this.checkTabFromHash();
    if (!this.id) {
      this.getMe();
    }
  }
}
</script>

<style scoped>

</style>