import AuthService from '../../services/auth/auth.service';


const token = localStorage.getItem('token');

const initialState = token
    ? {status: {loggedIn: true}, token: token}
    : {status: {loggedIn: false}, token: null};

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        async login({commit, dispatch}, token) {
            return new Promise((resolve, reject) => {
                AuthService.login(token).then(
                    token => {
                        commit('loginSuccess', token);
                        dispatch('player/getMe', null, {root: true});
                        resolve(token);
                    }).catch(
                    error => {
                        commit('loginFailure');
                        reject(error);
                    });
            })

        },
        logout({commit}) {
            AuthService.logout();
            commit('logout');
        },
        async register({commit}, payload) {
            return new Promise((resolve, reject) => {
                AuthService.register(payload).then(
                    response => {
                        commit('registerSuccess');
                        resolve(response.data);
                    }).catch(
                    error => {
                        commit('registerFailure');
                        console.error(error)
                        reject(error);
                    });
            });
        },
        async recoveryAsk({commit}, payload) {
            return new Promise((resolve, reject) => {
                AuthService.recoveryAsk(payload).then(
                    response => {
                        commit('registerSuccess');
                        resolve(response.data);
                    }).catch(
                    error => {
                        commit('registerFailure');
                        console.error(error)
                        reject(error);
                    });
            });
        },
        async passwordRecovery({commit}, payload) {
            return new Promise((resolve, reject) => {
                AuthService.recoveryAsk(payload).then(
                    response => {
                        commit('registerSuccess');
                        resolve(response.data);
                    }).catch(
                    error => {
                        commit('registerFailure');
                        console.error(error)
                        reject(error);
                    });
            });
        },
        async verify({commit}, payload) {
            return new Promise((resolve, reject) => {
                AuthService.verification(payload).then(
                    response => {
                        commit('registerSuccess');
                        resolve(response.data);
                    }).catch(
                    error => {
                        console.error(error)
                        reject(error);
                    });
            });
        }
    },
    mutations: {
        loginSuccess(state, payload) {
            state.status.loggedIn = true;
            state.token = payload.token;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.token = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.token = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        }
    },
    getters: {
        loggedIn: state => {
            return state.status.loggedIn;
        },
    }
};