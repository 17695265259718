import APIService from "./api.service";
import moment from "moment";

class GameService {
    async getGameFilters() {
        return APIService.get('/game_categories');
    }

    async getGameTypes() {
        return APIService.get('/game_types?isListed=true');
    }

    async addGameFavorite(game) {
        return APIService.put(game['@id'] + '/favorites', {id: game.id}).finally(() => {
            window.localStorage.removeItem('gtGames_favorite');
        });
    }

    async removeGameFavorite(game) {
        return APIService.delete(game['@id'] + '/favorites').finally(() => {
            window.localStorage.removeItem('gtGames_favorite');
        });
    }

    async getGames(gameTypeCode, gameFiters = [], authorId = null, pagination = {}, isValidated = false, isCommunity = false, isFavorite = false) {
        let url = '/games?';
        if (isFavorite) {
            url = '/games/favorites?'
        }
        if (gameTypeCode) {
            url += 'gameType.code=' + gameTypeCode;
        }
        gameFiters.forEach(filtersArray => {
            filtersArray.forEach(filterId => {
                url += '&categoryElements.id=' + filterId;
            })
        })
        if (!authorId) {
            url += '&exists[author]=';
            url += !isCommunity ? 'false' : 'true';
            url += '&publicationStart%5Bbefore%5D=' + moment().format('Y-M-D');
            url += '&publicationEnd%5Bafter%5D=' + moment().format('Y-M-D');
        } else {
            url += '&author.id=' + authorId;
        }
        if (isValidated) {
            url += '&exists[validatedBy]=true'
        }

        let page = pagination.page != undefined ? pagination.page : 1;
        let itemsPerPage = pagination.itemsPerPage != undefined ? pagination.itemsPerPage : 20;
        url += '&page=' + page;
        if (itemsPerPage > 0) {
            url += '&itemsPerPage=' + itemsPerPage;
        }
        url += '&order%5Bid%5D=desc'
        return await APIService.get(url);
    }

    async sendGame(game) {
        let url = '/games'
        return await APIService.post(url, game);
    }
}

export default new GameService();