<template>
  <v-card style="min-height: 80vh">
    <v-card-title>Mon partenaire</v-card-title>
    <v-card-text class="text-center">
      <div v-if="you">
        <v-row align-content="center" justify="center" align="center" no-gutters>
          <v-img :src="youAvatarImagePath" width="128px" max-width="128px" contain/>
        </v-row>
        <span>{{ you.nickName }}</span>
      </div>
      <div v-else>
        <couple-received-invitation/>
        <couple-sended-invitation/>
        <couple-invitation/>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="error" dark v-if="you" @click="confirmDelete"><pg-icon s-m color="white">i-close</pg-icon>Quitter mon partenaire</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import PlayerMixin from "../../mixins/PlayerMixin";
import CoupleInvitation from "./couple/CoupleInvitation";
import CoupleReceivedInvitation from "./couple/CoupleReceivedInvitation";
import CoupleSendedInvitation from "./couple/CoupleSendedInvitation";
import PopUpMixin from "../../mixins/PopUpMixin";
import {mapActions} from "vuex";
import PgIcon from "../PGIcon";

export default {
  name: "ProfileCouple",
  components: {PgIcon, CoupleSendedInvitation, CoupleReceivedInvitation, CoupleInvitation},
  mixins: [PlayerMixin, PopUpMixin],
  methods: {
    ...mapActions({
      removeCouple: "player/removeCouple",
    }),
    confirmDelete() {
      this.showPopUp({
        title: 'Effacer le lien',
        message: "Voulez-vous vraiment quitter "+this.you.nickName+" ? Vous ne pourrez plus jouer ensemble ?",
        buttonTitle: 'Confirmer',
        callback: function () {
          this.removeCouple(this.couple);
        }.bind(this)
      })
    },
  }
}
</script>

<style scoped>

</style>