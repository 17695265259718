import {mapActions} from "vuex";
import {createHelpers} from "vuex-map-fields";

const {mapFields} = createHelpers({
    getterType: 'game_contest/getField',
    mutationType: 'game_contest/updateField'
});

export default {
    computed: {
        ...mapFields(['currentGameContest']),
        currentImage() {
            if (this.currentGameContest && this.currentGameContest.image && this.currentGameContest.image.contentUrl) {
                return process.env.VUE_APP_API_URL+this.currentGameContest.image.contentUrl
            }
            return require('@/assets/site-en-construction.png');
        }

    },
    methods: {
        ...mapActions({
            getCurrentGameContest: "game_contest/getCurrentGameContest",
            addGameContestParticipation : "game_contest/addGameContestParticipation"
        })
    }
}