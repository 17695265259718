import {getField, updateField} from 'vuex-map-fields';

export const message = {
    namespaced: true,
    state: {
        snackBarMessage: "",
        showSnackBar: false,
        snackBarTimeOut: 6000,
        snackBarColor: "red darken-4"
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,

        SHOW_MESSAGE(state, payload) {
            state.snackBarColor = payload.color;
            state.snackBarMessage = payload.message
            state.showSnackBar = true;
        }

    },
    actions: {
        showInfoMessage(store, message) {
            let payload =
                {
                    color: 'primary',
                    message: message
                };
            store.commit('SHOW_MESSAGE', payload)
        },
        showErrorMessage(store, message) {
            let payload =
                {
                    color: 'red darken-4',
                    message: message
                };
            store.commit('SHOW_MESSAGE', payload)
        },
        showSuccessMessage(store, message) {
            let payload =
                {
                    color: 'green darken-4',
                    message: message
                };
            store.commit('SHOW_MESSAGE', payload)
        },
    }
}