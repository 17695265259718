<template>
  <v-card elevation="0" @click="gameToStart=game">
    <v-card-title class="pa-1 text-center">
      <v-img :src="getGameImageUrl(game)"

             gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
             :width="imgSize"
             :height="imgSize"
             class="justify-center align-center align-self-center">
      </v-img>
    </v-card-title>
    <v-card-text class="font-weight-bold text-center pa-1 fit-in-card-text" >
      {{game.title}}
    </v-card-text>
  </v-card>
</template>

<script>
import GameInPlayMixin from "../../mixins/GameInPlayMixin";
import GameMixin from "../../mixins/GameMixin";

export default {
  name: "GameInPlayTile",
  components: {},
  props: {
    gameInPlay: {Object},
    noText: {Boolean, default: false}
  },
  mixins: [GameInPlayMixin,GameMixin],
  computed:{
    imgSize(){
      return this.$vuetify.breakpoint.mobile?'75px':'125px';
    },
    game(){
      return this.gameInPlay?this.gameInPlay.game:{};
    }
  }
}
</script>

<style scoped>
.not-more-than-3-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-height: 1.5em; /* fallback */
  max-height: 4.5em; /* fallback */
  font-size: 0.8em;
}
.not-more-than-3-lines p {
  margin-bottom: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>