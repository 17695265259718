import {getField, updateField} from 'vuex-map-fields';
import GameService from "../../services/game.service";

export const game_write = {
    namespaced: true,
    state: {
        showGameToWrite: false,
        sendToPlayer: false,
        sendToCommunity: false,
        nbPimentosCost: 10,
        nbPimentosReward: 10,
        duration:"7200",
        texteDefi: "",
        titreDefi: "",
        selectedGategory: [],
    },
    getters: {
        getField,
        isWritingGame(state){
            return state.showGameToWrite;
        }
    },
    mutations: {
        updateField,
        RESET_GAME_WRITE(state) {
            state.showGameToWrite = false;
            state.sendToPlayer = false;
            state.sendToCommunity = false;
            state.nbPimentos = false;
            state.texteDefi = "";
            state.titreDefi = "";
            state.selectedGategory = [];
            state.duration="7200";
        }
    },
    actions: {
        async sendGame({dispatch, state}, payload) {
            console.log(payload)
            let game = {
                "title": state.titreDefi,
                "descriptionShort": state.titreDefi,
                "descriptionLong": state.texteDefi,
                "pointsReward": state.nbPimentosReward,
                "pointsCost": state.sendToCommunity?state.nbPimentosCost:0,
                "gameType": payload.gameType,
                "categoryElements": payload.gameCategories,
                "author": payload.author,
                "isPrivate": !state.sendToCommunity,
                "duration" : state.duration
            }
            return new Promise((resolve, reject) => {
                GameService.sendGame(game).then((response) => {
                    resolve(response.data);
                    let message = 'Jeu envoyé avec succès.';
                    if (state.sendToCommunity) {
                        message += ' Merci pour votre partage. Le jeu sera publié prochainement après validation par nos équipes.'
                    }
                    dispatch('message/showSuccessMessage', message, {root: true});
                }).catch(e => {
                    console.error(e);
                    dispatch('message/showErrorMessage', 'Erreur d\'envoi de votre jeu', {root: true});
                    reject(false);
                })
            })
        },
        async getMyGame({rootGetters},payload) {
            let myId = rootGetters["player/getPlayerId"];
            return new Promise((resolve, reject) => {
                GameService.getGames(null, [], myId,payload).then(response => {
                    resolve({games:response.data['hydra:member'],totalGames:response.data['hydra:totalItems']});
                }).catch(error => {
                    reject(error);
                })
            })
        },
    }
}