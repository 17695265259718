<template>
  <v-app>
    <v-snackbar
        v-model="showSnackBar"
        :color="snackBarColor"
        dark
        app
        width="100vw"
    >
      {{ snackBarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            dark
            text
            @click="showSnackBar = false"
            v-bind="attrs"
        >
          <v-icon>mdi-close</v-icon>
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
    <v-container class="fill-height ma-0 pa-0 mb-16 fill-height-with-footer" fluid >
      <router-view/>
    </v-container>
    <FooterPlayMenu/>
  </v-app>

</template>

<script>
import FooterPlayMenu from "../components/play/FooterPlayMenu";
import MessageMixin from "../mixins/MessageMixin";

export default {
  name: "PlayLayout",
  components: {FooterPlayMenu},
  mixins:[MessageMixin]
}
</script>
<style scoped>
.fill-height-with-footer{
  max-height: calc(100vh - 54px);
  overflow-y: scroll;
}
</style>