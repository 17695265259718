import APIService from "../api.service";

class AuthService {
    async login(player) {
        return APIService
            .post('/authentication_token', {
                email: player.email,
                password: player.password
            })
            .then(response => {
                if (response.data.token) {
                    localStorage.setItem('token', response.data.token);
                }
                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }

    async logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('gtGames_favorite');
    }

    async register(player) {
        return APIService.post('/shop/client/register', player);
    }

    async recoveryAsk(payload) {
        return APIService.post('/shop/client/recovery', payload);
    }

    async verification(token) {
        return APIService.get('/shop/client/verify-account/' + token);
    }
}

export default new AuthService();