import { render, staticRenderFns } from "./AvatarBuilder.vue?vue&type=template&id=8952e846&scoped=true&"
import script from "./AvatarBuilder.vue?vue&type=script&lang=js&"
export * from "./AvatarBuilder.vue?vue&type=script&lang=js&"
import style0 from "./AvatarBuilder.vue?vue&type=style&index=0&id=8952e846&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8952e846",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VBtn,VCol,VContainer,VDialog,VImg,VProgressCircular,VRow})
