<template>
  <v-container :fluid="$vuetify.breakpoint.smAndUp" min-height="90vh" class="pa-0 mb-12">
    <GameMeAndYou/>
    <GameInProgress/>
    <GameType favorite/>
    <GameOfTheWeek/>
    <GameType :type="type" v-for="type in gameTypes" v-bind:key="type.code"/>
  </v-container>
</template>

<script>

import AuthPlayerNeeded from "@/mixins/AuthPlayerNeeded";
import GameType from "../components/game/GameType";
import GameMeAndYou from "../components/game/GameMeAndYou";
import GameInProgress from "../components/game/GameInPlay";
import GameMixin from "../mixins/GameMixin";
import GameOfTheWeek from "../components/game/GameOfTheWeek";

export default {
  name: "Home",
  mixins: [AuthPlayerNeeded, GameMixin],
  components: {GameOfTheWeek, GameInProgress, GameMeAndYou, GameType, },
  data() {
    return {
      types: [
        {name: "Défis", typeCode: 'challenge'},
        {name: "Scénarios", typeCode: 'storyline'},
        {name: "Jeux de rencontre", typeCode: 'meet-game'},
      ]
    }
  },
  created() {
    this.getGameFilters();
    this.getGameTypes();
  }
}
;
</script>
