<template>
  <v-select label="Couleur cheveux" clearable :items="avatarHairColors" item-text="color" v-model="selected" item-value="@id">
    <template v-slot:item="{item}">
      <v-chip :style="'background-color: '+item.color">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-chip>
    </template>
    <template v-slot:selection="{item}">
      <v-chip :style="'background-color: '+item.color">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-chip>
    </template>
  </v-select>
</template>

<script>
import AvatarMixin from "../../mixins/AvatarMixin";

export default {
  name: "AvatarHairColorSelect",
  mixins: [AvatarMixin],
  props: ['value', 'avatarHairColors'],
  data() {
    return {
      selected: "",
    }
  },
  watch: {
    selected: function () {
      this.$emit('input', this.selected);
    },
    value: function () {
      this.selected = this.value;
    }
  }
}
</script>

<style scoped>

</style>