<template>
  <v-row no-gutters align-content="start" justify="start" class="white mt-2 mb-2 elevation-4" v-show="nbGamesInPlay>0">
    <h1 class="title pl-3">
      Mes parties en cours ({{ nbGamesInPlay }}/{{maxGamesInPlay}})
    </h1>
    <v-row style="width: 100%" v-if="nbGamesInPlay>0" no-gutters>
      <swiper ref="mySwiper" :options="swiperOptions" :key="slidesPerView" nav style="width: 100%">
        <swiper-slide v-for="gameInPlay in gamesInPlay" v-bind:key="gameInPlay['@id']">
          <game-in-play-tile :game-in-play="gameInPlay"/>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </v-row>
  </v-row>
</template>

<script>
// import GameTile from "./GameTile";
import GameInPlayMixin from "../../mixins/GameInPlayMixin";
import GameInPlayTile from "./GameInPlayTile";
import PlayerMixin from "../../mixins/PlayerMixin";

export default {
  name: "GameInPlay",
  components: {GameInPlayTile},
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        slidesPerView: this.$vuetify.breakpoint.smAndUp ? 6 : 3,
        loop: false,
        spaceBetween: 15,
        freeMode: true,
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
      },
    }
  },
  mixins: [GameInPlayMixin, PlayerMixin],
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
    slidesPerView() {
      return this.$vuetify.breakpoint.smAndUp ? 6 : 3;
    },
    isMobile() {
      return !this.$vuetify.breakpoint.smAndUp;
    }
  },
  created() {
    this.swiperOptions.slidesPerView = this.$vuetify.breakpoint.smAndUp ? 6 : 3;
  },
  watch: {
    'isMobile': function (before, after) {
      if (before != after) {
        this.swiperOptions.slidesPerView = this.$vuetify.breakpoint.smAndUp ? 6 : 3;
        this.$refs.mySwiper.updateSwiper();
      }
    }
  }
}
</script>
<style scoped lang="scss">
.swiper-pagination {
  position: relative;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10000;
  bottom: 0px;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullets {
  bottom: 0 !important;
}
$color-strong-orange: #cc9933;
.swiper-button-next, .swiper-button-prev {
  top: 32%;
  @media (max-width: 501px) {
    display: none;
  }
  color: $color-strong-orange;
}


</style>